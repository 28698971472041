(function() {

	'use strict';

	$.validator.addMethod('cc-number', function(value, element) {
		var pattern = /^\d{16}$/;
		return this.optional(element) || pattern.test(value);
	}, 'Please enter a valid credit card number.');

}());
