// ==========================================================================
// Microsite Hero banner
// ==========================================================================

(function(NAMESPACE, $) {

	'use strict';

	NAMESPACE.micrositeHeroBanner = (function() {

		var SELECTORS,
			init;

		SELECTORS = {
			BANNER: '.cm-micro-site-hero-banner',
			OVERLAY: '.cm-micro-site-hero-banner__overlay'
		};

		init = function() {
			var ratio = 2.51;
			function updateHeroBannerContainer() {
				var widthOfHeroBannerContainer = window.innerWidth;
				var calculatedHeight = Math.floor(widthOfHeroBannerContainer / ratio);
				var $containerWidth = $(SELECTORS.BANNER).width();

				if ($containerWidth >= 521 && $containerWidth <= 1440) {
					$(SELECTORS.BANNER).css({
						height: calculatedHeight + 'px'
					});
					$(SELECTORS.OVERLAY).css({
						height: calculatedHeight + 'px'
					});
				}
			}

			updateHeroBannerContainer();
			$(window).on('resize', function() {
				updateHeroBannerContainer();
			});
		};

		return {
			init: init
		};
	}());

}(DDIGITAL, jQuery));
