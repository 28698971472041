(function() {

	'use strict';

	$.validator.addMethod('cc-cvv', function(value, element) {
		var pattern = /^\d{3,4}$/;

		return this.optional(element) || pattern.test(value);
	}, 'Please enter a valid CVV code. For Mastercard or Visa, this is the last three digits in the signature area ' +
		'on the back of your card.');

}());
