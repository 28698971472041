/* ==========================================================================
 * FORM ELEMENT TRACKER
 * ========================================================================== */

/**
 * Form field Event Tracker.
 * This can be used for analytics purposes as well as determining whether the user made any changes
 * to the form.
 *
 * @todo Consider re-writing this as a jQuery plugin and make isDirty and isValid public methods
 */
(function(NAMESPACE, $) {

	'use strict';

	/**
	 * @class ElementTracker
	 * @memberOf DDIGITAL.forms
	 *
	 * @param {object} $form jQuery reference to the form container
	 * @param {object} $ctrl jQuery reference to the control
	 * @param {object} settings Default settings hash
	 * @constructor
	 */
	var ElementTracker = function($form, $ctrl, settings) {
		this.$form = $form;
		this.validator = this.$form.data('validator');
		this.$ctrl = $ctrl;

		this.defaults = {
			debounce: 100
		};
		this.settings = $.extend(true, this.settings, this.defaults, settings);
	};

	/**
	 * @type {{previousValue: null, $ctrl: null, validator: null, settings: {}, init: DDIGITAL.form.ElementTracker.init, _isValid: DDIGITAL.form.ElementTracker._isValid, _isDirty: DDIGITAL.form.ElementTracker._isDirty, _emitSuccess: DDIGITAL.form.ElementTracker._emitSuccess, _emitError: DDIGITAL.form.ElementTracker._emitError, _trackGeneric: DDIGITAL.form.ElementTracker._trackGeneric, _trackCheckbox: DDIGITAL.form.ElementTracker._trackCheckbox, _trackRadio: DDIGITAL.form.ElementTracker._trackRadio, _trackGroup: DDIGITAL.forms.ElementTracker._trackGroup}}
	 */
	ElementTracker.prototype = {

		previousValue: null,
		validator: null,
		settings: {},

		/**
		 * Initialize form field tracker class
		 * @memberOf DDIGITAL.form.ElementTracker
		 * @private
		 */
		init: function() {

			if (this.$ctrl.data('elementTracker.initialised') === true) {
				return false;
			}

			this.isGroup = NAMESPACE.forms.validate.isInGroup(this.$ctrl);

			if (this.isGroup) {

				// If is first in group
				if (NAMESPACE.forms.decorator.getCtrlsInGroup(this.$ctrl).first().is(this.$ctrl)) {
					this._trackGroup();
				}

			} else {

				if (this.$ctrl[0].type === 'text' || this.$ctrl[0].type === 'password' || this.$ctrl[0].tagName === 'SELECT') {
					this._trackGeneric();
				}

				if (this.$ctrl[0].type === 'checkbox') {
					this._trackCheckbox();
				}

				if (this.$ctrl[0].type === 'radio') {
					this._trackRadio();
				}
			}

			this.$ctrl.data('elementTracker.initialised', true);
		},

		/**
		 * Determines if the control is currently valid by inspecting the jQuery validate validator instance
		 * @returns {boolean}
		 * @inner
		 * @memberOf DDIGITAL.form.ElementTracker
		 * @private
		 */
		_isValid: function() {
			var valid = true;

			// Check if the errorMap has a corresponding error
			if (this.validator.errorMap.hasOwnProperty(this.$ctrl[0].name)) {
				valid = false;
			}

			return valid;
		},

		/**
		 * Returns true if a control is dirty
		 * @returns {boolean}
		 * @inner
		 * @memberOf DDIGITAL.form.ElementTracker
		 * @private
		 */
		_isDirty: function() {
			return this.validator.submitted[this.$ctrl[0].name] !== undefined;
		},

		/**
		 * Emits success event to the FormTracker instance that this ElementTracker instance is a child of
		 * @inner
		 * @memberOf DDIGITAL.form.ElementTracker
		 * @private
		 */
		_emitSuccess: function() {
			this.$ctrl.trigger('element-success.track', [this.$ctrl]);
		},

		/**
		 * Emits error event to the FormTracker instance that this ElementTracker instance is a child of
		 * @returns {boolean}
		 * @inner
		 * @memberOf DDIGITAL.form.ElementTracker
		 * @private
		 */
		_emitError: function() {
			var errorMsg = '';

			if (this.isGroup) {
				// Will just have to grab this straight from the DOM :/
				errorMsg = $('#' + this.$ctrl[0].id + '-error').text();
			} else {
				try {
					errorMsg = this.validator.errorMap[this.$ctrl[0].id];
				} catch (e) {
					return false;
				}
			}
			this.$ctrl.trigger('element-error.track', [this.$ctrl, errorMsg]);
		},

		/**
		 * Listens for change events on the following input types:
		 * - input[type="text"]
		 * - input[type="password"]
		 * - select
		 * - select[multiple="multiple"]
		 *
		 * @todo add support for autocomplete and datepicker elements
		 *
		 * @inner
		 * @memberOf DDIGITAL.form.ElementTracker
		 * @private
		 */
		_trackGeneric: function() {
			var _this = this;

			function change() {
				var currentValue = _this.$ctrl.val();

				if (_this.previousValue === currentValue) {
					return false;
				}

				if (_this.$ctrl.val() !== '' && _this._isValid()) {
					_this._emitSuccess();
				}
				if ((_this.$ctrl.val() !== '' || _this._isDirty()) && !_this._isValid()) {
					_this._emitError();
				}

				_this.previousValue = currentValue;
			}

			// Delay change event callback so that validator instance has time to start reflecting the change first
			this.$ctrl.on('change.track', this.settings.debounce !== false ? $.debounce(this.settings.debounce, change) : change);
		},

		/**
		 * Listens for changes events on checkboxes
		 * @inner
		 * @memberOf DDIGITAL.form.ElementTracker
		 * @private
		 */
		_trackCheckbox: function() {
			var _this = this;

			function change() {
				var currentValue = _this.$ctrl.is(':checked');

				if (_this.previousValue === currentValue) {
					return false;
				}

				// If the checkbox is checked and is considered valid
				if (_this.$ctrl.is(':checked') && _this._isValid()) {
					_this._emitSuccess();
				}

				// If the checkbox is not checked and is invalid
				if (!_this.$ctrl.is(':checked') && !_this._isValid()) {
					_this._emitError();
				}

				_this.previousValue = currentValue;
			}

			// Delay change event callback so that validator instance has time to start reflecting the change first
			this.$ctrl.on('change.track', this.settings.debounce !== false ? $.debounce(this.settings.debounce, change) : change);
		},

		/**
		 * Listens for changes events on radio buttons
		 * @inner
		 * @memberOf DDIGITAL.form.ElementTracker
		 * @private
		 */
		_trackRadio: function() {
			var _this = this;

			function change() {
				var $checked = $('input[name="' + _this.$ctrl[0].name + '"]:checked');

				// If the radio is checked and is considered valid
				if ($checked.length && _this._isValid()) {
					_this._emitSuccess();
				}

				// If the radio is not checked and is invalid
				if (!$checked.length && !_this._isValid()) {
					_this._emitError();
				}
			}

			// Delay change event callback so that validator instance has time to start reflecting the change first
			this.$ctrl.on('change.track', this.settings.debounce !== false ? $.debounce(this.settings.debounce, change) : change);
		},

		/**
		 * Tracks a control group marked with the .v_group class
		 * @private
		 */
		_trackGroup: function() {
			var _this = this,
				$ctrls = NAMESPACE.forms.decorator.getCtrlsInGroup(this.$ctrl),
				$ctrlsHolder = NAMESPACE.forms.decorator.getCtrlHolderFromElement(this.$ctrl, false);

			function change() {
				var complete = true,
					checkboxOrRadioInGroup = false,
					anyChecked = false,
					isValid = NAMESPACE.forms.validate.isGroupValid($ctrlsHolder),
					currentValue = '';

				$ctrls.each(function() {
					var _$ctrl = $(this);

					if (_$ctrl.is(':checkbox') || _$ctrl.is(':radio')) {
						checkboxOrRadioInGroup = true;
						if (_$ctrl.is(':checked')) {
							anyChecked = true;
						}

						if (_$ctrl.is(':checkbox') && _$ctrl.is(':checked')) {
							currentValue += _$ctrl[0].name;
						}
						if (_$ctrl.is(':radio')) {
							currentValue += $('input[name="' + _$ctrl[0].name + '"]').val();
						}
					} else {
						// Assume we're dealing with a select or text input
						if (_$ctrl.val() === '' || _$ctrl.val() === null) {
							complete = false;
						}

						currentValue += _$ctrl.val();
					}
				});

				if (_this.previousValue === currentValue) {
					return false;
				}

				// Check if group's fields are complete and without errors
				if (complete === true && isValid) {
					// If there is a checkbox or radio button in the group, one of them must be checked
					if (checkboxOrRadioInGroup) {
						if (anyChecked) {
							_this._emitSuccess();
						}
					} else {
						_this._emitSuccess();
					}
				} else if (!isValid) {
					_this._emitError();
				}

				_this.previousValue = currentValue;
			}

			$ctrls.each(function() {
				var $ctrl = $(this);
				$ctrl.on('change.track', _this.settings.debounce !== false ? $.debounce(_this.settings.debounce, change) : change);
			});
		}
	};

	NAMESPACE.forms.ElementTracker = ElementTracker;

}(DDIGITAL, jQuery));
