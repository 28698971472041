// ==========================================================================
// DO WHEN - A jQuery plugin to do stuff when you want
// https://github.com/dkeeghan/jQuery-doWhen
// ==========================================================================

(function(NAMESPACE, $) {

	'use strict';

	NAMESPACE.doWhen = (function() {

		var init;

		init = function() {
			$(document).doWhen();
		};

		return {
			init: init
		};
	}());

}(DDIGITAL, jQuery));
