// ==========================================================================
// MULTI STEP FORM STEP CLASS
// ==========================================================================

(function(NAMESPACE, $) {

	'use strict';

	/**
	 * Forms namespace
	 *
	 * @namespace DDIGITAL.forms
	 * @memberof DDIGITAL
	 */
	NAMESPACE.forms = NAMESPACE.forms || {};

	/**
	 * Multistep namespace
	 *
	 * @namespace DDIGITAL.forms.multistep
	 * @memberof DDIGITAL
	 */
	NAMESPACE.forms.multistep = NAMESPACE.forms.multistep || {};

	/**
	 * @class Step
	 * @memberOf DDIGITAL.forms.multistep
	 *
	 * @param {Object} $container jQuery reference to the form wrapper
	 * @param {Object} settings Default settings hash
	 * @constructor
	 */
	var Step,
		CLASSES;

	CLASSES = {
		BACK: 'js-multistep-back'
	};

	/**
	 * @class MultiStep
	 * @memberOf DDIGITAL.forms.multistep
	 *
	 * @param {Object} $step Reference to element that is wrapped around the <form> element
	 * @param {Object} $container Reference to LiveForm container
	 * @param {Object} settings Hash of configuration parameters
	 * @constructor
	 */
	Step = function($step, $container, settings) {
		this.$step = $step;
		this.step = this.$step.data('step');
		this.$container = $container;
		this.defaults = {
			confirmText: 'Are you sure you want to navigate away from this step of the form? Changes you made will not be saved.'
		};

		this.settings = $.extend(true, this.settings, this.defaults, settings);
	};

	Step.prototype = {

		/**
		 * @type {Object}
		 */
		settings: {},

		/**
		 * @type {Object}
		 */
		$step: null,

		/**
		 * @type {Object}
		 */
		$form: null,

		/**
		 * Will turn true as soon as a control as been successfully validated
		 * @type {Boolean}
		 */
		isDirty: false,

		/**
		 * Initialize step
		 * @memberOf DDIGITAL.forms.multiStep.Step
		 * @private
		 */
		init: function(service) {
			this.service = service;
			this.$form = this.$step.find('form');
			this.$fieldsets = this.$form.find('fieldset');

			// Ensure all JS modules are initialised against this new section of DOM
			this.$step.trigger('re-init-all');

			this._listenForEvents();
			this._initGoBack();
			this._trackElements();
			this._onBeforeUnload();
		},

		/**
		 * Listen for events that get raised ageinst this step node
		 * @private
		 */
		_listenForEvents: function() {

			// Respond to request to disable this step node
			this.$step.on('disable.step', function() {
				this._disable();
			}.bind(this));

			// Respond to request to enable this step node
			this.$step.on('enable.step', function() {
				this._enable();
			}.bind(this));

			// Respond to request to implode and remove this node from the DOM
			this.$step.on('destroy.step', function() {
				this.destroy();
			}.bind(this));
		},

		/**
		 * Catch 'go back' link click and delegate event for LiveForm
		 * @private
		 */
		_initGoBack: function() {
			this.$step.find('.' + CLASSES.BACK)
				.on('click', function(evt) {
					evt.preventDefault();

					if (this.isDirty) {
						if (window.confirm(this.settings.confirmText)) {
							this.$step.trigger('goback.step', this.step);
						}
					} else {
						this.$step.trigger('goback.step', this.step);
					}
				}.bind(this));
		},

		/**
		 * Track elements to determine whether this page can be unloaded without warning the user
		 * @private
		 */
		_trackElements: function() {
			var _this = this;

			function initTracker($element) {
				var elementTracker = new NAMESPACE.forms.ElementTracker(_this.$form, $element, {});

				elementTracker.init();

				$element.on('element-success.track', function() {
					_this.isDirty = true;
				});
			}

			// Initialize ElementTracker instance against each form control withing the scope
			// of this step of the form
			this.$step
				.find('.ctrl-holder input, .ctrl-holder select, .ctrl-holder textarea')
				.each(function() {
					initTracker($(this));
				});
		},

		/**
		 * Prompts user if they really want to navigate away form the page
		 * @private
		 */
		_onBeforeUnload: function() {
			$(window).on('beforeunload.step-' + this.step.step, function() {
				if (this.isDirty) {
					return '';
				}
			}.bind(this));

			// Unbind as soon as the form is considered valid
			this.$form.on('submit-success.sync', function() {
				$(window).off('beforeunload.step-' + this.step.step);
			}.bind(this));
		},

		/**
		 * Disables the form fieldsets/submit button in this step
		 */
		_disable: function() {

			// Disable submit buttons (for cases where the buttons do not appear within the
			// fieldsets).
			this.$step.find('button[type="submit"]').each(function() {
				var $button = $(this),
					state = $button.prop('disabled');

				$button.data('disabled-state', Boolean(state));
				$button.prop('disabled', true);
			});

			// Disable fieldsets
			this.$step.find('fieldset').each(function() {
				var $fieldset = $(this),
					state = $fieldset.prop('disabled');

				$fieldset.data('disabled-state', Boolean(state));
				$fieldset.prop('disabled', true);
			});
		},

		/**
		 * Enables the form fieldsets/submit button in this step
		 */
		_enable: function() {

			// Re-enable submit buttons
			this.$container.find('button[type="submit"]').each(function() {
				var $button = $(this),
					state = $button.data('disabled-state');

				$button.prop('disabled', Boolean(state));
				$button.removeData('disabled-state');
			});

			// Re-enable fieldsets
			this.$fieldsets.each(function() {
				var $fieldset = $(this),
					state = $fieldset.data('disabled-state');

				$fieldset.prop('disabled', Boolean(state));
				$fieldset.removeData('disabled-state');
			});
		},

		/**
		 * Removes the step form the DOM and cleans up after itself
		 */
		destroy: function() {
			$(window).off('beforeunload.step-' + this.step.step);
			this.$step.remove();
		}

	};

	NAMESPACE.forms.multistep.Step = Step;

}(DDIGITAL, jQuery));
