/* ==========================================================================
 * MAGICAL AMAZING RESPONSIVE TABLES
 *
 * This module applies to tables with a classname of "js-responsive-table".
 * By default, it will apply a horizontal scroll to tables.
 * It also has an option to display an interface for toggling table columns.
 *
 * To enable column toggling:
 *  - Add a classname of "responsive-table-column-toggling" to the table
 *  - Add a classname of "column-persist" to any <th> elements for columns that
 *    should never ever be hidden (such as the title column)
 *  - Add a classname of "column-important" to any <th> elements for columns that
 *    will be displayed by default on all breakpoints (including extra small and below)
 *  - Add a classname of "column-optional" to any <th> elements for columns that
 *    will be displayed by default on small-and-above breakpoints
 *  - <th> elements without one of the above classnames will only be displayed
 *    by default on the large-and-above breakpoint
 *
 * Table column toggling supports tables with colspan attributes in the head
 * <th> elements, but not in the body <td> elements.
 *
 * ========================================================================== */

(function(NAMESPACE, $) {

	'use strict';

	NAMESPACE.responsiveTable = (function() {
		var init = function() {
			$('.js-responsive-table').each(function(i, el) {
				var tableHeaders = [];
				$(el).find('thead tr th').each(function() {
					tableHeaders.push($(this).text());
				});
				$(el).find('tbody tr').each(function(i, row) {
					$(row).children().each(function(i, cell) {
						$(cell).attr('data-header', tableHeaders[i]);
					});
				});
			});
		};

		return {
			init: init
		};

	}());

}(DDIGITAL, jQuery));
