/* ==========================================================================
 * BACKGROUND VIDEO
 * ========================================================================== */

(function(NAMESPACE, $) {

	'use strict';

	NAMESPACE.bgVideo = (function() {
		var SELECTORS,
			DATA,
			init;

		SELECTORS = {
			CONTAINER: '.js-bg-video'
		};

		DATA = {
			MP4: 'data-mp4-video-src',
			WEBM: 'data-webm-video-src',
			POSTER: 'data-poster-src'
		};

		init = function() {
			$(SELECTORS.CONTAINER).each(function(i, el) {
				var $container = $(el),
					src = {
						mp4: $container.attr(DATA.MP4),
						webm: $container.attr(DATA.WEBM),
						poster: $container.attr(DATA.POSTER)
					};

				$container.vide(src, {
					muted: true,
					loop: true,
					autoplay: true,
					posterType: 'jpg'
				});

				if (NAMESPACE.util.device.is.ios()) {
					$container.find('video').remove();
				}
			});
		};

		return {
			init: init
		};
	}());

}(DDIGITAL, jQuery));
