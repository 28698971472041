// ==========================================================================
// TABS
// ==========================================================================

(function(NAMESPACE, $) {

	'use strict';

	// tabs functionality, including the ability to have tabs in tabs
	NAMESPACE.contactTiles = (function() {

		var SELECTORS,
			init;

		SELECTORS = {
			TILES: '.js-contact-tiles',
			TILE_EC_CONTENT: '.contact-tile-ec-content',
			TILE: '.info-tile'
		};

		init = function() {
			$(SELECTORS.TILE).click(function() {
				var tabId = $(this).attr('aria-controls');
				var $container = $(this).parentsUntil(SELECTORS.TILES);
				if ($(this).attr('aria-current') !== 'page') { //this is the start of our condition
					$container.find(SELECTORS.TILE + ' a').attr('aria-current', '');
					$container.find(SELECTORS.TILE_EC_CONTENT).addClass('hidden');
					$container.find('div[aria-controls="' + tabId + '"]').find('a').attr('aria-current', 'page');
					$container.find('#' + tabId).removeClass('hidden');
				}
			});
		};

		return {
			init: init
		};
	}());

}(DDIGITAL, jQuery));
