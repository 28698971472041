(function() {
	'use strict';

	$.validator.addMethod('email-length', function(value) {
		// Let 'pattern' be a pattern that matches a digit.
		var pattern = /^.{0,255}$/;
		// Check if the value in the form control does not match 'pattern'.
		return pattern.test(value);
	}, 'Please enter no more than 255 characters');
}());
