// ==========================================================================
// EQUAL HEIGHTS
// ==========================================================================

(function(NAMESPACE, $) {

	'use strict';

	/**
	 * Manages heights of like items to keep them the same
	 * using the $.ddEqualHeights plugin
	 *
	 * @namespace equalHeights
	 * @memberof DDIGITAL
	 * @version 1.1.0
	 * @author Deloitte Digital Australia
	 */
	NAMESPACE.equalHeights = (function() {
		var init;

		/*
		 * @example
		 *
		 * $.fn.ddEqualHeights.addType('feature-item', {
		 *     itemsSelector: '.item',
		 *     sectionSelector: '.section',
		 *     numItemsPerRow: 2
		 * });
		 */

		// add the eqh-demo type
		$.ddEqualHeights.addType('eqh-demo', {
			numItemsPerRow: function() {
				// you can do tricky things here like look at layouts and check if the site is responsive or not.
				if ($('.l-three-column').length) {
					return (NAMESPACE.IS_RESPONSIVE) ? {'0,xs': 1, 's,l': 2, 'xl': 3} : 2;
				}

				return (NAMESPACE.IS_RESPONSIVE) ? {'0,xs': 1, 's,m': 2, 'l,xl': 3, 'xxl': 4} : 3;
			}
		});

		// add the sl-list-landing type
		$.ddEqualHeights.addType('sl-list-landing', {
			itemsSelector: '.cm',
			sectionSelector: '.title, > ul',
			numItemsPerRow: function() {
				return (NAMESPACE.IS_RESPONSIVE) ? {'0,m': 1, 'l': 2} : 2;
			}
		});

		// add the nav-onscreen type
		$.ddEqualHeights.addType('nav-onscreen', {
			numItemsPerRow: function() {
				return (NAMESPACE.IS_RESPONSIVE) ? {'0,m': 1, 'l': 4} : 4;
			}
		});

		// add the site map type
		$.ddEqualHeights.addType('cm-site-map', {
			numItemsPerRow: function() {
				return (NAMESPACE.IS_RESPONSIVE) ? {'0,xs': 1, 's,m': 2, 'l': 4} : 4;
			}
		});

		// add the homepage hero-links type
		$.ddEqualHeights.addType('hero-links', {
			itemsSelector: '.link-item',
			sectionSelector: '.content',
			numItemsPerRow: function() {
				return (NAMESPACE.IS_RESPONSIVE) ? {'0,xxs': 1, 'xs,m': 2, 'l': 4} : 4;
			}
		});

		init = function(scope) {
			var $scope = (scope) ? $('[data-heights-type]', scope) : $('[data-heights-type]');
			$scope.ddEqualHeights();
		};

		return {
			init: init
		};
	}());

}(DDIGITAL, jQuery));
