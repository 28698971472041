// ==========================================================================
// M58 - MULTILINGUAL PANEL
// ==========================================================================

// NOTE: Google Translate does not translate elements that are hidden on the page.
// A new POST request is fired off when a new element becomes visibile, and the content is
// then translated on the fly - this therefore depends on an active network connection.

(function(NAMESPACE, $) {
	'use strict';

	/**
	 * MULTILINGUAL FUNCTIONS
	 *
	 * @namespace multilingualPanel
	 * @memberof DDIGITAL
	 * @version 1.0.0
	 * @author Deloitte Digital Australia
	 */
	NAMESPACE.multilingualPanel = (function() {
		var CONST,
			HTML,
			CLASSES,
			SELECTORS,

			event,

			interimTranslation = false,

		// ----- Scope elements
			_$gtDropdown, // Google Translate dropdown
			_$headerLanguagesButton,
			_$multilingualPanel,

			_existingLanguageSetting,
			_recursionLoopBreak = false,

		// ----- Functions
			_setDOMmutationObserver,
			_triggerGoogleTranslateEvent,
			_openAndTranslateModal,
			_changeHTMLlang,
			_languageButtonClick,
			_keyboardActions,
			_toggleDisplayVisibility,
			_onClick,
			_saveLanguageCookie,
			_trapFocus,

			init;

		CONST = {
			CHECK_INTERVAL: 500,
			CHECK_TIMEOUT: 5000,

			LANGUAGES: {
				'en':		'English',
				'ar':		'Arabic',
				'bn':		'Bengali',
				'zh-CN':	'Chinese (Simplified)',
				'zh-TW':	'Chinese (Traditional)',
				'hr': 		'Croatian',
				'cs':		'Czech',
				'tl':		'Filipino',
				'el':		'Greek',
				'hi':		'Hindi',
				'id':		'Indonesian',
				'it':		'Italian',
				'ja':		'Japanese',
				'km':		'Khmer',
				'ko':		'Korean',
				'mk':		'Macedonian',
				'mt': 		'Maltese',
				'my':		'Myanmar (Burmese)',
				'ne':		'Nepali',
				'fa':		'Persian',
				'pt':		'Portuguese',
				'sr':		'Serbian',
				'es':		'Spanish',
				'ta':		'Tamil',
				'tr': 		'Turkish',
				'ur':		'Urdu',
				'vi':		'Vietnamese'
			},

			LOCAL_STORAGE: {
				LANGUAGE: 'page-language',
				PREVIOUS_LANGUAGE: 'previous-page-language'
			},

			MODALS: {
				DISCLAIMER: 'modal-multilingual-disclaimer'
			},

			// Mutation observer default config
			OBS_CONFIG: {
				attributes: true,
				childList: true,
				subtree: true,
				characterData: true
			},

			KEYCODES: {
				UP: 38,
				DOWN: 40,
				TAB: 9,
				ENTER: 13,
				ESC: 27
			},

			// DOMAIN: 'localhost' // DEV ONLY
			DOMAIN: 'icare.nsw.gov.au'
		};

		HTML = {
			LANGUAGE_BUTTON: [
				'<button ',
					'class="js-language-option language-option notranslate"',
				'>',
				'</button>'
			].join('')
		};

		CLASSES = {
			HIDDEN: 'is-hidden',
			SELECTED: 'is-selected',
			LOADING: 'is-loading',
			IS_TRANSLATED: 'is-translated'
		};

		SELECTORS = {
			GT_CONTAINER: '.js-google_translate_element',
			GT_DROPDOWN: '#google_translate_element select',

			MODAL: '.modal',
			MODAL_CONTENT_CONTAINER: '.modal-content',
			MODAL_CONTENT: '.modal-content-overflow',

			// Header/menu toggle
			HEADER_LANGUAGE_MENU: '.js-languages-button',

			LANGUAGE_UI_PANEL: '.js-multilingual-panel-dropdown',
			LANGUAGE_UI_LIST_CONTAINER: '.js-multilingual-panel-dropdown-list',
			LANGUAGE_UI_BUTTON: '.js-language-option',

			READSPEAKER: '.rsbtn'
		};

		// Create and initialize the 'languagechange' event.
		event = document.createEvent('Event');
		event.initEvent('languagechange', true, false);

		/**
		 * Handle keyboard actions on the miltilingual UI panel
		 *
		 * @param {Event} event
		 */
		_keyboardActions = function(event) {
			// Hide panel when tabbing outside of the container, either from last child forwards, or first child backwards
			if ((event.which === CONST.KEYCODES.TAB && !event.shiftKey && ($(event.target).is(':last-child'))) ||
				(event.which === CONST.KEYCODES.TAB && event.shiftKey && ($(event.target).is(':first-child')))) {

				_toggleDisplayVisibility(false);
			}

			// if the esc key is pressed
			if (event.which === CONST.KEYCODES.ESC) {
				DD.a11y.onEscape.set(function() {
					_toggleDisplayVisibility(false);
					_$headerLanguagesButton.focus();
				});
			}
		};

		/**
		 * Event handler for click on any language button in multilingual UI
		 *
		 * @param {String} languageCode 	Language code value from clicked button
		 * @param {Boolean} togglePanel 	Whether to toggle the UI panel visibility
		 */
		_languageButtonClick = function(languageCode, togglePanel) {

			// extra error checking
			if (languageCode === null || languageCode === '' || typeof languageCode !== 'string') {
				return;
			}

			// Only proceed if the target language is different from current language
			if ((languageCode === _$gtDropdown.val()) ||
				(languageCode === 'en' && _$gtDropdown.val() === '') &&
				togglePanel) {

				_toggleDisplayVisibility(false);
				return;
			}

			// Add loading indicator, hide multilingual UI
			_$headerLanguagesButton.addClass(CLASSES.LOADING);
			_toggleDisplayVisibility(false);

			if (languageCode === 'en') {
				localStorage.setItem(CONST.LOCAL_STORAGE.LANGUAGE, languageCode);
			}

			_triggerGoogleTranslateEvent(languageCode, true);
		};

		/**
		 * Trigger Google Translate event to translate to target language
		 *
		 * @param {String} languageCode 	Value to set the Google Translate dropdown to
		 * @param {Boolean} triggerModal 	Whether to trigger the translation disclaimer modal
		 * @param {Function} callback 		NOTE: Set internally for the purposes of single-level recursion, not designed
		 * 									to be passed in as a parameter when invoked elsewhere
		 */
		_triggerGoogleTranslateEvent = function(languageCode, triggerModal, callback) {

			// If translating to a language other than English
			if (languageCode !== null && languageCode !== 'en' && _$gtDropdown.children().filter('[value="en"]').length > 0 && !_recursionLoopBreak) {
				interimTranslation = true;

				// Set back to English first, because the translation process always goes via English first.
				// This way we don't have to arbitrarily wait for it to finish reverting to English and concern ourselves
				// with having to wait for a false trigger of our Mutation Observer to resolve,
				// (Also, don't trigger the modal since this is an interim step before proceeding to target language)
				_triggerGoogleTranslateEvent('en', false, function() {
					// Remove any translation indicators, because they're now in the wrong language
					$('.' + CLASSES.IS_TRANSLATED).removeClass(CLASSES.IS_TRANSLATED);

					// Dont allow recursive loop to loop infinitely
					_recursionLoopBreak = true;

					// Once back to English, translate to target language (Mutation Observer will apply this time round)

					interimTranslation = false;

					_triggerGoogleTranslateEvent(languageCode, true, function() {
						// Set selected languageCode to cookie
						_saveLanguageCookie(CONST.LOCAL_STORAGE.PREVIOUS_LANGUAGE, languageCode);

						// Reset recursive loop condition
						_recursionLoopBreak = false;
					});
				});

				// Abort translation in lieu of above recursion
				return;

			} else {
				// Set Mutation Observer before firing off Google Translate event
				// Note that the translation request has yet to be fired, and is called below the closing of this else block
				_setDOMmutationObserver(
					// Node to observe
					// Page body
					$(SELECTORS.GT_CONTAINER).get(0),

					// Condition
					// None (any change)
					function() {
						return true;
					},

					// Success callback
					function() {
						var _$readSpeakerBtn = $(SELECTORS.READSPEAKER);

						// Remove loading indicator, mark selected language
						$('.' + CLASSES.SELECTED).removeClass(CLASSES.SELECTED);
						_$multilingualPanel.find('button[value="' + languageCode + '"]').addClass(CLASSES.SELECTED);

						// Allow page to settle before hiding spinner
						setTimeout(function() {
							_$headerLanguagesButton.removeClass(CLASSES.LOADING);
						}, 500);

						// extra error checking
						if (languageCode === null || languageCode === '' || typeof languageCode !== 'string') {
							Cookies.set('googtrans', '/en/en');
							return;
						}

						_changeHTMLlang(languageCode);

						_saveLanguageCookie(CONST.LOCAL_STORAGE.LANGUAGE, languageCode);

						// Fix dropdown selected text bug by reinitialising [ICSS-312]
						NAMESPACE.homepageUserTaskNav.init();

						if (!interimTranslation && Cookies.get(CONST.LOCAL_STORAGE.PREVIOUS_LANGUAGE) !== languageCode) {
							// Notify the browser that the current page has been translated.
							document.dispatchEvent(event);
						}

						// Target language translation complete, show the translation disclaimer
						if (languageCode !== 'en' && triggerModal) {

							// Don't show disclaimer modal if previously set languageCode is the same the newly set languageCode
							if (Cookies.get(CONST.LOCAL_STORAGE.PREVIOUS_LANGUAGE) !== languageCode) {

								_openAndTranslateModal(CONST.MODALS.DISCLAIMER, languageCode);
								_$readSpeakerBtn.addClass(CLASSES.HIDDEN);

								_$readSpeakerBtn.removeClass(CLASSES.HIDDEN);

								localStorage.setItem(CONST.LOCAL_STORAGE.LANGUAGE, languageCode);
							}
						}

						// Either there is a specific target language with a callback to apply translation
						NAMESPACE.util.helpers.triggerCallback(callback);
					}
				);
			}

			var _$gtLanguageSelectionFrame = $('.goog-te-menu-frame.skiptranslate:first').contents(),
				_$gtBannerFrame = $('.goog-te-banner-frame.skiptranslate:first').contents();

			// Simulate click on the target language, provided the frame exists
			// Note that 'en' is an exception here because it DOES NOT always exist in the panel, only when the page is
			// already translated to a non-English language
			if (languageCode !== 'en' && _$gtLanguageSelectionFrame.length > 0) {

				var _$targetLanguage = _$gtLanguageSelectionFrame.find('table td a').filter(function() {
						return $(this).val() === languageCode;
					}),
					_$gtTranslateButton = _$gtBannerFrame.find('.goog-te-button button#\\:1\\.confirm');

				if (_$targetLanguage.length > 0) {
					_$targetLanguage.get(0).dispatchEvent(new Event('click'));

					if (_$gtTranslateButton.length > 0) {
						_$gtTranslateButton.get(0).dispatchEvent(new Event('click'));
					}
				}

			// Else manually change value of Google Translate select element, and trigger change event to kick it along
			} else {
				// Update Google Translate dropdown and dispatch event to trigger translation
				// Note event is dispatched manually, jQuery's .trigger() doesn't work here due to the listener not bubbling up
				_$gtDropdown.val(languageCode);
				_$gtDropdown.get(0).dispatchEvent(new Event('change'));
			}
		};

		/**
		 * Open modal, but ensure that its contents are translated before showing it.
		 * This is necessary because Google Translate only translates visible text, and fires off
		 * a seperate request when new content is made visible, such is the case with modals
		 *
		 * @param {String} modalName 			Name/ID of target modal
		 * @param {String} languageCode 		Language code
		 */
		_openAndTranslateModal = function(modalName, languageCode) {

			// Now modal will only show up if languageCode is defined and english is not selected
			if (!languageCode || languageCode === 'en') {
				return;
			}

			var _$targetModalContentContainer = $(SELECTORS.MODAL + '#' + modalName).find(SELECTORS.MODAL_CONTENT_CONTAINER);

			// Mark modal as loading, open it, set Mutation Observer
			_$targetModalContentContainer.addClass(CLASSES.LOADING);

			// Note we cannot rely on the callback in ddModal since it is triggered after the related
			// animation is complete, and therefore the translation often occurs before callback is invoked
			_setDOMmutationObserver(
				// Node to observe
				// Modal text content
				_$targetModalContentContainer.find(SELECTORS.MODAL_CONTENT).get(0),

				// Condition
				// None (any change)
				function() {
					return true;
				},

				// Success callback
				function() {
					setTimeout(function() {
						// Remove loading indicator
						_$targetModalContentContainer.removeClass(CLASSES.LOADING);
					}, 500);
				},

				// Timeout
				3000,

				// Fail callback
				// Note a minority of browsers (Safari) translate the entire DOM, regardless of visibility,
				// therefore this mutation observer will not be triggered since the modal is already translated
				function() {
					_$targetModalContentContainer.removeClass(CLASSES.LOADING);
				}
			);

			NAMESPACE.modal.open(modalName);
			setTimeout(()=> {
				_trapFocus($('#' + modalName));
			}, 500);
		};

		/**
		 * traps focus at the given element
		 * @param elem
		 */
		_trapFocus = function(elem) {
			var tabbable = elem.find('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');
			var firstTabbable = tabbable.first();
			var lastTabbable = tabbable.last();
			firstTabbable.focus();
			lastTabbable.on('keydown', function(e) {
				if ((e.which === 9 && !e.shiftKey)) {
					e.preventDefault();
					firstTabbable.focus();
				}
			});
			firstTabbable.on('keydown', function(e) {
				if ((e.which === 9 && e.shiftKey)) {
					e.preventDefault();
					lastTabbable.focus();
				}
			});
		};

		/**
		 * Set mutation observer on DOM node, check condition, trigger callback
		 * TODO timeout, error handling
		 *
		 * @param {Node} node 						DOM node to observe
		 * @param {Function} condition 				Function that returns a boolean, tested each interval
		 * @param {Function} successCallback 		Function to be executed when condition is met
		 * @param {Number} timeout 					Optional timeout before disconnecting, and triggering fail callback if it exists
		 * @param {Function} failCallback 			Optional function to be executed if condition is not met before timeout
		 */
		_setDOMmutationObserver = function(node, condition, successCallback, timeout, failCallback) {
			var _mutationObserver = new MutationObserver(function() {
					// If condition is met, trigger callback
					if (NAMESPACE.util.helpers.triggerCallback(condition)) {
						// Cease observation once callback triggered
						_mutationObserver.disconnect();

						NAMESPACE.util.helpers.triggerCallback(successCallback);
					}
				});

			_mutationObserver.observe(node, CONST.OBS_CONFIG);

			// Apply disconnection timeout and fail callback if specified
			if (!!timeout && typeof timeout === 'number' && timeout > 0) {
				setTimeout(function() {
					_mutationObserver.disconnect();
					NAMESPACE.util.helpers.triggerCallback(failCallback);
				}, timeout);
			}
		};

		/*
		 * Toggle visibility of the multilingual UI panel
		 *
		 * @param {Boolean} toggle 	Toggle visibility on/off
		 */
		_toggleDisplayVisibility = function(toggle) {
			_$multilingualPanel.toggleClass(CLASSES.HIDDEN, !toggle);
			_$headerLanguagesButton.attr('aria-expanded', toggle);

			// If the multilingual panel is open.
			if (toggle) {
				// Start monitoring clicks.
				window.addEventListener('click', _onClick, true);
			}
		};

		/** Close the multilingual panel (if open) whenever there is a click
		 * outside the boundaries of the multilingual panel.
		 * @param {MouseEvent} event
		 * @listens MouseEvent.type === 'click'
		 */
		_onClick = function onClick(event) {
			// Let `panel` be the multilingual panel.
			var panel = _$multilingualPanel[0];
			// Let `button` be the multilingual menu button
			var button = _$headerLanguagesButton[0];

			// If this event's click occurred outside `panel`'s boundaries.
			if (!panel.contains(event.target) && !button.contains(event.target)) {
				// Close `panel`.
				_toggleDisplayVisibility(false);
				// Stop monitoring clicks.
				window.removeEventListener('click', _onClick, true);
			}
		};

		/*
		 * Change the HTML doc 'lang' attribute
		 *
		 * @param {String} languageCode
		 */
		_changeHTMLlang = function(languageCode) {
			if (languageCode !== null) {
				$('html').attr('lang', languageCode);
			}
		};

		/**
		 * Save cookie to the browser
		 * @param cookieName
		 * @param languageCode
		 */
		_saveLanguageCookie = function(cookieName, languageCode) {
			if (Cookies.get(cookieName) !== languageCode) {
				Cookies.set(cookieName, languageCode, {
					domain: CONST.DOMAIN
				});
			}
		};

		init = function() {
			// These are defined globally (script scope)
			_$multilingualPanel = $(SELECTORS.LANGUAGE_UI_PANEL);
			_$headerLanguagesButton = $(SELECTORS.HEADER_LANGUAGE_MENU);

			// Bind header button click - toggle multilingual panel visibility
			_$headerLanguagesButton
				.off('click.togglePanel')
				.on('click.togglePanel', function() {

				_toggleDisplayVisibility(
					_$multilingualPanel.hasClass(CLASSES.HIDDEN)
				);
			});

			// Wait for Google Translate to load before initialising module components
			_setDOMmutationObserver(
				// Node to observe
				// Google Translate container
				$(SELECTORS.GT_CONTAINER).get(0),

				// Condition
				// When Google Translate dropdown options exist
				function() {
					return $(SELECTORS.GT_DROPDOWN).children().length >= Object.keys(CONST.LANGUAGES).length;
				},

				// Success callback
				function() {
					// Google Translate dropdown
					_$gtDropdown = $(SELECTORS.GT_DROPDOWN);
					_existingLanguageSetting = Cookies.get(CONST.LOCAL_STORAGE.LANGUAGE);

					// If existing language is not set, set it as English
					if (_existingLanguageSetting == null || _existingLanguageSetting === '') {
						Cookies.set('googtrans', '/en/en');
					}

					var _$options = _$gtDropdown.children(),
						_$multilingualPanelContainer = $(SELECTORS.LANGUAGE_UI_LIST_CONTAINER);

					// A11y keyboard navigation controls
					_$multilingualPanelContainer
						.off('keydown.a11yControls')
						.on('keydown.a11yControls', _keyboardActions);

					// Clear loading text
					// Bind translation trigger for the buttons within the UI component
					_$multilingualPanelContainer
						.text('')
						.on('click', function(event) {
							var _languageCode = $(event.target).closest(SELECTORS.LANGUAGE_UI_BUTTON).attr('value');

							if (_languageCode !== null) {
								_languageButtonClick(_languageCode, true);
							}
						});

					// Iterate through available languages in Google Translate
					_$options.each(function(idx, el) {
						var _$option = $(el),
							_language = _$option.attr('value'),

							_$button;

						// NOTE: Google Translate will only show the "English" (en) option SOME times
						// This is because the first option toggles between a placeholder value (when set to
						// English on load) and English (when set to any other language on load)
						if (idx === 0 && _language === '') {
							// So we can rightly discern that if the first option is blank, we need to manually add English as an option
							// (lest one never be able to return to English, after translating to another language for the first time)
							_language = 'en';
						}

						// If this language is a match against our list of supported languages, add
						// it as a translation option in the multilingual panel
						if (_language in CONST.LANGUAGES) {

							_$button = $(HTML.LANGUAGE_BUTTON);
							_$button
								.text(CONST.LANGUAGES[_language])
								.attr('value', _language);

							_$multilingualPanelContainer.append(_$button);
						}
					});

					// Check if user has set the language in another page/tab/session, apply those same settings
					// Conversely if retrieved setting is English, no action need be taken (and will in fact confuse Google Translate and bug out)
					if (_existingLanguageSetting !== null && _existingLanguageSetting !== '' && _existingLanguageSetting !== 'en') {

						_triggerGoogleTranslateEvent(_existingLanguageSetting, false);

					// If no languages were matches to the Google Translate dropdown, it must be English
					} else {
						_$multilingualPanelContainer.find('button').eq(0).addClass(CLASSES.SELECTED);
						_$headerLanguagesButton.removeClass(CLASSES.LOADING);
					}

					// Watch for language changes in other tabs
					$(window).bind('storage', function() {
						var _retrievedLanguage = localStorage.getItem(CONST.LOCAL_STORAGE.LANGUAGE);

						// Only apply if different to current language
						if (_retrievedLanguage !== null && _retrievedLanguage !== _existingLanguageSetting) {
							_existingLanguageSetting = _retrievedLanguage;
							_languageButtonClick(_retrievedLanguage, false);
						}
					});
				}
			);


			/** Fix an issue on Firefox when the page is translated to Burmese.
			 * Lines of text never break due the lack of white space characters
			 * in the language. This causes text to overflow its containing
			 * content box. The Burmese language has no concept of words. There
			 * is no clear separation whatsoever.
			 * @listens Event.type === 'languagechange'
			 */
			function onLanguageChange() {
				var body = document.body;

				// If the document has been translated into Burmese.
				if (document.documentElement.lang === 'my') {
					// Explicitly insert line breaks to avoid text from overflowing its containing content-box.
					body.style.wordBreak = 'break-all';
				} else {
				// Otherwise, if the document has been translated into any other language.
					// Do not insert any line breaks as it is not necessary.
					body.style.wordBreak = '';
				}
			}

			document.addEventListener('languagechange', onLanguageChange, true);
		};

		return {
			init: init
		};
	}());
}(DDIGITAL, jQuery));
