// ==========================================================================
// VIDEO
// ==========================================================================

(function(NAMESPACE, $) {

	'use strict';

	/**
	 * Creates a basic accessible youtube video
	 * Youtube progress events are accessible on the module.
	 * -1 – unstarted
	 *	0 – ended
	 *	1 – playing
	 *	2 – paused
	 *	3 – buffering
	 *	5 – video cued
	 *	The youtubeDictionary is a dict with .video IDs as keys.
	 * You can perform official methods on this from:
	 * https://developers.google.com/youtube/iframe_api_reference
	 *
	 * @namespace video
	 * @memberof DDIGITAL
	 * @version 0.1.0
	 * @author Deloitte Digital Australia
	 */
	NAMESPACE.video = (function() {
		var CONST,
			SELECTORS,
			init;

		CONST = {
			IFRAME_API_SRC: 'https://www.youtube.com/iframe_api'
		};

		SELECTORS = {
			YOUTUBE_VARIANT: '.cm-video.youtube'
		};

		var youtubeDictionary = {};

		init = function() {

			var youtubePlayerArray = [];

			if ($(SELECTORS.YOUTUBE_VARIANT).length === 0) {
				return;
			}

			// Init youtube variant. Other variants may be added later.
			$(SELECTORS.YOUTUBE_VARIANT).each(function(index) {

				var $videoContainer = $(this),
					videoId = $videoContainer.find('.video').attr('data-youtube-id'),
					videoElId,
					contentWrapperStart,
					contentWrapperEnd;

				// Accessibility
				/* eslint-disable quote-props */
				contentWrapperStart = $('<a />', {
					id: 'v-skip-' + index + '-top',
					href: '#v-skip-' + index + '-bot',
					'class': 'vh focusable',
					text: 'Skip to below video'
				});

				contentWrapperEnd = $('<a />', {
					id: 'v-skip-' + index + '-bot',
					href: '#v-skip-' + index + '-top',
					'class': 'vh focusable',
					text: 'Skip to above video'
				});
				/* eslint-enable quote-props */

				$videoContainer.find('.intrinsic-wrap').before(contentWrapperStart);
				$videoContainer.find('.intrinsic-wrap').after(contentWrapperEnd);

				// Set the id on the index;
				$videoContainer.find('.video').attr('id', videoId + '-video-' + index);

				videoElId = $videoContainer.find('.video').attr('id');

				if (!videoId || videoId.length === 0) {
					// No youtube ID attribute or the attribute is blank
					return;
				}

				// If there is no youtube API script, insert it.
				if ($('script[src="' + CONST.IFRAME_API_SRC + '"]').length === 0) {

					var tag,
						firstScript;

					tag = document.createElement('script');
					tag.src = CONST.IFRAME_API_SRC;

					firstScript = document.getElementsByTagName('script')[0];
					firstScript.parentNode.insertBefore(tag, firstScript);

				}

				// Attach a new video object to the array of present youtube videos.
				(function(videoId, videoElId) {

					var playerObject = {
						height: '100%',
						width: '100%',
						videoId: videoId,
						videoElId: videoElId,
						playerVars: {
							rel: '0'
						},
						events: {
							// The video has loaded to the page. Modify this function for preloaders etc.
							onReady: function() {
								$(document.getElementById(videoElId)).css('opacity', 1);
							},
							onStateChange: function(event) {
								$(document.getElementById(videoElId))
									.closest(SELECTORS.YOUTUBE_VARIANT)
									.trigger('youtubeStateChange.video', event);
								window.onPlayerStateChange(event);
							}
						}
					};

					youtubePlayerArray.push(playerObject);

				}(videoId, videoElId));

			});

			window.onYouTubeIframeAPIReady = function() {
				// trigger decoupled custom event, to notify analytics of video API event
				$('body').trigger('youtubeStateChange.iframeAPIReady');
				youtubePlayerArray.forEach(function(obj) {
					youtubeDictionary[obj.videoId] = new YT.Player(obj.videoElId, obj);
				});

			};

		};

		return {
			init: init,
			youtubeDictionary: youtubeDictionary
		};
	}());

}(DDIGITAL, jQuery));
