// ==========================================================================
// DO WHEN - A jQuery plugin to do stuff when you want
// https://github.com/dkeeghan/jQuery-doWhen
// ==========================================================================

(function(NAMESPACE, $) {

	'use strict';

	NAMESPACE.floatingBtn = (function() {

		var SELECTORS,
			toggleFloatingBtn,
			shutdownFloatingBtn,
			handleFloatingBtnClick,
			toggleFloatingBtnDisable,
			init;

		SELECTORS = {
			BTN: '#idFloatingBtn',
			BTN_TOGGLE: '#idFloatingBtnToggle',
			BTN_OPEN: '#idBtnOpen',
			BTN_CLOSE: '#idBtnClose',
			BTN_SHUTDOWN: '#idFloatingBtnShutdown'
		};

		/**
		 * toggle floating button
		 * @method _toggleFloatingBtn
		 * @return boolean
		 * @public
		 */
		toggleFloatingBtn = function() {
			if ($(SELECTORS.BTN_TOGGLE).hasClass('open')) {
				$(SELECTORS.BTN_TOGGLE).removeClass('open').addClass('close');
				$(SELECTORS.BTN_OPEN).show();
				$(SELECTORS.BTN_CLOSE).hide();

				// Log GA event
				if (window.dataLayer) {
					window.dataLayer.push({
						event : 'Close Floating CTA'
					});
				}
				return true;
			}
			$(SELECTORS.BTN_TOGGLE).removeClass('close').addClass('open');
			$(SELECTORS.BTN_CLOSE).show();
			$(SELECTORS.BTN_OPEN).hide();

			if (window.dataLayer) {
				window.dataLayer.push({
					event : 'Expand Floating CTA'
				});
			}
			return true;
		};

		/**
		 * shutdown floating button
		 * @method _shutdownFloatingBtn
		 * @public
		 */
		shutdownFloatingBtn = function() {
			$(SELECTORS.BTN).hide();
			$(SELECTORS.BTN_SHUTDOWN).hide();
			Cookies.set('shutdownFloatBtn', '1');

			if (window.dataLayer) {
				window.dataLayer.push({
					event : 'Disable Floating CTA'
				});
			}
			return false;
		};

		/**
		 * handle click on floating button
		 * @method handleFloatingBtnClick
		 * @public
		 */
		handleFloatingBtnClick = function(url) {
			if (window.dataLayer) {
				window.dataLayer.push({
					event : 'Click Floating CTA Link'
				});
			}

			if (url) {
				window.location.href = url;
			}
			return false;
		};

		/**
		 * this button enables disables Floating CTA button
		 * @method toggleFloatingBtnDisable
		 * @param status
		 * @public
		 */
		toggleFloatingBtnDisable = function(status) {
			if (status === 'open') {
				$(SELECTORS.BTN).css('opacity', 0.3).css('pointer-events', 'none');
				$(SELECTORS.BTN_SHUTDOWN).css('opacity', 0.3).css('pointer-events', 'none');
				return;
			}
			$(SELECTORS.BTN).css('opacity', 1).css('pointer-events', 'auto');
			$(SELECTORS.BTN_SHUTDOWN).css('opacity', 1).css('pointer-events', 'auto');
		};

		init = function() {
			if (Cookies.get('shutdownFloatBtn') === '1') {
				shutdownFloatingBtn();
				return;
			}
			$(SELECTORS.BTN_CLOSE).hide();
		};

		return {
			init: init,
			shutdownFloatingBtn: shutdownFloatingBtn,
			toggleFloatingBtn: toggleFloatingBtn,
			handleFloatingBtnClick: handleFloatingBtnClick,
			toggleFloatingBtnDisable: toggleFloatingBtnDisable
		};
	}());

	// HIDE CLOSE BUTTON
	DDIGITAL.floatingBtn.init();

}(DDIGITAL, jQuery));
