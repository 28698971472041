// ==========================================================================
// LIVEFORM - ONEPAGE MIXIN
// ==========================================================================

(function(NAMESPACE) {

	'use strict';

	/**
	 * Forms namespace
	 *
	 * @namespace DDIGITAL.forms
	 * @memberof DDIGITAL
	 */
	NAMESPACE.forms = NAMESPACE.forms || {};

	/**
	 * Multistep namespace
	 *
	 * @namespace DDIGITAL.forms.multistep
	 * @memberof DDIGITAL.forms
	 */
	NAMESPACE.forms.multistep = NAMESPACE.forms.multistep || {};

	/**
	 * Mixins namespace
	 *
	 * @namespace DDIGITAL.forms.multistep.mixins
	 * @memberof DDIGITAL.forms.multistep
	 */
	NAMESPACE.forms.multistep.mixins = NAMESPACE.forms.multistep.mixins || {};

	/**
	 * Mixin for liveForm type onepage
	 *
	 * @namespace DDIGITAL.forms.multistep.mixins.OnePage
	 * @memberOf DDIGITAL.forms.multistep.mixins
	 */
	NAMESPACE.forms.multistep.mixins.OnePage = function() {

		this.beforeInsertStep = function() {};

		this.animateStepIn = function(/* ... */) {
		};

		this.animateStepOut = function(/* ... */) {
		};
	};

}(DDIGITAL));
