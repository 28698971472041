// ==========================================================================
// BUILD INFORMATION
// ==========================================================================

window.DD_BUILD = window.DD_BUILD || {};

// Uncomment this line to emulate the global variables that are added to build/assets/js/script.js after doing a
// middleman build
//window.DD_BUILD = window.DD_BUILD || {}, window.DD_BUILD.NUMBER = '4', window.DD_BUILD.BRANCH = 'master';

(function(NAMESPACE, $) {

	'use strict';

	NAMESPACE.buildInfo = (function() {
		var init;

		init =  function() {

			// Very early exit if no build information is at hand
			if (DD_BUILD.NUMBER === undefined || DD_BUILD.BRANCH === undefined) {
				return;
			}

			var $target = $('.js-buildinfo');

			// Early exit if target DOM node is not present
			if ($target.length === 0) {
				return;
			}

			$target.each(function() {
				var $el = $(this);

				$el.removeClass('hidden')
					.find('.middleman.build-number')
					.text(DD_BUILD.NUMBER);

				$el.find('.middleman.datetime')
					.text(DD_BUILD.DATETIME || '');

				$el.find('.middleman.branch')
					.text(DD_BUILD.BRANCH);
			});
		};

		return {
			init: init
		};

	}());

}(DDIGITAL, jQuery));
