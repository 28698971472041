// ==========================================================================
// LIVEFORM - WIZARD MIXIN
// ==========================================================================

(function(NAMESPACE) {

	'use strict';

	/**
	 * Forms namespace
	 *
	 * @namespace DDIGITAL.forms
	 * @memberof DDIGITAL
	 */
	NAMESPACE.forms = NAMESPACE.forms || {};

	/**
	 * Multistep namespace
	 *
	 * @namespace DDIGITAL.forms.multistep
	 * @memberof DDIGITAL.forms
	 */
	NAMESPACE.forms.multistep = NAMESPACE.forms.multistep || {};

	/**
	 * Mixins namespace
	 *
	 * @namespace DDIGITAL.forms.multistep.mixins
	 * @memberof DDIGITAL.forms.multistep
	 */
	NAMESPACE.forms.multistep.mixins = NAMESPACE.forms.multistep.mixins || {};

	/**
	 * Mixin for liveForm type wizard
	 *
	 * @namespace DDIGITAL.forms.multistep.mixins.Wizard
	 * @memberOf DDIGITAL.forms.multistep.mixins
	 */
	NAMESPACE.forms.multistep.mixins.Wizard = function() {

		/**
		 * Manipulates step DOM node prior to it being inserted into the DOM
		 *
		 * @param {Object} $step Reference to step DOM node
		 */
		this.beforeInsertStep = function($step) {
			$step.addClass('hidden');
		};

		/**
		 * Animates a step into view before calling the callback which will trigger the activation
		 * of the step
		 *
		 * @param {Object} $step Reference to step DOM node
		 * @param {Function} callback Should be triggered when step should be activated
		 */
		this.animateStepIn = function($step, callback) {

			$step
				.removeClass('hidden')
				.velocity('stop')
				.velocity({
					opacity: 1
				}, {
					easing: 'easeOutQuart',
					duration: 500,
					complete: callback
				});
		};

		/**
		 * Animates a step out of view before calling the callback which will trigger the
		 * animateStepIn sequence
		 *
		 * @param {Object} $step Reference to step DOM node
		 * @param {Function} callback When triggered, the animateStepIn sequence will start
		 */
		this.animateStepOut = function($step, callback) {

			$step
				.velocity('stop')
				.velocity({
					opacity: 0
				}, {
					easing: 'easeOutQuart',
					duration: 500,
					complete: function() {
						$step.addClass('hidden');
						callback();
					}
				});
		};
	};

}(DDIGITAL));
