window.DDIGITAL = window.DDIGITAL || {};

// ==========================================================================
// Broker Hub
// ==========================================================================

(function(NAMESPACE, $) {
	'use strict';

	NAMESPACE.brokerhub = (function() {
		var init;
		init = function() {
			var scrollBtn = $('#brokerHubScrollBtn');
			var pageHeight = $(document).height();
			var scrollPageHeight = pageHeight * 0.25;
			$(window).on('scroll', function() {
				if ($(this).scrollTop() > scrollPageHeight) {
					scrollBtn.addClass('show');
				} else {
					scrollBtn.removeClass('show');
				}
			});

			$('.scroll-cta').on('click', function() {
				if (window.innerWidth >= 1024) {
					if ($('.global-header')[0]) {
						var brokerHubLinkId = $(this).attr('href');

						const idScroll = $(brokerHubLinkId).offset().top;

						$('html, body').animate(
							{
								scrollTop: idScroll - 150
							},
							'slow'
						);
					}
				}
			});

			scrollBtn.on('click', function(e) {
				e.preventDefault();
				if (window.innerWidth >= 1024) {
					$('html, body').scrollTop(
						$('.scroll-to-broker-quicklink').offset().top - 112
					);
				} else {
					$('html, body').scrollTop(
						$('.scroll-to-broker-quicklink').offset().top
					);
				}
			});
		};

		return {
			init: init
		};
	})();
})(DDIGITAL, jQuery);
