window.DDIGITAL = window.DDIGITAL || {};

// ==========================================================================
// Banner Search
// ==========================================================================

(function(NAMESPACE, $) {
	'use strict';
	NAMESPACE.bannerSearch = (function() {
		var init;
		init = function() {

			var searchBtn = $('#search-icon-legacy');

			$('.disabled-input').addClass('show-image');
			$('.active-input').addClass('hide-image');

			$('#banner-search-input').on('input', function() {
				$('.disabled-input').removeClass('show-image');
				$('.disabled-input').removeClass('hide-image');
				$('.active-input').removeClass('hide-image');
				$('.active-input').removeClass('show-image');

				if ((document.getElementById('banner-search-input').value).length === 0) {
					searchBtn.addClass('disabled');
					$('#search-icon-legacy').click(false);
					$('.disabled-input').addClass('show-image');
					$('.active-input').addClass('hide-image');

				} else {
					searchBtn.removeClass('disabled');
					$('#search-icon-legacy').click(true);
					$('.disabled-input').addClass('hide-image');
					$('.active-input').addClass('show-image');
				}
			});
		};
		$('.search-button-container').on('keypress', function(e) {
			if (e.which === 13) {
				if ((document.getElementById('banner-search-input').value).length === 0) {
					$('#search-icon-legacy').click();
				} else {
					$('#search-form-submit').trigger('submit');
				}
			}
		});

		return {
			init: init

		};
	})();
})(DDIGITAL, jQuery);
