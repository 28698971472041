// ==========================================================================
// LIVE FORMS
// ==========================================================================

(function(NAMESPACE) {

	'use strict';

	/**
	 * Forms namespace
	 *
	 * @namespace DDIGITAL.forms
	 * @memberof DDIGITAL
	 */
	NAMESPACE.forms = NAMESPACE.forms || {};

	/**
	 * Form validation with jQuery validate
	 *
	 * @namespace DDIGITAL.forms.sync
	 * @memberOf DDIGITAL.forms
	 */
	NAMESPACE.forms.sync = (function() {
		var OPTIONS,
			SELECTORS,
			_parseSyncData,
			_renderSummaryAndErrors,
			_syncForm,
			_handleAjaxRequest,
			_syncFormOnLoad,
			_syncFormOnSubmit,
			handleNetworkError,
			init;

		OPTIONS = {
			AJAX_TIMEOUT: 10000,
			AJAX_MIN_DELAY: 2000,
			DATA: {
				IS_INIT: 'form-sync-is-init',
				SYNC: 'form-sync',
				STATUS: 'form-status',
				FORCE_SUBMIT: 'form-sync-force-submit',
				REQUEST: 'form-sync-request'
			},
			NEXT: {
				ACTIONS: {
					partial: function($form, value) {
						$form.trigger('next-action-partial.sync', value);
					},
					redirect: function($form, value) {
						window.location = value.redirect;
					},
					reset: function($form, value) {
						if (value.reset === true) {
							$form.get(0).reset();
							NAMESPACE.forms.validate.reset($form);
						}
					},
					submit: function($form, value) {
						if (value.submit === true) {
							$form.data(OPTIONS.DATA.FORCE_SUBMIT, true);
							NAMESPACE.forms.decorator.setDisabledState($form, false);

							$form.get(0).submit();
						}
					}
				}
			},
			ERRORS: {
				CONNECTION_ERROR: {
					status: [
						{
							type: 'warning',
							title: 'Connection error',
							description: 'There has been a connection error with the server. Please check your internet connection and try again.'
						}
					]
				}
			}
		};

		SELECTORS = {
			SYNC_FORM: '.js-validate-sync'
		};

		/**
		 * @param {Object} data JSON data as returned from endpoint
		 * @returns {{status: boolean, next: boolean}}
		 * @private
		 * @inner
		 * @memberOf DDIGITAL.forms.sync
		 */
		_parseSyncData = function(data) {
			if (!data.status && !data.next) {
				throw new Error('DDIGITAL.forms.sync: Invalid JSON format. Expected at least a `status` or `next` item.');
			}

			var syncObject = {
				status: false,
				next: false
			};

			if (data.status) {
				syncObject.status = [];

				for (var i = 0, len = data.status.length; i < len; i += 1) {
					var statusData = data.status[i];

					var statusItem = {
						type: statusData.type || '',
						title: statusData.title || false,
						description: statusData.description || false,
						controls: []
					};

					if (statusData.controls) {
						for (var i = 0, len = statusData.controls.length; i < len; i += 1) {
							var control = statusData.controls[i],
								$element = $('#' + control.id);

							if ($element.length === 0) {
								$element = $('[name="' + control.id + '"]');
							}

							if ($element.length > 0) {
								statusItem.controls.push({
									element: $element.get(0),
									message: control.message
								});
							}
						}
					}

					syncObject.status.push(statusItem);
				}
			}

			if (data.next) {
				var found = false;

				for (var type in OPTIONS.NEXT.ACTIONS) {
					if (OPTIONS.NEXT.ACTIONS.hasOwnProperty(type) && data.next.hasOwnProperty(type)) {
						syncObject.next = data.next;
						syncObject.action = OPTIONS.NEXT.ACTIONS[type];
						found = true;
					}
				}

				if (!found) {
					console.warn('DDIGITAL.forms.sync: Invalid next action passed:', data.next);
				}
			}

			return syncObject;
		};

		/**
		 * Triggers error summary and inline error rendering by delegating to the decorator
		 *
		 * @param {Object} $form Reference to the form element
		 * @param {Array} statuses Array of status objects
		 * @param {Boolean} skipAnimation Defaults to false
		 * @private
		 * @inner
		 * @memberOf DDIGITAL.forms.sync
		 */
		_renderSummaryAndErrors = function($form, statuses, skipAnimation) {
			skipAnimation = skipAnimation || false;

			for (var i = 0, len = statuses.length; i < len; i += 1) {
				var status = statuses[i];

				NAMESPACE.forms.decorator.formSummary.add(status.type, $form, status.controls, status.title, status.description, true, skipAnimation);

				if (status.type === 'error') {
					NAMESPACE.forms.decorator.inlineErrors.add(status.controls);
				}
			}
		};

		/**
		 * Takes JSON response data returned from server and triggers a render of the errors
		 * contained in it
		 *
		 * @param {Object} $form Reference to the form element
		 * @param {Object} data The data that was returned form the server
		 * @param {Boolean} skipAnimation Animation of the form summary yes/no
		 * @private
		 * @inner
		 * @memberOf DDIGITAL.forms.sync
		 */
		_syncForm = function($form, data, skipAnimation) {
			skipAnimation = skipAnimation || false;

			var syncObject = _parseSyncData(data);

			if (typeof data.next === 'object') {
				$form.trigger('submit-success.sync', data.next);
			}

			if (typeof (syncObject.action) === 'function') {
				syncObject.action($form, syncObject.next);
			}

			if (syncObject.status !== false && typeof data.next !== 'object') {
				$form.trigger('submit-invalid.sync');
				_renderSummaryAndErrors($form, syncObject.status, skipAnimation);
			}
		};

		/**
		 * Serialises form data and triggers async submission
		 *
		 * @param {HTMLElement} form Reference to unpacked form element
		 * @private
		 */
		_handleAjaxRequest = function(form) {
			var $form = $(form);

			if ($form.data(OPTIONS.DATA.REQUEST)) {
				// abort a request if already running
				$form.data(OPTIONS.DATA.REQUEST).abort();
				$form.data(OPTIONS.DATA.REQUEST, false);
			}

			var url = $form.data(OPTIONS.DATA.SYNC),
				method = $form.attr('method') || 'post',
				data = $form.serialize(),
				isReady = true,
				actionsAfterReady = [];

			$form.trigger('submit-start.sync');

			setTimeout(function() {
				isReady = true;

				while (actionsAfterReady.length) {
					actionsAfterReady.shift().call();
				}
			}, OPTIONS.AJAX_MIN_DELAY);

			// set the form to loading state
			NAMESPACE.forms.decorator.setLoadingState($form, true);
			NAMESPACE.forms.decorator.setDisabledState($form, true);

			// do ajax call
			var request = $.ajax({
				timeout: OPTIONS.AJAX_TIMEOUT,
				type: method,
				dataType: 'json',
				url: url,
				data: data
			});

			// save the request where it can be retrieved
			$form.data(OPTIONS.DATA.REQUEST, request);

			// on success of the sync ajax call
			request.then(function(data) {
				if (isReady) {
					_syncForm($form, data);
				} else {
					actionsAfterReady.push(function() {
						_syncForm($form, data);
					});
				}
			});

			// on fail of the ajax call
			request.fail(function() {
				if (isReady) {
					handleNetworkError($form);
				} else {
					actionsAfterReady.push(function() {
						handleNetworkError($form);
					});
				}
			});

			// on success or failure
			request.always(function() {
				$form.data(OPTIONS.DATA.REQUEST, false);

				if (isReady) {
					NAMESPACE.forms.decorator.setLoadingState($form, false);
					NAMESPACE.forms.decorator.setDisabledState($form, false);
					$form.trigger('submit-end.sync');
				} else {
					actionsAfterReady.push(function() {
						NAMESPACE.forms.decorator.setLoadingState($form, false);
						NAMESPACE.forms.decorator.setDisabledState($form, false);
						$form.trigger('submit-end.sync');
					});
				}
			});
		};

		/**
		 * Handles submit event on form
		 *
		 * @param {Object} event Submit event
		 * @private
		 */
		_syncFormOnSubmit = function(event) {
			var $form = $(this),
				validator = NAMESPACE.forms.validate.getValidatorFromElement(this);

			// allow client side validation to do it's thing.
			if (validator.errorList.length > 0 || validator.pendingRequest > 0) {
				return;
			}

			// force default submission if it's enabled
			if ($form.data(OPTIONS.DATA.FORCE_SUBMIT) === true) {
				return;
			}

			event.preventDefault();

			NAMESPACE.forms.decorator.formSummary.remove($form);

			_handleAjaxRequest(this);
		};

		/**
		 * Grabs JSON string from status attribute and renders errors any contained in it
		 *
		 * @param {Object} $form Reference to form element
		 * @private
		 */
		_syncFormOnLoad = function($form) {
			var json = $form.data(OPTIONS.DATA.STATUS);

			if (typeof (json) === 'undefined') {
				return;
			}

			if (typeof (json) === 'string' && json === '') {
				console.warn('DDIGITAL.forms.sync: Empty string in `data-form-status`, ignoring.');
				return;
			}

			if (typeof (json) === 'string') {
				throw new Error('DDIGITAL.forms.sync: Invalid JSON data entered into the `data-form-status` attribute.');
				return;
			}

			_syncForm($form, json, true);
		};

		/**
		 * Renders error message that tells user a network issue occurred
		 * @param {Object} $form jQuery reference to form element
		 * @memberOf DDIGITAL.forms.sync
		 */
		handleNetworkError = function($form) {
			_syncForm($form, OPTIONS.ERRORS.CONNECTION_ERROR);
			$form.trigger('submit-fail.sync', OPTIONS.ERRORS.CONNECTION_ERROR);
		};

		/**
		 * @memberOf DDIGITAL.forms.sync
		 */
		init = function() {
			$(SELECTORS.SYNC_FORM).each(function(i, el) {
				var $form = $(el);

				// ensure it can't be enabled twice
				if ($form.data(OPTIONS.DATA.IS_INIT) === true) {
					return;
				}

				// is initialised
				$form.data(OPTIONS.DATA.IS_INIT, true);

				// show the error/status on page load
				_syncFormOnLoad($form);

				// don't bind the form submit code if a url endpoint isn't specified
				if (typeof ($form.data(OPTIONS.DATA.SYNC)) !== 'string') {
					console.warn('DDIGITAL.forms.sync: URL not provided, sync on submission not enabled.');
					return;
				}

				$form.on('submit.ddSync', _syncFormOnSubmit);
			});
		};

		return {
			init: init
		};

	}());

}(DDIGITAL));
