// ==========================================================================
// FORM DECORATOR
// ==========================================================================

(function(NAMESPACE) {

	'use strict';

	/**
	 * Forms namespace
	 *
	 * @namespace DDIGITAL.forms
	 * @memberof DDIGITAL
	 */
	NAMESPACE.forms = NAMESPACE.forms || {};

	/**
	 * Form validation with jQuery validate
	 *
	 * @namespace DDIGITAL.forms.decorator
	 * @memberOf DDIGITAL.forms
	 */
	NAMESPACE.forms.decorator = (function() {

		var CONST,
			CLASSES,
			SELECTORS,
			_animations,
			getSelectors,
			getFormFromElement,
			getCtrlHolderFromElement,
			getLabelTextFromCtrlHolder,
			getCtrlsInGroup,
			isFirstInGroup,
			formSummary,
			inlineErrors,
			errorPlacement,
			highlight,
			unhighlight,
			addAriaInvalid,
			createErrorMessage,
			setDisabledState,
			setLoadingState;

		CONST = {
			SUMMARY: {
				TYPES: {
					ERROR: 'error',
					WARNING: 'warning',
					INFO: 'info',
					SUCCESS: 'success'
				},
				SCROLL_TO: true
			},
			DATA: {
				DISABLED_ITEMS: 'form-disabled-items'
			}
		};

		CLASSES = {
			IS_VALID: 'is-valid',
			IS_ERROR: 'is-error',
			IS_SUCCESS: 'is-success',
			IS_INFO: 'is-info',
			IS_WARNING: 'is-warning',
			IS_LOADING: 'is-loading',
			SUMMARY: 'form-summary',
			SUMMARY_TITLE: 'form-summary-title',
			ERROR: 'error'
		};

		SELECTORS = {
			FORM: '.js-validate',
			SUMMARY: '.js-validate-summary',
			SUMMARY_INNER: '.form-summary',
			CTRL_HOLDER: '.ctrl-holder',
			CTRLS: '.ctrls',
			LABEL: '> label, > .label',
			LEGEND: 'legend, .legend',
			STATUS_MSG: '.status-msg',
			ELEMENTS: ':input, select'
		};

		_animations = (function() {
			var showSummary;

			showSummary = function($container, callback) {
				$container.css({
					display: 'none',
					opacity: 0
				});

				$container.velocity('slideDown', {
					duration: 150
				}).velocity({
					opacity: 1,
					duration: 200,
					onComplete: callback
				});
			};

			return {
				showSummary: showSummary
			};
		}());

		/**
		 * Returns the SELECTORS constant for use externally.
		 *
		 * @return {Object}
		 * @memberOf DDIGITAL.forms.decorator
		 */
		getSelectors = function() {
			return SELECTORS;
		};

		/**
		 * Returns the form of a particular form element
		 *
		 * @param {DOM} element Current form item
		 * @return {Object}
		 * @memberOf DDIGITAL.forms.decorator
		 */
		getFormFromElement = function(element) {
			return $(element).closest(SELECTORS.FORM);
		};

		/**
		 * Returns the form element's control holder given the form element itself
		 *
		 * @param {DOM} element Current form item
		 * @param {Boolean} ownParentOnly Flag to get it's own parent only
		 * @return {Object}
		 * @memberOf DDIGITAL.forms.decorator
		 */
		getCtrlHolderFromElement = function(element, ownParentOnly) {
			ownParentOnly = ownParentOnly || false;

			if ($(element).closest(SELECTORS.CTRLS).length > 0 && !ownParentOnly) {
				return $(element).closest(SELECTORS.CTRLS).closest(SELECTORS.CTRL_HOLDER);
			}

			return $(element).closest(SELECTORS.CTRL_HOLDER);
		};

		/**
		 * Returns the cleaned label text (removes all child elements and text)
		 *
		 * @param {jQuery} $ctrlHolder The ctrl-holder element
		 * @return {Object} Object returning formLabel and groupLabel text
		 * @memberOf DDIGITAL.forms.decorator
		 */
		getLabelTextFromCtrlHolder = function($ctrlHolder) {
			var $label = $ctrlHolder.find(SELECTORS.LABEL).filter(':visible'),
				groupLabelText = $ctrlHolder.closest('fieldset').find(SELECTORS.LEGEND).text(),
				labelText,
				_getCleanText;

			_getCleanText = function($elem) {
				// remove text from child elements, and whitespace
				return $elem.clone().children().remove().end().text().replace(/^\s+|\s+$/g, '');
			};

			// for options
			if ($ctrlHolder.find(SELECTORS.LABEL).length === 0) {
				if ($ctrlHolder.find('.option').length === 1) {
					$label = $ctrlHolder.find('.option > label');
				}
			}

			// grouped fields
			if ($ctrlHolder.closest(SELECTORS.CTRLS).length > 0) {
				$label = $ctrlHolder.closest(SELECTORS.CTRL_HOLDER).find(SELECTORS.LABEL);
			}

			labelText = _getCleanText($label);

			return {
				formLabel: labelText,
				groupLabel: labelText + '_' + groupLabelText
			};
		};

		/**
		 * Returns the form element's list of controls
		 *
		 * @param {DOM} element Current form item
		 * @return {jQuery}
		 * @memberOf DDIGITAL.forms.decorator
		 */
		getCtrlsInGroup = function(element) {
			var $ctrlHolder = getCtrlHolderFromElement(element);
			return $ctrlHolder.find(SELECTORS.ELEMENTS);
		};

		/**
		 * Returns true if control is the first in a group
		 *
		 * @memberOf DDIGITAL.forms.decorator
		 * @param {object} $ctrl Reference to control element
		 * @return {boolean}
		 */
		isFirstInGroup = function($ctrl) {
			var $ctrls = getCtrlsInGroup($ctrl);
			return $ctrls.first().is($ctrl);
		};

		/**
		 * Creation and placement of the form summary modules
		 *
		 * @namespace DDIGITAL.forms.decorator.formSummary
		 * @memberOf DDIGITAL.forms.decorator
		 */
		formSummary = (function() {
			var _renderListItem,
				_renderList,
				scrollTo,
				add,
				remove,
				_renderSummaryTitle,
				_renderSummaryDescription,
				_renderErrors;

			/**
			 * Renders a list item for an error
			 *
			 * @param {Object} error Error object item
			 * @return {jQuery}
			 * @private
			 * @memberOf DDIGITAL.forms.decorator.formSummary
			 */
			_renderListItem = function(error) {
				/*
				Format of error is:
				{
					element: (elem) DOM Element of the input,
					message: (string) Error Message,
					method: (string) Error type e.g. email
				}
				*/

				var ctrl = error.element,
					$ctrl = $(error.element),
					$ctrlHolder = getCtrlHolderFromElement(error.element),
					$li = $('<li />'),
					$link,
					$label,
					labelText;

				// Only render error message once for each group
				if (NAMESPACE.forms.validate.isInGroup(ctrl) && !isFirstInGroup($ctrl)) {
					return;
				}

				labelText = getLabelTextFromCtrlHolder($ctrlHolder);

				$link = $('<a />', {
					href: '#' + $ctrl.attr('id'),
					class: 'scrollto',
					html: error.message
				});

				$label = $('<strong />', {
					text: labelText.formLabel + ': '
				});

				$link.prepend($label);
				$li.append($link);

				return $li;
			};

			/**
			 * Renders given summary title
			 * @param {String} summaryTitle Title for error summary
			 * @param {Object} $errorContainer Container to inject title into
			 * @private
			 * @memberOf DDIGITAL.forms.decorator.formSummary
			 */
			_renderSummaryTitle = function(summaryTitle, $errorContainer) {
				if (summaryTitle !== false) {
					var $summaryTitle = $('<div />', {
						class: CLASSES.SUMMARY_TITLE,
						html: $('<h2 />', {
							text: summaryTitle
						})
					});

					$errorContainer.append($summaryTitle);
				}
			};

			/**
			 * Renders given summary description
			 * @param {String} summaryDescription Description for error summary
			 * @param {Object} $errorContainer Container to inject description into
			 * @private
			 * @memberOf DDIGITAL.forms.decorator.formSummary
			 */
			_renderSummaryDescription = function(summaryDescription, $errorContainer) {
				if (summaryDescription !== false) {
					var $summaryDescription = $('<p />', {
						text: summaryDescription
					});

					$errorContainer.append($summaryDescription);
				}
			};

			/**
			 * Renders list of errors
			 * @param {Array} errors List of errors form jQuery validate
			 * @param {Object} $errorContainer Container to inject error list into
			 * @private
			 */
			_renderErrors = function(errors, $errorContainer) {
				if (errors.length === 0) {
					return;
				}

				var $ul,
					$listTitle,
					listIntroText = 'The following',
					errorCount = 0;

				$ul = $('<ul />');

				for (var i = 0, len = errors.length; i < len; i += 1) {
					var $listItem = _renderListItem(errors[i]);

					if (typeof $listItem === 'object') {
						$ul.append($listItem);
						errorCount += 1;
					}
				}

				listIntroText += (errorCount > 1) ? ' ' + errorCount + ' fields contain errors:'
					: ' field contains an error:';

				$listTitle = $('<p />', {
					text: listIntroText
				});

				$errorContainer.append($listTitle);
				$errorContainer.append($ul);
			};

			/**
			 * Renders the list given errors, summaryTitle and summaryDescription
			 *
			 * @param {Object} type Type of summary to render (valid options 'error', 'warning', 'info', 'success')
			 * @param {Array} errors Error object array
			 * @param {String|Boolean} [summaryTitle=false] Optional title text to display
			 * @param {String|Boolean} [summaryDescription=false] Optional description text to display prepended to the list
			 * @return {jQuery}
			 * @private
			 * @memberOf DDIGITAL.forms.decorator.formSummary
			 */
			_renderList = function(type, errors, summaryTitle, summaryDescription) {
				summaryTitle = summaryTitle || false;
				summaryDescription = summaryDescription || false;

				// don't render if nothing is passed through
				if (errors.length === 0 && summaryTitle === false && summaryDescription === false) {
					return false;
				}

				var containerClasses = [CLASSES.SUMMARY],
					$errorContainer;

				// types
				if (type === CONST.SUMMARY.TYPES.ERROR) {
					containerClasses.push(CLASSES.IS_ERROR);
				} else if (type === CONST.SUMMARY.TYPES.WARNING) {
					containerClasses.push(CLASSES.IS_WARNING);
				} else if (type === CONST.SUMMARY.TYPES.INFO) {
					containerClasses.push(CLASSES.IS_INFO);
				} else if (type === CONST.SUMMARY.TYPES.SUCCESS) {
					containerClasses.push(CLASSES.IS_SUCCESS);
				}

				$errorContainer = $('<div />', {
					class: containerClasses.join(' '),
					tabindex: '-1'
				});

				_renderSummaryTitle(summaryTitle, $errorContainer);
				_renderSummaryDescription(summaryDescription, $errorContainer);
				_renderErrors(errors, $errorContainer);

				return $errorContainer;
			};

			/**
			 * Scrolls the page to the current form summary
			 *
			 * @param {jQuery} $form Form containing the error summary
			 * @memberOf DDIGITAL.forms.decorator.formSummary
			 */
			scrollTo = function($form) {
				var $summaryContainer = $form.find(SELECTORS.SUMMARY),
					pageTop = $(document).scrollTop(),
					pageBottom = pageTop + $(window).height(),
					offset,
					afterScroll;

				afterScroll = function() {
					$summaryContainer.find('.' + CLASSES.SUMMARY).eq(0).focus(); //TODO: REFACTOR THIS ONE
				};

				// display the container to get the position
				offset = $summaryContainer.offset().top - 50;

				if (offset > pageTop && offset < pageBottom) {
					afterScroll();
					// is currently in the page so don't scroll
					return;
				}

				// scroll the page
				NAMESPACE.util.scroll.page(offset, null, 250, afterScroll);
			};

			/**
			 * Renders the list given errors, summaryTitle and summaryDescription
			 *
			 * @param {Object} type Type of summary to render (valid options 'error', 'warning', 'info', 'success')
			 * @param {jQuery} $form Form to add the error summary to
			 * @param {Array} errors Error object array
			 * @param {String=false} summaryTitle Optional title text to display
			 * @param {String=false} summaryDescription Optional description text to display prepended to the list
			 * @param {Boolean=false} scrollToSummary Scroll to the summary message
			 * @memberOf DDIGITAL.forms.decorator.formSummary
			 */
			add = function(type, $form, errors, summaryTitle, summaryDescription, scrollToSummary, skipAnimation) {
				summaryTitle = summaryTitle || false;
				summaryDescription = summaryDescription || false;
				scrollToSummary = scrollToSummary || CONST.SUMMARY.SCROLL_TO;
				skipAnimation = skipAnimation || false;

				var $summaryContainer = $form.find(SELECTORS.SUMMARY);

				if ($summaryContainer.length === 0) {
					return;
				}

				errors = errors || [];

				if (errors.length > 0 || summaryTitle || summaryDescription) {
					var $errorContainer = _renderList(type, errors, summaryTitle, summaryDescription);
					$summaryContainer.html($errorContainer);

					if (scrollToSummary) {
						scrollTo($form);
					}

					if (!skipAnimation) {
						_animations.showSummary($summaryContainer);
					}
				} else {
					remove($form);
				}

				//click and keyup event on error list in form moves focus to respective fields through below function
				$summaryContainer.find('.scrollto').on('click', function() {
					$($(this).attr('href')).focus();
				});

				$summaryContainer.find('.scrollto').on('keyUp', function(event) {
					if (event.keyCode === 13) {
						$($(this).attr('href')).focus();
					}
				});
			};

			/**
			 * Removes the form summary from the page
			 *
			 * @param {jQuery} $form Form containing the error summary
			 * @memberOf DDIGITAL.forms.decorator.formSummary
			 */
			remove = function($form) {
				$form.find(SELECTORS.SUMMARY).empty();
			};

			return {
				scrollTo: scrollTo,
				add: add,
				remove: remove
			};
		}());

		/**
		 * Creation and placement of the inline error items
		 *
		 * @namespace DDIGITAL.forms.decorator.inlineErrors
		 * @memberOf DDIGITAL.forms.decorator
		 */
		inlineErrors = (function() {
			var add;

			/**
			 * Adds inline errors to the page
			 *
			 * @param {Array} errors Array of error objects
			 * @memberOf DDIGITAL.forms.decorator.inlineErrors
			 */
			add = function(errors) {
				if (errors.length > 0) {
					for (var i = 0, len = errors.length; i < len; i += 1) {
						var error = errors[i],
							validator = NAMESPACE.forms.validate.getValidatorFromElement(error.element);

						if (validator) {
							validator.showLabel(error.element, error.message);
						} else {
							errorPlacement(createErrorMessage(error.message), error.element);
						}

						addAriaInvalid(error.element);
						highlight(error.element);
					}
				}
			};

			return {
				add: add
			};
		}());

		/**
		 * Place the error message into the page
		 *
		 * @param {jQuery} $error Error jQuery object
		 * @param {DOM} element Form element on the page
		 * @memberOf DDIGITAL.forms.decorator
		 */
		errorPlacement = function($error, element) {
			var $ctrlHolder = getCtrlHolderFromElement(element),
				$statusContainer = $ctrlHolder.find(SELECTORS.STATUS_MSG);

			$statusContainer.html($error);
		};

		/**
		 * Highlight the ctrl holder when an error has occurred
		 *
		 * @param {DOM} element Form element on the page
		 * @memberOf DDIGITAL.forms.decorator
		 */
		highlight = function(element) {
			var $ctrlHolder = getCtrlHolderFromElement(element);
			$ctrlHolder.removeClass(CLASSES.IS_VALID).addClass(CLASSES.IS_ERROR);
		};

		/**
		 * Unhighlight the ctrl holder when an error has been cleared
		 *
		 * @param {DOM} element Form element on the page
		 * @memberOf DDIGITAL.forms.decorator
		 */
		unhighlight = function(element) {
			var $ctrlHolder = getCtrlHolderFromElement(element);
			$ctrlHolder.addClass(CLASSES.IS_VALID).removeClass(CLASSES.IS_ERROR);
		};

		/**
		 * Adds aria-invalid to an element
		 *
		 * @param {DOM} element Form element on the page
		 * @memberOf DDIGITAL.forms.decorator
		 */
		addAriaInvalid = function(element) {
			$(element).attr('aria-invalid', 'true');
		};

		/**
		 * Create the error message for positioning on the page
		 *
		 * @param {DOM} message Message to be displayed as text
		 * @return {jQuery}
		 * @memberOf DDIGITAL.forms.decorator
		 */
		createErrorMessage = function(message) {
			return $('<div />', {
				class: CLASSES.ERROR,
				html: message
			});
		};

		/**
		 * Enables or Disables all the forms fields in the form. Will not
		 * re-enable form fields that were previously disabled.
		 *
		 * @param {jQuery} $form Form element on the page
		 * @param {Boolean} state Whether to enable or disable the fields
		 * @memberOf DDIGITAL.forms.decorator
		 */
		setDisabledState = function($form, state) {
			var setElementToDisabled = function($items, state) {
				$items.prop('disabled', state);
			};

			if (state) {
				var $itemsToDisable = $form.find(SELECTORS.ELEMENTS).not(':disabled');

				$form.data(CONST.DATA.DISABLED_ITEMS, $itemsToDisable);

				setElementToDisabled($itemsToDisable, true);
			} else {
				var $disabledItems = $form.data(CONST.DATA.DISABLED_ITEMS);

				if ($disabledItems) {
					setElementToDisabled($disabledItems, false);
				}
			}
		};

		/**
		 * Set the form to display or hide the loading state
		 *
		 * @param {jQuery} $form Form element on the page
		 * @param {Boolean} state Whether to enable or disable the loading state
		 * @memberOf DDIGITAL.forms.decorator
		 */
		setLoadingState = function($form, state) {
			$form.attr('aria-busy', state);

			if (state) {
				$form.addClass(CLASSES.IS_LOADING);
			} else {
				$form.removeClass(CLASSES.IS_LOADING);
			}
		};

		return {
			getSelectors: getSelectors,
			getFormFromElement: getFormFromElement,
			getCtrlHolderFromElement: getCtrlHolderFromElement,
			getLabelTextFromCtrlHolder: getLabelTextFromCtrlHolder,
			getCtrlsInGroup: getCtrlsInGroup,
			isFirstInGroup: isFirstInGroup,
			formSummary: formSummary,
			inlineErrors: inlineErrors,
			errorPlacement: errorPlacement,
			highlight: highlight,
			unhighlight: unhighlight,
			addAriaInvalid: addAriaInvalid,
			createErrorMessage: createErrorMessage,
			setDisabledState: setDisabledState,
			setLoadingState: setLoadingState
		};

	}());

}(DDIGITAL));
