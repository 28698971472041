// ==========================================================================
// MULTI STEP FORM
// ==========================================================================

(function(NAMESPACE, $) {

	'use strict';

	/**
	 * Forms namespace
	 *
	 * @namespace DDIGITAL.forms
	 * @memberof DDIGITAL
	 */
	NAMESPACE.forms = NAMESPACE.forms || {};

	/**
	 * Multistep namespace
	 *
	 * @namespace DDIGITAL.forms.multistep
	 * @memberof DDIGITAL
	 */
	NAMESPACE.forms.multistep = NAMESPACE.forms.multistep || {};

	/**
	 * Multi step form service
	 *
	 * @namespace DDIGITAL.forms.multistep.service
	 * @memberOf DDIGITAL.forms.multistep
	 */
	NAMESPACE.forms.multistep.service = (function() {
		var init,
			OPTIONS,
			getPartial,
			getStepFromIndex,
			getStep,
			getIndexFromStep,
			getURLPathFromStep,
			getSteps,
			getFormTitle,
			getFormId,
			makeAbsolute,
			_data;

		OPTIONS = {
			timeout: 10000
		};

		/**
		 * Fetches a partial from an endpoint on the server
		 * @returns {Promise}
		 * @memberOf DDIGITAL.forms.multistep.service
		 * @instance
		 */
		getPartial = function(endpoint) {
			return $.ajax({
				timeout: OPTIONS.timeout,
				method: 'GET',
				url: makeAbsolute(endpoint)
			});
		};

		/**
		 * Returns step object from 0 based index.
		 * @param {Number} stepIndex
		 * @returns {{title: String, partial: String, Step: Number, displayAt: Number, slug: String}}
		 * @memberOf DDIGITAL.forms.multistep.service
		 * @instance
		 */
		getStepFromIndex = function(stepIndex) {
			return _data.steps[stepIndex];
		};

		/**
		 * Returns the step object for the given step number
		 *
		 * @param {Number} step Step number indexed from 1
		 * @returns {{title: String, partial: String, Step: Number, displayAt: Number, slug: String}}
		 * @memberOf DDIGITAL.forms.multistep.service
		 * @instance
		 */
		getStep = function(step) {
			var stepObject = {};

			for (var i = 0; i <= _data.steps.length; i += 1) {
				if (_data.steps[i].step === step) {
					stepObject = _data.steps[i];
					break;
				}
			}

			return stepObject;
		};

		/**
		 * Returns 0 based index for given step
		 * @param {Number} step Form step indexed at 1
		 * @returns {Number}
		 * @memberOf DDIGITAL.forms.multistep.service
		 * @instance
		 */
		getIndexFromStep = function(step) {
			var idx = -1;

			for (var i = 0; i < _data.steps.length; i += 1) {
				if (_data.steps[i].step === step) {
					idx = i;
					break;
				}
			}

			return idx;
		};

		/**
		 * Ensures that the path starts with a /
		 * @param {String} path URL path
		 * @returns {String}
		 * @memberOf DDIGITAL.forms.multistep.service
		 * @instance
		 */
		makeAbsolute = function(path) {
			if (path.slice(0, 1) !== '/') {
				path = '/' + path;
			}
			return path;
		};

		/**
		 * Returns a URL path for the given step
		 * @param {Number} step Number of step indexed at 1
		 * @returns {String}
		 * @memberOf DDIGITAL.forms.multistep.service
		 * @instance
		 */
		getURLPathFromStep = function(step) {
			var stepObject = getStep(step),
				path = _data.basePath,
				newPath = path,
				proxy = false;

			if (step === 1) {
				return makeAbsolute(path);
			}

			if (_data.hasOwnProperty('proxy') && _data.proxy === true) {
				proxy = true;
				newPath = newPath.replace('.html', '');
			}

			if (path.slice(-1) !== '/') {
				newPath += '/';
			}

			newPath += stepObject.slug;

			if (proxy === true) {
				newPath += '.html';
			}

			return makeAbsolute(newPath);
		};

		/**
		 * Returns all steps in this form
		 * @returns {Array<Object>}
		 * @memberOf DDIGITAL.forms.multistep.service
		 * @instance
		 */
		getSteps = function() {
			return _data.steps;
		};

		/**
		 * @returns {String}
		 * @memberOf DDIGITAL.forms.multistep.service
		 * @instance
		 */
		getFormId = function() {
			return _data.id;
		};

		/**
		 * @returns {String}
		 * @memberOf DDIGITAL.forms.multistep.service
		 * @instance
		 */
		getFormTitle = function() {
			return _data.title;
		};

		/**
		 * @param {Object} data JSON representation of form and steps contained within it
		 * @param {Object} options Hash with options to override default options
		 * @returns {init}
		 * @memberOf DDIGITAL.forms.multistep.service
		 */
		init = function(data, options) {
			OPTIONS = $.extend(true, options, OPTIONS);

			_data = data;

			return this;
		};

		return {
			init: init,
			getPartial: getPartial,
			getStepFromIndex: getStepFromIndex,
			getStep: getStep,
			getIndexFromStep: getIndexFromStep,
			getURLPathFromStep: getURLPathFromStep,
			getSteps: getSteps,
			getFormId: getFormId,
			getFormTitle: getFormTitle,
			makeAbsolute: makeAbsolute
		};

	}());

}(DDIGITAL, jQuery));
