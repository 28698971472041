// ==========================================================================
// MULTI STEP FORM
// ==========================================================================

(function(NAMESPACE, $) {

	'use strict';

	/**
	 * Forms namespace
	 *
	 * @namespace DDIGITAL.forms
	 * @memberof DDIGITAL
	 */
	NAMESPACE.forms = NAMESPACE.forms || {};

	/**
	 * Multistep namespace
	 *
	 * @namespace DDIGITAL.forms.multistep
	 * @memberof DDIGITAL
	 */
	NAMESPACE.forms.multistep = NAMESPACE.forms.multistep || {};

	/**
	 * Mixins namespace
	 *
	 * @namespace DDIGITAL.forms.multistep.mixins
	 * @memberof DDIGITAL.forms.multistep
	 */
	NAMESPACE.forms.multistep.mixins = NAMESPACE.forms.multistep.mixins || {};

	/**
	 * Multi step forms
	 *
	 * @namespace DDIGITAL.forms.multiStep
	 * @memberOf DDIGITAL.forms
	 */
	NAMESPACE.forms.multiStep = (function() {
		var init,
			liveForm,
			_initProgressBar;

		_initProgressBar = function($bar, $container, service) {
			var progressBar;

			progressBar = new NAMESPACE.forms.multistep.ProgressBar($bar, {
				onStepClick: function(evt, step) {
					evt.preventDefault();
					liveForm.switchStep(step);
				}
			});
			progressBar.init(service);

			$container.on('step-change.liveform', function(evt, currentStep) {
				progressBar.setState(currentStep);
			});
		};

		init = function($scope) {
			$scope = $scope || $('body');

			$scope.find('.js-multistep').each(function() {
				var $container = $(this),
					formData = $container.data('form-multistep'),
					options = {
						form: formData,
						currentStep: $container.data('form-multistep-current-step')
					},
					service,
					router;

				service = NAMESPACE.forms.multistep.service.init(formData, {});

				router = NAMESPACE.forms.multistep.router.init({
					onStateChange: function(evt) {
						var step = evt.state;
						liveForm.switchStep(step);
					}
				});

				$scope.find('.js-form-progress').each(function() {
					_initProgressBar($(this), $container, service);
				});

				// Should we be loading the current step asynchronously?
				if ($container.data('form-multistep-partial') !== undefined) {
					options.partial = $container.data('form-multistep-partial');
				}

				switch (formData.type) {
					case 'wizard':
						NAMESPACE.forms.multistep.mixins.Wizard.call(NAMESPACE.forms.multistep.LiveForm.prototype);
						break;
					case 'accordion':
						NAMESPACE.forms.multistep.mixins.Accordion.call(NAMESPACE.forms.multistep.LiveForm.prototype);
						break;
					case 'onepage':
						NAMESPACE.forms.multistep.mixins.OnePage.call(NAMESPACE.forms.multistep.LiveForm.prototype);
						break;
					default:
						console.warn('Unsupported form type: \'' + formData.type + '\'');
				}

				// Grab some settings from data attributes and such ..
				liveForm = new NAMESPACE.forms.multistep.LiveForm($container, options);
				liveForm.init(service, router);

			});
		};

		return {
			init: init
		};

	}());

}(DDIGITAL, jQuery));
