/* ==========================================================================
 * ReadSpeaker Handler - Event binds
 *
 * NOTE: ReadSpeaker JS is included in _scripts.erb, and then configured on a
 * per-page basis (as is necessary); refer to _readspeaker.erb
 * ========================================================================== */

(function(NAMESPACE, $) {

	'use strict';

	NAMESPACE.readSpeakerHandler = (function() {

		var CLASSES,
			SELECTORS,

			init;

		CLASSES = {
			HIDDEN: 'hidden',
			INIT_COMPLETE: 'rs_loaded'
		};

		SELECTORS = {
			READSPEAKER_BTN: '.rsbtn'
		};

		init = function() {
			var _$readSpeakerBtn = $(SELECTORS.READSPEAKER_BTN);

			// Do not run if ReadSpeaker doesn't exist
			if (typeof ReadSpeaker === 'undefined') {
				_$readSpeakerBtn.addClass(CLASSES.HIDDEN);
				return;
			}

			// Each time the 'Listen' button is clicked, ensure loading indicator is shown
			ReadSpeaker.q(function() {
				ReadSpeaker.Common.addEvent('onUIShowPlayer',
					function() {
						_$readSpeakerBtn.removeClass(CLASSES.INIT_COMPLETE);
					}
				);
			});

			// This is because there is a short delay after clicking 'Listen', and receiving the parsed response
			// from the ReadSpeaker cloud, and in the meantime we display a loading indicator until the below class is added
			ReadSpeaker.q(function() {
				ReadSpeaker.Common.addEvent('onAfterSyncInit',
					function() {
						_$readSpeakerBtn.addClass(CLASSES.INIT_COMPLETE);
					}
				);
			});

			$(document).on('mouseup', 'body', function() {
				var $popUpPlayButton = $('.rspopup');

				if ($popUpPlayButton.length) {
					$popUpPlayButton.addClass('reposition');
					var pos = $popUpPlayButton.position();
					$popUpPlayButton.css({
						top: pos.top - 50
					});
				};
			});
		};

		return {
			init: init
		};
	}());

}(DDIGITAL, jQuery));
