/* ==========================================================================
 * RESPONSIVE LAYOUT
 * ========================================================================== */

(function(NAMESPACE, $) {

	'use strict';

	NAMESPACE.responsiveLayout = (function() {
		var SELECTORS,
			init;

		SELECTORS = {
			TARGET: '.cm-cta-module',
			ORIGIN: '.l-complementary',
			DESTINATION: '.content-header'
		};

		init = function() {
			enquire.register(DD.bp.get('0, m'), {
				match: function() {
					$(SELECTORS.DESTINATION).after($(SELECTORS.TARGET).eq(0));
				},
				unmatch: function() {
					$(SELECTORS.ORIGIN).prepend($(SELECTORS.TARGET).eq(0));
				}
			});
		};

		return {
			init: init
		};
	}());

}(DDIGITAL, jQuery));
