// ==========================================================================
// Feedback and complaints form
// ==========================================================================

(function(NAMESPACE, $) {
	'use strict';

	NAMESPACE.fncForm = (function() {
		var init, SELECTORS, changeProductAreaIntialValue;

		SELECTORS = {
			FNC: '[name="Key_ReasonBy"]',
			FNCFORM: '.fnc-form',
			FNCAREA: '[for="product-area"]',
			FNCDETAILS: '[for="fnc-details"]',
			FNCSUBMIT: '.fnc-form-submit',
			COMPLAINTAREALABEL: '.fnc-complaint-area-label',
			COMPLAINTDETAILSLABEL: '.fnc-complaint-details-label',
			COMPLAINTSUBMITLABEL: '.fnc-complaint-submit-label',

			SHOWHIDEFORM: '.show-contact-form',
			SHOWFORMLABEL: '.show-contact-form',
			HIDEFORMLABEL: '.hide-contact-form',
			ENQUIRYFORM: '.enquiry-form',
			ENQUIRYSUBMIT: '.enquiry-form-submit',

			FEEDBACKORCOMPLAINT: 'input[name="Key_ReasonBy"]:checked',
			PRODUCTAREA: '#product-area',
			CONTACTBY: 'input[name="Key_ContactBy"]:checked',
			REFERENCENUM: '#reference-num'
		};

		changeProductAreaIntialValue = function() {
			$('#product-area option:first').val('');
			$('#product-area').prop('selectedIndex', 0);
		};

		init = function() {
			let fnc = $(SELECTORS.FNC),
				fncForm = $(SELECTORS.FNCFORM),
				fncArea = $(SELECTORS.FNCAREA),
				fncDetails = $(SELECTORS.FNCDETAILS),
				fncSubmit = $(SELECTORS.FNCSUBMIT),
				feedbackAreaLabel = $(SELECTORS.FNCAREA).html(),
				feedbackDetailsLabel = $(SELECTORS.FNCDETAILS).html(),
				feedbackSubmitLabel = $(SELECTORS.FNCSUBMIT).text(),
				complaintAreaLabel = $(SELECTORS.COMPLAINTAREALABEL).html(),
				complaintDetailsLabel = $(SELECTORS.COMPLAINTDETAILSLABEL).html(),
				complaintSubmitLabel = $(SELECTORS.COMPLAINTSUBMITLABEL).text(),
				form = $(SELECTORS.SHOWHIDEFORM),
				showFormLabel = $(SELECTORS.SHOWFORMLABEL),
				hideFormLabel = $(SELECTORS.HIDEFORMLABEL),
				enquiryForm = $(SELECTORS.ENQUIRYFORM),
				enquirySubmit = $(SELECTORS.ENQUIRYSUBMIT);

			hideFormLabel.remove();
			showFormLabel.attr('aria-expanded', 'false');
			form.on('click', function(e) {
				e.preventDefault();
				if (!$(enquiryForm).is(':visible')) {
					enquiryForm.show();
					form.attr('aria-expanded', 'true');
					setTimeout(() => showFormLabel.text('Hide the form'), 10);
				} else {
					enquiryForm.hide();
					form.attr('aria-expanded', 'false');
					setTimeout(() => showFormLabel.text('Show me the form'), 10);
				}
			});

			fnc.on('change', function(e) {
				if (e.target.value.toLowerCase() === 'complaint') {
					fncArea.html(complaintAreaLabel);
					fncDetails.html(complaintDetailsLabel);
					fncSubmit.text(complaintSubmitLabel);
				} else {
					fncArea.html(feedbackAreaLabel);
					fncDetails.html(feedbackDetailsLabel);
					fncSubmit.text(feedbackSubmitLabel);
				}
			});

			fncSubmit.on('click', function(e) {
				e.preventDefault();
				if ($(SELECTORS.PRODUCTAREA).index === 0) {
					$(SELECTORS.PRODUCTAREA).val = '';
				}
				if (fncForm.valid()) {
					let feedbackOrComplaint = $(SELECTORS.FEEDBACKORCOMPLAINT).val(),
						productArea = $(SELECTORS.PRODUCTAREA).val(),
						contactBy = $(SELECTORS.CONTACTBY).val(),
						referenceNumber = $(SELECTORS.REFERENCENUM).val();
					if (feedbackOrComplaint.toLowerCase() === 'complaint') {
						if (window.dataLayer) {
							window.dataLayer.push({
								event: 'complaintsTrigger',
								complaintsServiceLine: productArea,
								complaintsReferenceNumber: referenceNumber,
								contactoption: contactBy
							});
						}
					} else {
						if (window.dataLayer) {
							window.dataLayer.push({
								event: 'Submitted Feedbacks',
								schemes: productArea,
								contactoption: contactBy
							});
						}
					}
					fncForm.submit();
				}
			});

			enquirySubmit.on('click', function(e) {
				e.preventDefault();
				if ($(SELECTORS.PRODUCTAREA).index === 0) {
					$(SELECTORS.PRODUCTAREA).val = '';
				}
				if (enquiryForm.valid()) {
					let productArea = $(SELECTORS.PRODUCTAREA).val(),
						contactBy = $(SELECTORS.CONTACTBY).val();
					if (window.dataLayer) {
						window.dataLayer.push({
							event: 'gen_enquiry_Trigger',
							gen_enquiry_ServiceLine: productArea,
							contactoption: contactBy
						});
					}
					enquiryForm.submit();
				}
			});

			changeProductAreaIntialValue();
		};
		return {
			init: init,
			changeProductAreaIntialValue: changeProductAreaIntialValue
		};
	})();
})(DDIGITAL, jQuery);
