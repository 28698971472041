(function() {
	'use strict';

	$.validator.addMethod('no-letters', function(value) {
		// Let 'pattern' be a pattern that matches a non digit character.
		var pattern = /[A-Za-z]/;
		// Check if the value in the form control does not match 'pattern'.
		return !pattern.test(value);
	}, 'Please do no enter any letters');
}());
