// ==========================================================================
// TABS
// ==========================================================================

(function(NAMESPACE, $) {

	'use strict';

	// tabs functionality, including the ability to have tabs in tabs
	NAMESPACE.tabs = (function() {

		var SELECTORS,
			init;

		SELECTORS = {
			TABS: '.js-tabs',
			TAB_EC_TITLE: '.tab-ec-title',
			TAB_EC_CONTENT: '.tab-ec-content'
		};

		init = function() {
			$(SELECTORS.TABS).each(function(i, el) {
				var $tabsContainer = $(el);

				$tabsContainer.ddTabs();

				// if the tab has an expand/collapse title, listen for when the tab changes
				if ($tabsContainer.find(SELECTORS.TAB_EC_TITLE).length > 0) {

					$tabsContainer.on('tabChanged.ddTabs', function(event, id, $activeTab, $tabsList) {
						event.stopPropagation();

						$tabsList.find(SELECTORS.TAB_EC_CONTENT).trigger('collapse.ddExpandCollapse');
						$activeTab.find(SELECTORS.TAB_EC_CONTENT).trigger('expand.ddExpandCollapse');
					});

				}
			});

		};

		return {
			init: init
		};
	}());

}(DDIGITAL, jQuery));
