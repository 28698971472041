// ==========================================================================
// MULTI STEP FORM LIVEFORM CLASS
// ==========================================================================

(function(NAMESPACE, $) {

	'use strict';

	/**
	 * Forms namespace
	 *
	 * @namespace DDIGITAL.forms
	 * @memberof DDIGITAL
	 */
	NAMESPACE.forms = NAMESPACE.forms || {};

	/**
	 * Multistep namespace
	 *
	 * @namespace DDIGITAL.forms.multistep
	 * @memberof DDIGITAL.forms
	 */
	NAMESPACE.forms.multistep = NAMESPACE.forms.multistep || {};

	/**
	 * @class LiveForm
	 * @memberOf DDIGITAL.forms.multistep
	 *
	 * @param {object} $LiveForm jQuery reference to the form wrapper
	 * @param {object} settings Default settings hash
	 * @constructor
	 */
	var LiveForm,
		CLASSES;

	CLASSES = {
		STEP: '_step',
		SPINNER: 'spinner',
		IS_LOADING: 'is-loading',
		STEPS_LIST: 'step-list',
		STEPS_LIST_ITEM: '_step-list-item',
		STEP_TITLE: '_step-title',
		EDIT_LINK: '_edit-step'
	};

	/**
	 * @class LiveForm
	 * @memberOf DDIGITAL.forms.multistep
	 *
	 * @param {Object} $container Reference to element that contains the steps
	 * @param {Object} settings Hash of configuration parameters
	 * @constructor
	 */
	LiveForm = function($container, settings) {
		this.$container = $container;
		this.defaults = {};

		this.settings = $.extend(true, this.settings, this.defaults, settings);

		this.currentStep = settings.currentStep;
	};

	LiveForm.prototype = {

		TYPES: [
			'wizard',
			'accordion',
			'stepped'
		],

		TEMPLATES: {
			step: [
				'<div class="' + CLASSES.STEP + '">',
				'</div>'
			].join(''),
			list: '<ul class="' + CLASSES.STEPS_LIST + '" />',
			listItem: '<li class="' + CLASSES.STEPS_LIST_ITEM + '" />',
			stepTitle: [
				'<div class="' + CLASSES.STEP_TITLE + '">',
				'<span>{{step.title}}</span>',
				'</div>'
			].join(''),
			editLink: '<a href="#" class="' + CLASSES.EDIT_LINK + '">Edit</a>'
		},

		/**
		 * @type {Object}
		 */
		settings: {},

		/**
		 * @type {Object}
		 */
		$container: {},

		/**
		 * @type {Boolean}
		 */
		isLoading: false,

		/**
		 * @type {Object}
		 */
		currentStep: {},

		/**
		 * @type {Object}
		 */
		$currentStep: {},

		/**
		 * @type {Boolean}
		 */
		firstRender: true,

		/**
		 * Initialize form tracker classs
		 * @memberOf DDIGITAL.forms.multiStep.LiveForm
		 * @private
		 * @param {Object} service
		 * @param {Object} router
		 */
		init: function(service, router) {

			if (this.TYPES.indexOf(this.settings.form.type) === -1) {
				console.warn('Unsupported liveform form type \'' + this.settings.type + '\'');
				return false;
			}

			this.service = service;
			this.router = router;

			// Create reference to step object
			this.currentStep = this.service.getStep(this.settings.currentStep);

			// If type is accordion we need to stub out all the step DOM nodes in advance
			if (this.settings.form.type === 'accordion') {
				this._stubSteps();
				this._initStubs();
			}

			if (this.currentStep.step === 1) {
				// Store the step object in the browser history
				this.router.replaceState(this.currentStep);
			}

			// For wizards, the first step gets embedded into the DOM from the backend
			if (this.currentStep.step === 1 && this.settings.form.type === 'wizard') {
				// Initialize Step class against existing DOM node
				this.$currentStep = this.$container.find('[data-form-multistep-step="1"]');
				this.$currentStep.data('step', this.currentStep);
				this._initStep(this.$currentStep);
				this._triggerStepChange();
				this.firstRender = false;
			} else {
				// We have to fetch the partial first
				if (this.settings.partial) {
					this._buildStep(this.currentStep);
				} else {
					console.warn('You have not declared a partial to fetch on initialisation.');
				}
			}

			this._listenForEvents();
			this._initLoader();
		},

		/**
		 * Initializes Step class against $step node
		 * @private
		 */
		_initStep: function($step) {
			var step = new NAMESPACE.forms.multistep.Step($step, this.$container, {});

			step.init(this.service);

			// Always start the step in a disabled state
			//if (this.firstRender === false) {
				//$step.trigger('disable.step');
			//}

			// Catch 'go back' link click and trigger step switch
			$step.on('goback.step', function(evt, step) {
				this.loading.start();
				this.switchStep(this.service.getStep(step.step - 1));
			}.bind(this));
		},

		/**
		 * Listens for comms from other modules in the form of events that bubble up from the form
		 * element up to the $container
		 * @private
		 */
		_listenForEvents: function() {

			// Hook into point where the form is being submitted
			this.$container.on('submit-start.sync', function() {
				// Mark container loading event though _sync.js already marks the step that is being
				// submitted as loading as well.
				this.loading.start();

				// Mark container as busy while submitting and preparing the response/next step
				this.$container.attr('aria-busy', true);
			}.bind(this));

			// Submission was considered valid.
			this.$container.on('submit-success.sync', function(evt) {
				var $form = $(evt.target);

				// @todo Determine what key/value pairs to pass here
				// Trigger event for analytics/tracking purposes
				$form.trigger('step-complete.track', {
					'form-id': this.service.getFormId(),
					'form-title': this.service.getFormTitle(),
					'step-title': this.currentStep.title,
					'step': this.currentStep.step
				});

			}.bind(this));

			// Submission returned validation errors
			this.$container.on('submit-invalid.sync', function() {
				this.loading.stop();
				this.$container.attr('aria-busy', false);
			}.bind(this));

			// POST request was not successful in receiving an adequate response
			this.$container.on('submit-fail.sync', function(evt, connectionError) {
				console.log('submit fail', evt, connectionError);
			}.bind(this));

			// This event is always raised when the POST has received a response
			this.$container.on('submit-end.sync', function() {}.bind(this));

			// Execute action after submit completed without validation errors
			this.$container.on('next-action-partial.sync', function() {
				// Get the next step from the server and into the form
				this._nextStep();
			}.bind(this));
		},

		/**
		 * Sets up a loader utility to easily mark the loading state of the form while emitting an
		 * appropriate event to the Step class so it can disable/enable its children
		 *
		 * @private
		 */
		_initLoader: function() {
			var startLoading,
				stopLoading;

			startLoading = function() {
				// Add spinner loading class
				this.$container.addClass(CLASSES.IS_LOADING);
				this.$currentStep.trigger('disable.step');
			}.bind(this);

			// Stop loading; hide loading animation and enable the form
			stopLoading = function() {
				// Remove spinner loading class
				this.$container.removeClass(CLASSES.IS_LOADING);
				this.$currentStep.trigger('enable.step');
			}.bind(this);

			this.loading = {
				start: startLoading,
				stop: stopLoading
			};
		},

		/**
		 * Fetches partial form server on given endpoint
		 *
		 * @param {String} endpoint URL endpoint to query for fetching HTML for step
		 * @returns {Promise}
		 * @inner
		 * @private
		 */
		_fetchPartial: function(endpoint) {
			var request = this.service.getPartial(endpoint);

			// This should only happen when the endpoint is unavailable
			// or a server error occurs.
			request.fail(function() {
				NAMESPACE.forms.sync.handleNetworkError(this.$currentStep.find('form'));
			}.bind(this));

			return request;
		},

		/**
		 * Fetches partial from endpoint and when response has been received it triggers a
		 * render and initialisation for that step
		 *
		 * @param {object} step
		 * @private
		 */
		_buildStep: function(step) {
			var $step;

			this._fetchPartial(step.partial)
				.then(function(html) {
					// Now render the returned HTML in a step template
					$step = this._renderStep(step, html);

					// Make step data on this node available to other parts of the JS framework
					$step.data('step', step);

					// Inject the step into the DOM
					if (this.settings.form.type === 'wizard') {
						this._addStep($step, step);
					} else {
						this._insertStepIntoStub($step, step);
					}

					// Initialize Step class against new DOM node
					this._initStep($step);

					// Animate from current state to the requested step
					this._animate(this.$currentStep, $step);

				}.bind(this));
		},

		/**
		 * Renders step using configured template and executes various hooks to allow for DOM
		 * manipulation from outside of this class.
		 *
		 * @param {Object} step Step object
		 * @param {String} html Partial as fetched from server
		 * @returns {*|HTMLElement}
		 * @private
		 */
		_renderStep: function(step, html) {
			var $tpl = $(this.TEMPLATES.step);

			// Wrap partial in template element
			$tpl.append(html);

			this.beforeInsertStep($tpl, step);

			return $tpl;
		},

		/**
		 * Adds step to container before or after the current step based on index. This is only
		 * used for wizard type forms
		 *
		 * @param {Object} $step
		 * @param {Object} step
		 * @private
		 */
		_addStep: function($step, step) {
			var $steps = this.$container.find('.step');

			if ($steps.length === 0) {
				this.$container.append($step);
			} else {
				// Determine whether we should insert the new step before or after the step
				// currently on stage
				if (this.currentStep.step > step.step) {
					// Insert after
					$step.insertBefore($steps);
				} else {
					// Insert before
					$step.insertAfter($steps);
				}
			}
		},

		/**
		 * Switches to the next step in the collection
		 * @private
		 */
		_nextStep: function() {
			var index = this.service.getIndexFromStep(this.currentStep.step),
				nextStep = this.service.getStepFromIndex(index + 1);

			this._buildStep(nextStep);
		},

		/**
		 * Switches to the given step
		 *
		 * @param {Object} step Step object
		 */
		switchStep: function(step) {
			this._buildStep(step);
		},

		/**
		 * Takes care of moving from one step to the next now that both are in the DOM
		 *
		 * @param {Object} $stepOut Current step which wil be animating out
		 * @param {Object} $stepIn Next step to display
		 * @private
		 */
		_animate: function($stepOut, $stepIn) {
			var aniOut = new jQuery.Deferred(),
				aniIn = new jQuery.Deferred();;

			this.scrollToTop();

			// Do we have a visible step currently?
			if ($stepOut.length) {

				// Yes, animate the current step out of view
				this.animateStepOut($stepOut, function() {
					aniOut.resolve();
				});

			} else {
				// Resolve immediately
				aniOut.resolve();
			}

			// Animate in when aniOut has resolved/completed
			$.when(aniOut)
				.done(function() {

					this.animateStepIn($stepIn, function() {
						aniIn.resolve();
					});

				}.bind(this));

			// Once all animation has completed, we update the state of things
			$.when(aniIn)
				.done(function() {
					if ($stepOut.length) {
						$stepOut.trigger('destroy.step');
					}

					this._activateStep($stepIn);

					this.loading.stop();
					this.$container.attr('aria-busy', false);

				}.bind(this));
		},

		/**
		 * Sets some internal variables pointing to current step node and object. A route is
		 * triggered and a 'step-change' event is triggered as well
		 *
		 * @param {Object} $step The step node to mark as the current one
		 * @returns {boolean}
		 * @private
		 */
		_activateStep: function($step) {
			var path;

			this.currentStep = $step.data('step');
			this.$currentStep = $step;

			this._updateEditLinkStates();
			this._triggerStepChange();

			// If we're rendering the very first step into the DOM we do not want to update
			// the URL path and trigger a 'step-change' event
			if (this.firstRender) {
				this.firstRender = false;
				return false;
			}

			path = this.service.getURLPathFromStep(this.currentStep.step);

			this.router.navigate(path, this.currentStep);
		},

		/**
		 * Makes links visible or hidden depending on the current step
		 * @private
		 */
		_updateEditLinkStates: function() {
			var currentStep = this.currentStep;

			this.$container.find('.' + CLASSES.EDIT_LINK)
				.each(function() {
					var $link = $(this),
						step = $link.data('step');

					if (step.displayAt < currentStep.displayAt) {
						$link.removeClass('hidden');
					} else {
						$link.addClass('hidden');
					}
				});
		},

		/**
		 * Raises 'step-change' event against the container DOM node
		 * @private
		 */
		_triggerStepChange: function() {
			this.$container.trigger('step-change.liveform', this.currentStep);
		},

		/**
		 * Inserts rendered step at the right point in the DOM based on the step index
		 *
		 * @param {Object} $step
		 * @param {Object} step
		 * @private
		 */
		_insertStepIntoStub: function($step, step) {
			this.$container
				.find('.' + CLASSES.STEPS_LIST_ITEM)
				.eq(step.displayAt - 1)
				.append($step);
		},

		/**
		 * Creates an accordion container element in the DOM for each step in this form
		 *
		 * @private
		 */
		_stubSteps: function() {
			var steps = this.service.getSteps(),
				lastStep = 0;

			this.$list = $(this.TEMPLATES.list);

			function renderStub(step) {
				var $listItem,
					$editLink,
					$stepTitle,
					tpl;

				tpl = this.TEMPLATES.stepTitle.replace('{{step.title}}', step.title);
				$stepTitle = $(tpl);
				$listItem = $(this.TEMPLATES.listItem);

				if (step.canEdit !== false) {
					$editLink = $(this.TEMPLATES.editLink);
					$editLink.data('step', step);
					$editLink.appendTo($stepTitle);
				}

				$listItem.append($stepTitle);
				$listItem.appendTo(this.$list);
			}

			for (var i = 0; i < steps.length; i += 1) {
				if (steps[i].displayAt !== lastStep) {
					renderStub.call(this, steps[i]);
				}
				lastStep = steps[i].displayAt;
			}

			this.$list.appendTo(this.$container);
		},

		/**
		 * @todo Add feature that detects if changes were made in the form
		 *
		 * @private
		 */
		_initStubs: function() {
			var $editLinks = this.$container.find('.' + CLASSES.EDIT_LINK);

			$editLinks.on('click', function(evt) {
				evt.preventDefault();
				this.switchStep($(evt.currentTarget).data('step'));
			}.bind(this));
		},

		/**
		 * Scrolls page to top of the form
		 */
		scrollToTop: function() {
			var currentTop = $(document).scrollTop(),
				formToTop = this.$container.offset().top;

			// Only scroll to top if we're beyond the start of the form
			if (currentTop > formToTop) {
				NAMESPACE.util.scroll.page(0, null, 250, function() {});
			}
		},

		// Abstract methods that could/should be implemented by mixins

		/**
		 * @abstract
		 * @param {Object} $step The step node to be inserted
		 * @param {Object} step The step model
		 */
		beforeInsertStep: function() {}

	};

	NAMESPACE.forms.multistep.LiveForm = LiveForm;

}(DDIGITAL, jQuery));
