// ==========================================================================
// AUTOCOMPLETE
// ==========================================================================

(function(NAMESPACE, $) {

	'use strict';

	NAMESPACE.autocomplete = (function() {
		var init,
			SELECTORS;

		SELECTORS = {
			SEARCH_CLOSE: '.search-close',
			SEARCH_BUTTON: '.search-toggle',
			SEARCH_RESULTS: '.autocomplete-results',
			SEARCH_SUBMIT: '.js-autocomplete-results-submit'
		};

		init = function() {
			var options = {};

			$('.js-autocomplete').each(function(i, el) {
				var $el = $(el);

				// Set a validation delay to allow for the values to have been written to the input
				NAMESPACE.forms.validate.setValidationDelay($el.find('input'), 200);

				$el.ddAutocomplete(options);
			});

			$(SELECTORS.SEARCH_CLOSE).on('click', function() {
				$(SELECTORS.SEARCH_BUTTON).removeClass('is-active');
				$(SELECTORS.SEARCH_RESULTS).hide();
				$(SELECTORS.SEARCH_SUBMIT).hide();

			});

			$(SELECTORS.SEARCH_BUTTON).on('click', function() {
				$(SELECTORS.SEARCH_RESULTS).hide();
				$(SELECTORS.SEARCH_SUBMIT).hide();
			});
		};

		return {
			init: init
		};

	}());

}(DDIGITAL, jQuery));
