// Event constructors such as KeyboardEvent, MouseEvent and FocusEvent are not exposed as functions on Internet Explorer 11. The 'typeof' operator returns 'object' instead of 'function'. The opposite happens on the rest of the major browsers where event constructors are functions. This means that event constructors cannot be used with the 'new' keyboard on Internet Explorer. The following is a polyfill that converts event constructors into functions on Internet Explorer. This effectively aligns Internet Explorer with the rest of the major browsers.

(function(NAMESPACE) {

	'use strict';

	NAMESPACE.eventConstructorsPolyfill = (function() {

		var init;

		init = function() {
			if (typeof Event !== 'function') {
				/** Create a new Event.
			        * @param {String} type - The name of the event.
			        * @return {CustomEvent}
			        */
				Event = function Event(type, eventInitDict) {
					// Get the configuration of the event.
					eventInitDict = eventInitDict || {};

					var bubbles = eventInitDict.bubbles,
						cancellable = eventInitDict.cancellable,
						event = document.createEvent('CustomEvent');

					// Apply the configuration of the event.
					event.initCustomEvent(type, bubbles, cancellable, undefined);

					return event;
				};
			};
		};

		return {
			init: init
		};
	}());

}(DDIGITAL, jQuery));
