// ==========================================================================
// LIVEFORM - ACCORDION MIXIN
// ==========================================================================

(function(NAMESPACE) {

	'use strict';

	/**
	 * Forms namespace
	 *
	 * @namespace DDIGITAL.forms
	 * @memberof DDIGITAL
	 */
	NAMESPACE.forms = NAMESPACE.forms || {};

	/**
	 * Multistep namespace
	 *
	 * @namespace DDIGITAL.forms.multistep
	 * @memberof DDIGITAL.forms
	 */
	NAMESPACE.forms.multistep = NAMESPACE.forms.multistep || {};

	/**
	 * Mixins namespace
	 *
	 * @namespace DDIGITAL.forms.multistep.mixins
	 * @memberof DDIGITAL.forms.multistep
	 */
	NAMESPACE.forms.multistep.mixins = NAMESPACE.forms.multistep.mixins || {};

	/**
	 * Mixin for liveForm type accordion
	 *
	 * @namespace DDIGITAL.forms.multistep.mixins.Accordion
	 * @memberOf DDIGITAL.forms.multistep.mixins
	 */
	NAMESPACE.forms.multistep.mixins.Accordion = function() {

		/**
		 * Manipulates step DOM node prior to it being inserted into the DOM
		 *
		 * @param {Object} $step Reference to step DOM node
		 */
		this.beforeInsertStep = function($step) {
			$step.addClass('hidden step-enter');
		};

		//this.afterInsertStep = function($step, step) {};

		/**
		 * Animates a step into view before calling the callback which will trigger the activation
		 * of the step
		 *
		 * @param {Object} $step Reference to step DOM node
		 * @param {Function} callback Should be triggered when step should be activated
		 */
		this.animateStepIn = function($step, callback) {
			$step
				.removeClass('hidden step-leave step-leave-active')
				.addClass('step-enter-active');

			setTimeout(function() {
				callback();
				$step.removeClass('step-enter step-enter-active');
			}, 500);
		};

		/**
		 * Animates a step out of view before calling the callback which will trigger the
		 * animateStepIn sequence
		 *
		 * @param {Object} $step Reference to step DOM node
		 * @param {Function} callback When triggered, the animateStepIn sequence will start
		 */
		this.animateStepOut = function($step, callback) {
			$step
				.removeClass('step-enter step-enter-active')
				.addClass('step-leave step-leave-active');

			setTimeout(function() {
				$step
					.addClass('hidden')
					.removeClass('step-leave step-leave-active');
				callback();
			}, 300);
		};
	};

}(DDIGITAL));
