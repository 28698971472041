// ==========================================================================
// CONTACT US FORM
//
// The following piece of code controls what happens after the user selects a
// service line. In that case the products related to a service line get
// displayed in the form. In addition, a list of recipient suggestions is
// displayed as to who to send a 'contact us' message.
// ==========================================================================

(function(NAMESPACE, $) {
	'use strict';

	NAMESPACE.contactUsForm = (function() {
		// Let 'products' be an empty list of service line products.
		var products = [];
		// Let 'recipients' be an empty list of recipients.
		var recipients = [];
		// Let 'serviceLinesBox' be the area where service lines are displayed.
		var serviceLinesBox = document.querySelector('#js-product-area');
		// Let 'heading' be the heading in 'serviceLinesBox'.
		var heading = document.querySelector('#js-contact-details-label');
		// Let 'productsBox' be the area where service line products are displayed.
		var productsBox = document.querySelector('#js-contact-details-container');
		// Let 'recipientsBox' be the area where the recipient of a message is picked.
		var recipientsBox = document.querySelector('#js-recipients');
		// Let 'formSection' be the form controls after the tile containers.
		var formSection = document.querySelector('.scroll-point');
		/** Generates a HTML representation of a service line product.
		 * @param {Object} product - A service line product
		 * @returns {HTMLDivElement} - The product.
		 * */
		function createProduct(product) {
			var div = document.createElement('div');
			div.classList.add('contact-details-tile');

			var heading = document.createElement('h3');
			heading.classList.add('contact-details-heading');
			heading.textContent = product.title;

			div.appendChild(heading);
			div.insertAdjacentHTML('beforeend', product.content);

			return div;
		}

		/** Generates a HTML representation of a service line product recipient.
		 * @param {Object} product - A service line product
		 * @returns {HTMLLIElement} - The recipient.
		 * */
		var createRecipient = (function() {
			var counter = 0;

			return function createRecipient(product) {
				var radioButton = document.createElement('input');
				radioButton.classList.add('radio-button');
				radioButton.classList.add('vh');
				radioButton.dataset.ruleRequired = true;
				radioButton.id = 'recipient' + (counter += 1);
				radioButton.name = product.emailKey;
				radioButton.type = 'radio';
				radioButton.value = product.email;

				radioButton.setAttribute('aria-required', true);

				var label = document.createElement('label');
				label.setAttribute('for', 'recipient' + counter);
				label.textContent = product.title;

				var listItem = document.createElement('li');
				listItem.classList.add('option');

				listItem.appendChild(radioButton);
				listItem.appendChild(label);

				return listItem;
			};
		})();

		/**
		 * Hide contact details for disabled product
		 */
		function hideDisabledProduct() {
			var tile = $('.tile-container').find('input[name="Key_ProductArea"]');
			var contactDetails = $('#contact-details, .scroll-point');
			tile.change(function() {
				var hide = !this.classList.contains('product-disabled');
				contactDetails
					.find(
						'.ctrl-holder, h2:not(#js-contact-details-label), hr, .js-validate-summary, .cta'
					)
					.toggle(hide);
			});
		}

		/** Updates the area where service line products are displayed
		 * according to the service line selected.
		 * @param {Array} list - A list of products related to one of the
		 * service lines. */
		function updateProducts(list) {
			// Reverse 'list' due to the DOM order in which products are inserted.
			list = list.slice().reverse();

			// Remove any service line products currently on display (and in DOM).
			products.forEach(function(product) {
				product.parentNode.removeChild(product);
			});

			// Clear the list of products.
			products.length = 0;

			// For each product in 'list'.
			list.forEach(function(product) {
				// Let 'product' be a HTML representation of the product.
				product = createProduct(product);
				// Append 'product' to 'productArea'.
				productsBox.insertAdjacentElement('afterbegin', product);
				// Add 'product' to 'products'.
				products.push(product);
			});
		}

		/** Updates the area where recipients of the 'Contact Us' message are
		 * displayed.
		 * @param {Array} list - A list of products related to the service line
		 * currently selected. */
		function updateRecipients(list) {
			// Let 'box' be the area where each individual recipient is located.
			var box = recipientsBox.querySelector('ul');

			// Display 'recipientsBox' in case it is hidden.
			recipientsBox.classList.remove('hidden');

			// Filter out any products that do not have contactable recipients.
			list = list.filter(function(product) {
				return product.hasOwnProperty('email') && product.email.length;
			});

			// Remove any recipients currently on display (and in DOM).
			recipients.forEach(function(recipient) {
				recipient.parentNode.removeChild(recipient);
			});

			// Clears the list of recipients.
			recipients.length = 0;

			// For each service line product in 'list'.
			list.forEach(function(product) {
				// Let 'recipient' be a HTML representation of a recipient.
				var recipient = createRecipient(product);
				// Append 'tile' to 'productArea'.
				box.appendChild(recipient);
				// Add 'tile' to 'contactDetails'.
				recipients.push(recipient);
			});

			// If there is only one recipient as an option.
			if (list.length <= 1) {
				// Let 'button' be the radio button linked to this recipient.
				var button = recipients[0].querySelector('input');
				// Check 'button' as there is only 1 recipient to choose from.
				button.checked = true;
				// Do not display 'recipientsBox' as it doesn't make sense.
				recipientsBox.classList.add('hidden');
			}
		}

		/**
		 * Scrolls to the next part of the page.
		 *
		 * @param {jQuery} $formSection Scroll endpoint
		 */
		function scrollPage() {
			NAMESPACE.util.scroll.page(
				$(formSection).offset().top - $('.global-header').height() - 100,
				null,
				250
			);
		}

		/** Updates the form according to the service line selected.
		 * @param {Event} event
		 * @listens Event#change */
		function onChange(event) {
			// Let 'serviceLine' be the radio button that has just been selected.
			var serviceLine = event.target,
				products;

			if (serviceLine) {
				// Let 'products' be the products associated with 'serviceLine'.
				products = serviceLine.dataset.subproductArea;
				// Display the rest of the form that may be currently hidden now.
				formSection.classList.remove('hidden');
				// Update 'heading' according to the new service line selected.
				if (heading) {
					heading.textContent = serviceLine.value + ' contact details';
				}
				if (products) {
					// Transform 'products' into an array.
					products = JSON.parse(products);
					// Updates 'productsBox' according to the new service line selected.
					updateProducts(products);
					// Updates 'recipientsBox' according to the new service line selected.
					updateRecipients(products);
				}
			}
		}

		/** This function is to trigger additional validation for the Feedback
		 * form if the user selects the 'Yes' radio button - as required by UX.
		 */
		function changeValidationToTrue() {
			// Gets all fields that need to be validated.
			var toggleValidationFields = document.querySelectorAll(
				'.toggle-validation'
			);

			// Applies validation rules for first name and last name fields.
			toggleValidationFields.forEach(function(field) {
				field.required = true;

				if (field.classList.contains('toggle-first-name-rule')) {
					field.dataset.msgRequired = 'Please provide your first name';
				} else if (field.classList.contains('toggle-last-name-rule')) {
					field.dataset.msgRequired = 'Please provide your last name';
				}
			});
		}

		/** Initiates the functionality for the cobtact us form flows including
		 * the contact (general enquires), complaints and feedback forms.
		 */
		function init() {
			//contact-us Navbar
			/**
			 * data attributes, data-left-arrow & data-left-arrow are populated with the url
			 * path in file _cm56-ic-carousel.erb. On deployment sitecore developer will add
			 * correct domain via icare.FEAssetPathSitecore.WebsiteS3Path
			 */
			function makeVisibleSlidesTabbable() {
				setTimeout(function() {
					$('.ic-carousel-content.slick-active').removeAttr('tabindex');
				}, 500);
			}

			var leftArrow = $('#contact-us-carousel').attr('data-left-arrow'),
				rightArrow = $('#contact-us-carousel').attr('data-right-arrow');
			$('#contact-us-carousel').slick({
				arrows: true,
				prevArrow:
					'<button class="slick-prev" aria-label="Carousel Previous"><img src="' +
					leftArrow +
					'" alt="Previous Arrow"></button>',
				nextArrow:
					'<button class="slick-next" aria-label="Carousel Next"><img src="' +
					rightArrow +
					'" alt="Next Arrow"></button>',
				infinite: true,
				speed: 300,
				slidesToShow: 5,
				slidesToScroll: 1,
				responsive: [
					{
						breakpoint: 1024,
						settings: {
							slidesToShow: 3,
							slidesToScroll: 1,
							infinite: true
						}
					},
					{
						breakpoint: 768,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1
						}
					}
				]
			});

			$('div.hero-carousel').attr('aria-label', 'iCare Services Carousel');
			$('div.hero-carousel').attr('role', 'region');
			$('div.hero-carousel').attr('aria-roledescription', 'Carousel');

			makeVisibleSlidesTabbable();

			$('#contact-us-carousel .slick-arrow').on('click', function() {
				makeVisibleSlidesTabbable();
			});

			$(window).on('resize', function() {
				makeVisibleSlidesTabbable();
			});

			$('.ic-carousel-content,.ic-carousel-sticky-content').on(
				'click',
				function() {
					$('.ic-carousel-content,.ic-carousel-sticky-content').removeClass(
						'active'
					);
					$(this).addClass('active');
				}
			);

			// Scroll to content if we selected one of tabs
			if ($('.tile [aria-current=page]').length) {
				window.scrollTo(0, 1);
				setTimeout(function() {
					var stickyHeaderHeight = $('.global-header.is-sticky').height() || 0;
					var top = $('#main form').offset().top - stickyHeaderHeight;
					NAMESPACE.util.scroll.page(top, null, 250, function() {
						history.scrollRestoration = 'manual';
					});
				}, 0);
			}

			// If the user is on the 'Complaints', Contact Us' or 'Feedback' form.
			if (serviceLinesBox) {
				// Listen for change events originating from 'serviceLinesBox'.
				serviceLinesBox.addEventListener('change', onChange, true);

				// Listen for change events originating from 'serviceLinesBox'.
				if (document.querySelector('#contact-us-form')) {
					// Let 'serviceLine' be the service line that may be already selected on page load (because the user has been redirected to this form).
					var serviceLine = serviceLinesBox.querySelector(':checked');
					// Populate the rest of the form if 'serviceLine' exists.
					onChange({ target: serviceLine });
					serviceLinesBox.addEventListener('change', onChange, true);
				}

				serviceLinesBox.addEventListener('change', scrollPage, true);

				// Let 'yesRadioButton' be the trigger for additional form validation.
				var yesRadioButton = document.querySelector('.change-validation');
				// Checks for a trigger and adds a listener to change the form validation rules.
				if (yesRadioButton) {
					yesRadioButton.addEventListener(
						'change',
						changeValidationToTrue,
						true
					);
				}
			}

			hideDisabledProduct();
		}

		return {
			createProduct: createProduct,
			createRecipient: createRecipient,
			init: init,
			updateProducts: updateProducts,
			updateRecipients: updateRecipients
		};
	})();
})(DDIGITAL, jQuery);
