window.DDIGITAL = window.DDIGITAL || {};

// ==========================================================================
// Quick links
// ==========================================================================

(function(NAMESPACE, $) {
	'use strict';

	NAMESPACE.quickLinks = (function() {
		var constants,
			ATTRS,
			CLASSES,
			SELECTORS,
			init,
			_isExpanded,
			_expand,
			_collapse,
			_toggle;

		constants = {
			DESKTOP_WIDTH: 1024
		};

		CLASSES = {
			IS_GREY: 'quicklinks-left-container-grey',
			IS_OVERFLOW_HIDDEN: 'quicklinks-overflow-hidden'
		};

		SELECTORS = {
			CONTAINER: '.quicklinks-left-container',
			CONTAINER_COVER: '.quicklinks-left-container-cover',
			COLLAPSED: '.quicklinks-collapse-header',
			EXPANDED: '.quick-link-expand-wrapper',
			EXPANDED_HEADER: '.expand-header',
			RIGHT_CONTAINER: 'div#quicklinks-right-container',
			ANCHOR_SELECTOR: '.quicklinks-left-container a',
			SCROLL_BUTTON: '.quicklinks-scroll-to-top',
			HEADER: '.global-header',
			BANNER: '.cm-campaign-hero-banner'
		};

		_expand = function() {
			_isExpanded = true;
			$(SELECTORS.COLLAPSED).hide();
			$(SELECTORS.EXPANDED).show();

			$(SELECTORS.RIGHT_CONTAINER).css({
				top: -$(SELECTORS.EXPANDED).height()
			});

			if (screen.width >= constants.DESKTOP_WIDTH) {
				$(SELECTORS.RIGHT_CONTAINER).css({
					'margin-left': $(SELECTORS.EXPANDED).width() + 75
				});
			} else {
				$(SELECTORS.RIGHT_CONTAINER).css({
					'margin-left': 80
				});

				$(SELECTORS.CONTAINER_COVER)
					.addClass(CLASSES.IS_GREY)
					.addClass(CLASSES.IS_OVERFLOW_HIDDEN);

				$(SELECTORS.BANNER).hide();
				$(SELECTORS.HEADER).hide();

				$(SELECTORS.SCROLL_BUTTON).css({
					visibility: 'hidden'
				});

				$('html').addClass(CLASSES.IS_OVERFLOW_HIDDEN);
			}
		};

		_collapse = function() {
			_isExpanded = false;

			$(SELECTORS.EXPANDED).hide();
			$(SELECTORS.COLLAPSED).show();

			$(SELECTORS.RIGHT_CONTAINER).css({
				top: -$(SELECTORS.COLLAPSED).height()
			});

			if (screen.width >= constants.DESKTOP_WIDTH) {
				$(SELECTORS.RIGHT_CONTAINER).css({
					'margin-left': $(SELECTORS.COLLAPSED).width() + 75
				});
			} else {
				$(SELECTORS.RIGHT_CONTAINER).css({
					'margin-left': 80
				});

				$(SELECTORS.CONTAINER_COVER)
					.removeClass(CLASSES.IS_GREY)
					.removeClass(CLASSES.IS_OVERFLOW_HIDDEN);

				$(SELECTORS.BANNER).show();
				$(SELECTORS.HEADER).show();

				$(SELECTORS.SCROLL_BUTTON).css({
					visibility: 'visible'
				});

				$('html').removeClass(CLASSES.IS_OVERFLOW_HIDDEN);
			}
		};

		_toggle = function() {
			if (_isExpanded) {
				_collapse();
			} else {
				_expand();
			}
		};

		init = function() {
			// quickLinks is expanded by default for desktop
			if (screen.width >= constants.DESKTOP_WIDTH) {
				_expand();
			} else {
				_collapse();
			}

			$(SELECTORS.COLLAPSED).click(_toggle);
			$(SELECTORS.EXPANDED_HEADER).click(_toggle);

			$(SELECTORS.ANCHOR_SELECTOR).on('click', function() {
				if (screen.width < constants.DESKTOP_WIDTH) {
					_collapse();
				}
			});

			if ($(SELECTORS.SCROLL_BUTTON).hasClass('show')) {
				$(SELECTORS.SCROLL_BUTTON).removeClass('show');
			}

			$(window).on('scroll', function() {
				if ($(this).scrollTop() > $(window).height() * 0.25) {
					$(SELECTORS.SCROLL_BUTTON).addClass('show');
				} else {
					$(SELECTORS.SCROLL_BUTTON).removeClass('show');
				}
			});

			$(SELECTORS.SCROLL_BUTTON).on('click', function(e) {
				e.preventDefault();
				$('html, body').animate(
					{
						scrollTop: 0
					},
					'300'
				);
			});
		};

		return {
			init: init
		};
	})();
})(DDIGITAL, jQuery);
