window.DDIGITAL = window.DDIGITAL || {};

// ==========================================================================
// Quick links
// ==========================================================================

(function(NAMESPACE, $) {
	'use strict';

	NAMESPACE.injuryPrevention = (function() {
		var ATTRS,
			CLASSES,
			SELECTORS,
			init,
			_downloadPdf,
			constants = {
				MESSAGE_INTERVAL:
					$('#hdnInjuryPreventionDownlaodMessageInterval').val() * 1000,
				TIMEOUT_ID: null
			};

		ATTRS = {};

		CLASSES = {
			IS_HIDDEN: 'injury-prevention-download-message-hide',
			SHOW_POPUP: 'ip-show-popup'
		};

		SELECTORS = {
			DOWNLOAD_BUTTON: '.injury-prevention-download-btn',
			MESSAGE: '.injury-prevention-download-message'
		};

		_downloadPdf = function() {
			$(SELECTORS.MESSAGE).removeClass(CLASSES.IS_HIDDEN);

			$(SELECTORS.MESSAGE).css('display', 'flex');

			constants.TIMEOUT_ID = setTimeout(() => {
				$(SELECTORS.MESSAGE).addClass(CLASSES.IS_HIDDEN);
			}, constants.MESSAGE_INTERVAL);
		};

		init = function() {
			const ipListTileItem = $('.ip-item');
			const injuryPreventionModal = $('#ip-popup-modal');
			const backButton = $('.injury-prevention-accordion-back-btn-wrapper');
			const ipAccordionWrapper = $('.injury-prevention-accordion-wrapper');
			const ipAccordionItems = ipAccordionWrapper.find('.ip-accordion-item');

			ipAccordionItems.css('display', 'none');
			let clickedListTileItem = '';
			let filteredAccordionListItem = null;

			function openInjuryPreventionModal() {
				injuryPreventionModal.css('display', 'block');

				clickedListTileItem = $(this).attr('ip-tag');

				filteredAccordionListItem = $(
					'.ip-accordion-item[ip-tag="' + clickedListTileItem + '"]'
				);
				filteredAccordionListItem.css('display', 'block');
				$('body').css({
					overflow: 'hidden',
					height: '100%'
				});
			}

			function closeInjuryPreventionModal() {
				$('body').css({
					overflow: 'unset'
				});

				$('html, body').scrollTop($('.ip-list-heading').offset().top - 112);

				filteredAccordionListItem.css('display', 'none');
				injuryPreventionModal.css('display', 'none');
			}

			ipListTileItem.on('click', openInjuryPreventionModal);

			backButton.on('click', closeInjuryPreventionModal);

			$(SELECTORS.DOWNLOAD_BUTTON).click(_downloadPdf);
		};

		return {
			init: init
		};
	})();
})(DDIGITAL, jQuery);


