// ==========================================================================
// FEEDBACK FORM
//
//Following piece of code controls toggle of feedback form on selection of yes or no.
// ==========================================================================


(function(NAMESPACE, $) {
	'use strict';

	NAMESPACE.feedbackFormPage = (function() {
		var init,
			SELECTORS;

		SELECTORS = {
			FEEDBACK_FORM: '.cm-feedback-form-container',
			TEXT_AREA: '.cm-feedback-form__textarea',
			POSITIVE_FEEDBACK: '.cm-feedback-form .positiveFeedback',
			NEGATIVE_FEEDBACK: '.cm-feedback-form .negativeFeedback',
			ERROR_MESSAGE: '.errorMessage',
			SUCCESS_MESSAGE: '.successMsg',
			CONTACT_MESSAGE: '.contactMeMsg',
			NO_CONTACT_MESSAGE: '.noContactMeMsg',
			FORM: '.cm-feedback-form'
		};

		//Initiates the functionality for the feedback form flows including
		init = function() {
			let form = $(SELECTORS.FORM),
				contactForm = form.find('#contact-form'),
				emailContainer = form.find('#email-field'),
				emailField = emailContainer.find('input'),
				phoneContainer = form.find('#phone-field'),
				phoneField = phoneContainer.find('input');

			form.addClass('js-validate');

			form.find('[name="ContactMeBack"]').change(function(input) {
				contactForm.toggle(input.target.value.toLowerCase() === 'yes');
			});

			phoneContainer.hide();
			emailContainer.hide();

			// Hide container and disable input on selection change
			form.find('[name="ContactBy"]').change(function(input) {
				let isEmail = input.target.value.toLowerCase() === 'email';
				phoneContainer.toggle(!isEmail);
				phoneField.prop('disabled', isEmail);
				emailContainer.toggle(isEmail);
				emailField.prop('disabled', !isEmail);
			});

			$('.negativeFeedback').on('click', function() {

				$(SELECTORS.TEXT_AREA).removeClass('hidden');
				$(this).removeClass('is-secondary').addClass('is-active');
				if (window.dataLayer) {
					window.dataLayer.push({
						event  : 'Gen Lines Claims FAQ',
						didyoufindwhatyourlookingfor : 'No'
					});
				}
			});

			function submitForm(data) {
				var request = $.ajax({
					url: '/api/VisitorFeedback',
					type: 'POST',
					data: data,
					dataType: 'json'
				});
				return request;
			}

			function setMessage(modal, messageClass) {
				var moreFeedbackMsgHTML = $(messageClass).prop('outerHTML');
				$(modal).closest('.cm-feedback-form').html(moreFeedbackMsgHTML);
				$(messageClass).removeClass('hidden');
			}

			function submitWithResponseText(data, modal, messageClass) {
				submitForm(data).done(function(response, textStatus, jqXHR) {
					if (jqXHR && jqXHR.status === 200) {
						setMessage(modal, messageClass);
					}
				}).fail(function() {
					setMessage(modal, SELECTORS.ERROR_MESSAGE);
					if (window.dataLayer) {
						window.dataLayer.push({
							event: 'Problem saving feedback'
						});
					}
				});
			}

			$(SELECTORS.POSITIVE_FEEDBACK).on('click', function(e) {
				e.preventDefault();
				if (window.dataLayer) {
					window.dataLayer.push({
						event  : 'Gen Lines Claims FAQ',
						didyoufindwhatyourlookingfor : 'Yes'
					});
				}
				var data = {
					Url: window.location.href
				};
				data['Feedback'] = $(SELECTORS.POSITIVE_FEEDBACK).text().trim();
				submitWithResponseText(data, this, SELECTORS.SUCCESS_MESSAGE);
			});

			form.on('submit', function(e) {
				e.preventDefault();
				var self = $(this);
				if (self.valid()) {
					var data = {
						Url: window.location.href
					};
					data['Feedback'] = $(SELECTORS.NEGATIVE_FEEDBACK).text().trim();
					self.serializeArray().forEach(function(item) {
						if (item.value) {
							data[item.name] = item.value;
						}
					});
					if (window.dataLayer) {
						window.dataLayer.push({
							event: 'Did Not Find What You are Looking Comment',
							to_be_contacted_by: data['ContactBy']
						});
					}
					var contactMeBack = data['ContactMeBack'] === 'Yes' ? SELECTORS.CONTACT_MESSAGE : SELECTORS.NO_CONTACT_MESSAGE;
					submitWithResponseText(data, this, contactMeBack);
				}
			});

			function reduceChar(val) {
				var len = val.length;
				if (len >= 1000) {
					val = val.substring(0, 1000);
					$(SELECTORS.FEEDBACK_FORM).find('.help').text(1000 - len);
				} else {
					$(SELECTORS.FEEDBACK_FORM).find('.help').text(1000 - len);
				}
			}

			$(SELECTORS.FEEDBACK_FORM).find('textarea').on('input', function() {
				var val = $(this).val();
				reduceChar(val);
			});

			$(window).unload(function() {
				if ($(SELECTORS.NEGATIVE_FEEDBACK).hasClass('is-active')) {
					var feedback = $(SELECTORS.NEGATIVE_FEEDBACK).text().trim();
					var comments = $(SELECTORS.TEXT_AREA).find('textarea').val();

					var data = {
						Url: window.location.href,
						Feedback: feedback,
						Comments: comments
					};
					submitForm(data);
				}
			});
		};
		return {
			init: init
		};
	}());
}(DDIGITAL, jQuery));
