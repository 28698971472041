(function() {
	'use strict';

	$.validator.addMethod('phone-length', function(value) {
		// Let 'pattern' be a pattern that matches a digit.
		var pattern = /^.{8,15}$/;
		// Check if the value in the form control does not match 'pattern'.
		return pattern.test(value);
	}, 'Your phone number should be between 8 and 15 characters');
}());
