// ==========================================================================
// SIDE NAVIGATION
// ==========================================================================


(function(NAMESPACE, $) {

    'use strict';

    NAMESPACE.sideNavigation = (function() {
        var init;

        init = function() {
            $('.side-nav-list li a').click(function(e) {
                if (this.getAttribute('href').charAt(0) === '#') {
                    e.preventDefault();
                    $('.side-nav-list li').addClass('active').siblings().removeClass('active');
                    $('html, body').stop();
                    $('html, body').animate({
                        scrollTop: $($(this).attr('href')).offset().top - 170
                    }, 1000);
                }
			});
			$('.side-nav-container').parent().addClass('side-nav-parent');
        };

        return {
            init: init
        };

    }());

}(DDIGITAL, jQuery));
