// ==========================================================================
// Provider Search Segmented Control
// ==========================================================================

(function(NAMESPACE, $) {

	'use strict';

	NAMESPACE.providerSearchSegCtrl = (function() {

		var init,
			updateCategoryListBtnText;

		updateCategoryListBtnText = function() {

			var newButtonText = '';
			$('.category-list-selection h3').each(function() {
				var checkedInputs = $(this).parent().find('ul li input:checked');
				if (checkedInputs.length > 0) {
					var heading = $(this).text();
					newButtonText = newButtonText + heading.trim() + ': ';
				}

				$(this).parent().find('ul li input:checked').each(function() {
					newButtonText = newButtonText + $(this).next('label').text() + ', ';
				});
			});

			// remove last space a comma.
			newButtonText = newButtonText.slice(0, -2);

			var categoryListButton = $('.category-list-button');
			if (newButtonText) {
				categoryListButton.text(newButtonText);
				categoryListButton.attr('title', newButtonText);
			} else {
				categoryListButton.text('Select a category');
				categoryListButton.attr('title', 'Select a category');
			}
		};
		init = function() {
			// if checkboxes checked then update category list button's text
			// @TODO: populate button text with pre-checked checkboxes on load.
			updateCategoryListBtnText();

			var categoryButton = $('.category-list-button');

			categoryButton.on('click', function() {

				// accessibility
				var categoryListButton = $(this);
				categoryListButton.toggleClass('category-list-button--open');
				// Highlight dropdown when button is clicked
				categoryListButton.parent('li').addClass('is-focused');

				var newAriaCollapsed = categoryListButton.attr('aria-expanded') === 'true' ? 'false' : 'true';
				categoryListButton.attr('aria-expanded', newAriaCollapsed);

				var categoryListSelection = $('.category-list-selection'),
					newAriaHidden = categoryListSelection.attr('aria-hidden') === 'true' ? 'false' : 'true';
				categoryListSelection.attr('aria-hidden', newAriaHidden);

				categoryListSelection.toggleClass('category-list-selection--open');
				$('.segmented-control').toggleClass('segmented-control--open');
			});

			categoryButton.on('focus', function() {
				$(this).parent('li').addClass('is-focused');
			}).on('focusout', function() {
				$(this).parent('li').removeClass('is-focused');
			});

			$('.segmented-control--enter-postcode').on('focus', function() {
				$(this).parent('li').addClass('is-focused');
			}).on('focusout', function() {
				$(this).parent('li').removeClass('is-focused');
			});

			// get all checked values from categories list
			// add this text segmented control via .category-list-button text.
			$('.category-list-item--checkbox').on('click', function() {
				updateCategoryListBtnText();
			});

			$(document).click(function(e) {
				var container = $('.segmented-control--item-1');
				var categoryListSelection = $('.category-list-selection');

				// if you clicked outside the container close menu and remove border highlight
				if (container.has(e.target).length === 0) {
					categoryListSelection.removeClass('category-list-selection--open');
					$('.segmented-control').removeClass('segmented-control--open');
					categoryButton.parent('li').removeClass('is-focused');
					categoryButton.removeClass('category-list-button--open');
				}
			});
		};

		return {
			init: init,
			updateCategoryListBtnText: updateCategoryListBtnText
		};
	}());

}(DDIGITAL, jQuery));
