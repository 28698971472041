// ==========================================================================
// TIMELINE ACCORDION
// ==========================================================================


(function(NAMESPACE, $) {

    'use strict';

    NAMESPACE.timelineAccordion = (function() {
        var init, animateItemAction, itemAction;
        var el = document.querySelector('#timeline');

        init = function() {

            $('.timeline-item').click(function(e) {
                var { target } = e;
                var action = target.getAttribute('data-action');
                var item = target.getAttribute('data-item');

                if (action) {
                    var targetExpanded = action === 'expand' ? 'false' : 'true';
                    var buttons = Array.from(el.querySelectorAll(`[aria-expanded="${targetExpanded}"]`));
                    var wasExpanded = action === 'collapse';

                    for (let button of buttons) {
                        var buttonID = button.getAttribute('data-item');
                        var ctrld = el.querySelector(`#item${buttonID}-ctrld`);
                        var contentHeight = ctrld.firstElementChild.offsetHeight;

                        animateItemAction(button, ctrld, contentHeight, wasExpanded);
                    }

                } else if (item) {
                    var button = el.querySelector(`[data-item="${item}"]`);
                    var expanded = button.getAttribute('aria-expanded');

                    if (!expanded) {return;}

                    var wasExpanded = expanded === 'true';
                    var ctrld = el.querySelector(`#item${item}-ctrld`);
                    var contentHeight = ctrld.firstElementChild.offsetHeight;

                    animateItemAction(button, ctrld, contentHeight, wasExpanded);
                }
            });
        };

        animateItemAction = function(button, ctrld, contentHeight, shouldCollapse) {
            var expandedClass = 'timeline-item-body--expanded';
            var animOptions = {
                duration: 300,
                easing: 'cubic-bezier(0.65,0,0.35,1)'
            };
            var animation;

            if (shouldCollapse) {
                button.ariaExpanded = 'false';
                ctrld.ariaHidden = 'true';
                ctrld.classList.remove(expandedClass);
                animOptions.duration *= 2;
                animation = ctrld.animate([
                    { height: `${contentHeight}px` },
                    { height: `${contentHeight}px` },
                    { height: '0px' }
                ], animOptions);
            } else {
                button.ariaExpanded = 'true';
                ctrld.ariaHidden = 'false';
                ctrld.classList.add(expandedClass);
                animation = ctrld.animate([
                    { height: '0px' },
                    { height: `${contentHeight}px` }
                ], animOptions);
            }
        };
        itemAction = function(e) {
            const { target } = e;
            const action = target.getAttribute('data-action');
            const item = target.getAttribute('data-item');

            if (action) {
                const targetExpanded = action === 'expand' ? 'false' : 'true';
                const buttons = Array.from(el.querySelectorAll(`[aria-expanded="${targetExpanded}"]`));
                const wasExpanded = action === 'collapse';

                for (let button of buttons) {
                    const buttonID = button.getAttribute('data-item');
                    const ctrld = el.querySelector(`#item${buttonID}-ctrld`);
                    const contentHeight = ctrld.firstElementChild.offsetHeight;

                    animateItemAction(button, ctrld, contentHeight, wasExpanded);
                }

            } else if (item) {
                const button = el.querySelector(`[data-item="${item}"]`);
                const expanded = button.getAttribute('aria-expanded');

                if (!expanded) {return;}

                const wasExpanded = expanded === 'true';
                const ctrld = el.querySelector(`#item${item}-ctrld`);
                const contentHeight = ctrld.firstElementChild.offsetHeight;

                animateItemAction(button, ctrld, contentHeight, wasExpanded);
            }
        };
        return {
            init: init
        };


    }());

}(DDIGITAL, jQuery));
