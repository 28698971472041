// ==========================================================================
// EXPIRY DATE VALIDATION RULE
// Expiry date - expects two fields - date must be in the future
// ==========================================================================

(function(NAMESPACE) {

	'use strict';

	$.validator.addMethod('cc-expiry', function(value, ctrl) {
		var valid = false,
			$ctrls = NAMESPACE.forms.decorator.getCtrlsInGroup(ctrl),
			month = parseInt($ctrls.eq(0).val(), 10),
			year = parseInt($ctrls.eq(1).val(), 10),
			currentMonth = parseInt(new Date().getMonth(), 10),
			currentYear = parseInt(new Date().getFullYear().toString().substr(2, 2), 10),
			datesAreValid = false;

		if (!isNaN(month) && !isNaN(year)) {
			datesAreValid = true;
		}

		// if both dates are valid
		if (datesAreValid
			// if the year is greater than current it's valid, or if it's the current year
			// and the month is greater than or equal, then it's valid
			&& (year > currentYear || (year === currentYear && month - 1 >= currentMonth))) {
			valid = true;
		}

		return valid;
	}, 'Please enter a valid expiry date.');

}(DDIGITAL));
