/* ==========================================================================
 * ACCESSIBILITY SETTINGS
 * SETTINGS PAGE FUNCTIONALITY & GLOBAL APPLICATION OF SETTINGS
 * ========================================================================== */

(function(NAMESPACE, $) {

	'use strict';

	NAMESPACE.a11ySettingsPage = (function() {

		var CONST,
			CLASSES,
			SELECTORS,

		// ---- Scope variables
			_settingsPageExists,
		//	_confirmationToggleCount = 0,

			_$fontDropdown,
			_$highContrastRadio,
			_existingFont,
			_existingHighContrastMode,

		// ---- Functions
			_showConfirmationMessage,
			_retrieveExistingSettings,
			_applyGlobalFont,
			_setHighContrastMode,
			_setNightMode,

			init;

		CONST = {
			// Value of radio button in high contrast group
			NIGHT_MODE: 'Night Mode',

			DEFAULT_SETTINGS: {
				FONT: 'Default',
				HIGH_CONTRAST_MODE: 'false'
			},

			LOCAL_STORAGE: {
				FONT: 'a11y-settings-font',
				HIGH_CONTRAST_MODE: 'a11y-settings-high-contrast-mode'
			},

			CSS_FONT_FALLBACK: '-apple-system, BlinkMacSystemFont, sans-serif',

			// DOMAIN: 'localhost' // DEV ONLY
			DOMAIN: 'icare.nsw.gov.au'
		};

		CLASSES = {
			CSS_OVERRIDE_CONTAINER: 'css-override-container',
			HIGH_CONTRAST_MODE: 'high-contrast-mode',
			NIGHT_MODE: 'night-mode',
			NM_INVERT: 'nm-invert',
			HIDDEN: 'hidden'
		};

		SELECTORS = {
			CSS_OVERRIDE: {
				PREPEND_TO: 'body',
				CONTAINER: '.' + CLASSES.CSS_OVERRIDE_CONTAINER
			},

			FORM: {
				ELEMENT: '.a11y-settings-form',
				FONT_DROPDOWN: '#font_selection',
				HIGH_CONTRAST_RADIO: '[name="high_contrast_mode"]',

				SUCCESS_MESSAGE: '.change-success-message',
				CLOSE_MESSAGE_BTN: '.close-message-button',

				SAVE_SETTINGS_BTN: '.js-submit-a11y-settings',
				RESET_SETTINGS_BTN: '.js-reset-a11y-settings'
			}
		};

		/**
		 * Retrieve existing settings (if they exist) from browser local storage
		 *
		 * @param callback {Function}
		 */
		_retrieveExistingSettings = function(callback) {
			var _existingFont = Cookies.get(CONST.LOCAL_STORAGE.FONT),
				_existingHighContrastMode = Cookies.get(CONST.LOCAL_STORAGE.HIGH_CONTRAST_MODE);

			if (_existingFont != null) {
				_applyGlobalFont(_existingFont);

				if (_settingsPageExists) {
					_$fontDropdown.val(_existingFont);
				}
			}

			if (_existingHighContrastMode != null) {
				_setHighContrastMode(_existingHighContrastMode);

				if (_settingsPageExists) {
					_$highContrastRadio.filter('[value="' + _existingHighContrastMode + '"]').prop('checked', true);
				}
			}

			NAMESPACE.util.helpers.triggerCallback(callback);
		};

		/**
		 * Apply font globally to all elements on the page
		 *
		 * @param font {String}
		 * @param saveNewValue {Boolean} 	Whether to save the applied setting to local storage
		 */
		_applyGlobalFont = function(font, saveNewValue) {
			var _$cssContainer = $(SELECTORS.CSS_OVERRIDE.CONTAINER),
				saveNewValue = (saveNewValue != null) ? saveNewValue : false;

			if (font === 'Default') {
				// For the default font, simply wipe any applied CSS overrides
				_$cssContainer.html('');
			} else {
				// Apply CSS override to target all elements with target font, include fallback fonts
				_$cssContainer.html([
					'<style>',
						'* {',
							'font-family: "' + font + '", ' + CONST.CSS_FONT_FALLBACK + ' !important;',
						'}',
					'</style>'
				].join(''));
			}

			if (saveNewValue) {
				Cookies.set(CONST.LOCAL_STORAGE.FONT, font, {
					domain: CONST.DOMAIN
				});

				setTimeout(function() {
					localStorage.setItem(CONST.LOCAL_STORAGE.FONT, font);
				}, 100);
			}
		};

		/**
		 * Toggles the status of high contrast mode
		 *
		 * @param value {String}
		 * @param saveNewValue {Boolean} 	Whether to save the applied setting to local storage
		 */
		_setHighContrastMode = function(value, saveNewValue) {
			var _highContrastEnabled = NAMESPACE.util.helpers.parseBoolean(value),
				_nightModeEnabled = value === CONST.NIGHT_MODE,
				saveNewValue = (saveNewValue != null) ? saveNewValue : false;

			// Mutually exclusive, only one of these lines will run since they stem from opposite values of 'value' param
			_setNightMode(_nightModeEnabled, saveNewValue);
			$(SELECTORS.CSS_OVERRIDE.PREPEND_TO).toggleClass(CLASSES.HIGH_CONTRAST_MODE, _highContrastEnabled);

			if (saveNewValue) {
				Cookies.set(CONST.LOCAL_STORAGE.HIGH_CONTRAST_MODE, value, {
					domain: CONST.DOMAIN
				});

				setTimeout(function() {
					localStorage.setItem(CONST.LOCAL_STORAGE.HIGH_CONTRAST_MODE, value);
				}, 100);
			}
		};

		/**
		 * Toggles the status of night mode
		 *
		 * @param toggle {boolean}
		 * @param saveNewValue {Boolean} 	Whether to save the applied setting to local storage
		 */
		_setNightMode = function(toggle, saveNewValue) {
			var _$pageContainer = $(SELECTORS.CSS_OVERRIDE.PREPEND_TO),
				toggle = NAMESPACE.util.helpers.parseBoolean(toggle);

			$('[style]:not(.icon)').each(function(idx, el) {
				var _$el = $(el);

				if (_$el.attr('style').indexOf('background-image') !== -1) {
					_$el.addClass(CLASSES.NM_INVERT);
				}
			});

			_$pageContainer.toggleClass(CLASSES.NIGHT_MODE, toggle);

			if (saveNewValue) {
				Cookies.set(CONST.LOCAL_STORAGE.NIGHT_MODE, toggle, {
					domain: CONST.DOMAIN
				});

				setTimeout(function() {
					localStorage.setItem(CONST.LOCAL_STORAGE.NIGHT_MODE, toggle);
				}, 100);
			}
		};

		/**
		 * Show change confirmation message (change will not always be visually tangible)
		 */
		_showConfirmationMessage = function() {
			// var _thisTimeoutCount = _confirmationToggleCount += 1;
			var _$successMessage = $(SELECTORS.FORM.SUCCESS_MESSAGE);
			var _$closeMessageBtn = $(SELECTORS.FORM.CLOSE_MESSAGE_BTN);

			_$successMessage.removeClass(CLASSES.HIDDEN);

			_$closeMessageBtn.on('click', function(e) {
				e.preventDefault();

				_$successMessage.addClass(CLASSES.HIDDEN);
			});

			// Hide again after a few seconds
			// setTimeout(function() {
			// 	if (_thisTimeoutCount === _confirmationToggleCount) {
			// 		_$successMessage.addClass(CLASSES.HIDDEN);
			// 	}
			// }, 5000);
		};

		init = function() {
			var _$a11ySettingsForm = $(SELECTORS.FORM.ELEMENT);

			// Save settings state from page load so we can detect changes from other tabs/sessions without relying
			// on the presence of the a11y form controls. This is necessary to determine when these settings change in
			// the below 'storage' event listener, which is quite noisy
			_existingFont = Cookies.get(CONST.LOCAL_STORAGE.FONT),
			_existingHighContrastMode = Cookies.get(CONST.LOCAL_STORAGE.HIGH_CONTRAST_MODE);

			// Set scope variables/elements
			_settingsPageExists = _$a11ySettingsForm.length > 0;
			_$fontDropdown = $(SELECTORS.FORM.FONT_DROPDOWN);
			_$highContrastRadio = $(SELECTORS.FORM.HIGH_CONTRAST_RADIO);

			// Check if CSS override container exists, else create it
			// It shouldn't exist on page load, but covers potential edge cases like multi-init
			if ($(SELECTORS.CSS_OVERRIDE.CONTAINER).length === 0) {

				// This container will be used to inject our <style> tags with the necessary global CSS overrides
				$(SELECTORS.CSS_OVERRIDE.PREPEND_TO).prepend($(
					'<div class="' + CLASSES.CSS_OVERRIDE_CONTAINER + '"></div>'
				));
			}

			// Disable night mode option in IE, as CSS filters are not compatible
			if ($('.is-ie').length > 0) {
				$(SELECTORS.FORM.HIGH_CONTRAST_RADIO).filter('[value="' + CONST.NIGHT_MODE + '"]').prop('disabled', true);
			}

			// Retrieve and apply any existing global a11y settings
			_retrieveExistingSettings(function() {
				// Only attempt action binds if the form exists (as this init will run on all pages)
				if (_settingsPageExists) {
					var _$saveSettingsBtn = $(SELECTORS.FORM.SAVE_SETTINGS_BTN),
						_$resetSettingsBtn = $(SELECTORS.FORM.RESET_SETTINGS_BTN);

					_$a11ySettingsForm.on('change', function() {
						_$saveSettingsBtn.prop('disabled', false);
						_$resetSettingsBtn.prop('disabled', false);
					});

					_$saveSettingsBtn.on('click', function(e) {
						e.preventDefault();

						_applyGlobalFont(_$fontDropdown.val(), true);
						_setHighContrastMode(_$highContrastRadio.filter(':checked').val(), true);

						// Disable save button until a new change is made
						// This is necessary so you can't spam the save button (as action is done client-side)
						_$saveSettingsBtn.prop('disabled', true);
						_$resetSettingsBtn.prop('disabled', false);
						_showConfirmationMessage();
					});

					_$resetSettingsBtn.on('click', function(e) {
						e.preventDefault();

						// Update global settings
						_applyGlobalFont(CONST.DEFAULT_SETTINGS.FONT, true);
						_setHighContrastMode(CONST.DEFAULT_SETTINGS.HIGH_CONTRAST_MODE, true);

						// Update UI to reflect
						_$fontDropdown.val(CONST.DEFAULT_SETTINGS.FONT);
						_$highContrastRadio.filter('[value="' + CONST.DEFAULT_SETTINGS.HIGH_CONTRAST_MODE + '"]').prop('checked', true);

						_$resetSettingsBtn.prop('disabled', true);
						_showConfirmationMessage();
					});
				}
			});

			// Watch for user preferences changes in other tabs
			$(window).bind('storage', function() {
				var _retrievedFont = Cookies.get(CONST.LOCAL_STORAGE.FONT),
					_retrievedHighContrastMode = Cookies.get(CONST.LOCAL_STORAGE.HIGH_CONTRAST_MODE);

				// Only apply if there are changes detected to that setting, to avoid spamming DOM manipulation
				// This is necessary because the window 'storage' listener can be quite noisy
				if (_retrievedFont !== _existingFont) {
					_applyGlobalFont(_retrievedFont);
					_existingFont = _retrievedFont;
				}

				if (_retrievedHighContrastMode !== _existingHighContrastMode) {
					_setHighContrastMode(_retrievedHighContrastMode);
					_existingHighContrastMode = _retrievedHighContrastMode;
				}
			});
		};

		return {
			init: init
		};
	}());

}(DDIGITAL, jQuery));
