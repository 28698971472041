
(function(NAMESPACE, $) {

	'use strict';

	NAMESPACE.EmergencyBanner = (function() {

		var SELECTORS,
			init;

		SELECTORS = {
			BANNER: '.emergency-banner',
			BTN: '.closetBtn'
		};

		var closeEmergencyBanner =  function(el) {
			var guid = $(el).data('guid');
			var componentName = $(SELECTORS.BANNER).find('.vh').text();
			if (window.dataLayer) {
				window.dataLayer.push({
					event: 'nsw emergency banner close button',
					componentnameclosed: componentName
				});
			}
			sessionStorage.setItem('emb-' + guid, '1');
			$(el).parents(SELECTORS.BANNER).fadeOut();
		};

		init = function() {
			var banners = $('.emergency-banner');
			$.each(banners, function(i, banner) {
				var guid = $(banner).find('.closeBtn').data('guid');
				if (sessionStorage.getItem('emb-' + guid) === null || sessionStorage.getItem('emb-' + guid) !== '1') {
					$(banner).show();
				}
			});

			$('.readMoreLink').click(function(e) {
				e.preventDefault();
				var href = $(this).attr('href');
				var linkText = $(this).parentsUntil('.emergency-banner').find('.vh').text() + ' ' + $(this).find('.readMoreLink__desc').text();

				if (window.dataLayer) {
					window.dataLayer.push({
						event: 'nsw emergency banner message',
						linktext: linkText,
						LinkUrl: href
					});
				}
				window.open(href, '_blank');
			});
		};

		return {
			init: init,
			closeEmergencyBanner: closeEmergencyBanner
		};
	}());

}(DDIGITAL, jQuery));
