// ==========================================================================
// CAROUSEL
// ==========================================================================

(function(NAMESPACE, $) {

	'use strict';

	/**
	 * Creates a basic carousel slider using slick.js
	 *
	 * @namespace carousel
	 * @memberof DDIGITAL
	 * @version 0.0.1
	 * @author Deloitte Digital Australia
	 */
	NAMESPACE.carousel = (function() {
		var constants,
			ATTRS,
			CLASSES,
			SELECTORS,
			_checkCarouselTheme,
			_getCarouselOptions,
			_equaliseHeights,
			_enable,
			_disable,
			_initADA,
			init;

		constants = {
			DEFAULT_OPTIONS: {
				infinite: true,
				cssEase: 'ease-out',
				dots: false,
				speed: 600,
				adaptiveHeight: false
			},
			DEFAULT_AUTOPLAY: true
		};

		ATTRS = {
			CAROUSEL_TYPE: 'data-carousel-type',
			HAS_CAROUSEL: 'has-carousel'
		};

		CLASSES = {
			IS_DARK: 'is-dark'
		};

		SELECTORS = {
			CAROUSEL: '.js-carousel',
			CAROUSEL_SLIDE: '.slick-slide',
			CAROUSEL_TRACK: '.slick-track',
			HOMEPAGE_USER_TASK_NAV: '.homepage-user-task-nav',
			HERO_CAROUSEL: '.hero-carousel',
			CONTENT: '.content',
			PLAY_PAUSE_BUTTON: '.hero-carousel--play-button',
			BANNER_LINK_WRAPPER: '.banner-link-wrapper',
			BANNER_LINK: '.banner-link'
		};


		/**
		 * Check the current slide and detect if it's light (default) or dark
		 *
		 * @memberof DDIGITAL.carousel
		 * @param  {Object} $carousel jQuery object of the carousel item
		 * @param  {Object} $currentSlide jQuery object of the currently selected slide
		 * @private
		 */
		_checkCarouselTheme = function($carousel, $currentSlide) {
			if ($currentSlide.hasClass(CLASSES.IS_DARK)) {
				$carousel.addClass(CLASSES.IS_DARK);
			} else {
				$carousel.removeClass(CLASSES.IS_DARK);
			}
		};

		/**
		 * Get the options for the carousel slider
		 *
		 * @memberof DDIGITAL.carousel
		 * @param  {Object} $carousel jQuery object of the carousel item
		 * @return {Object|Boolean} Returns an object of options or if the carousel needs to be disabled returns false
		 * @private
		 */
		_getCarouselOptions = function($carousel) {
			var type = $carousel.attr(ATTRS.CAROUSEL_TYPE) || 'default',
				options = {};

			options = {
				type: type,
				opts: constants.DEFAULT_OPTIONS,
				arrows: true,
				adaptiveHeight: false,
				autoplay: constants.DEFAULT_AUTOPLAY,
				speed: 500,
				pauseOnHover: true,
				pauseOnFocus: true,
				dots: false,
				infinite: true,
				autoplaySpeed: 6000,
				prevArrow: '.slick-prev',
				nextArrow: '.slick-next',
				responsive: [
					{
						breakpoint: 1024,
						settings: {
							autoplay: false,
							arrows: false,
							dots: false
						}
					},
					{
						breakpoint: 480,
						settings: {
							arrows: false,
							dots: false,
							autoplay: false
						}
					}
				],
				events: {
					beforeChange: function(event, slick, currentSlide, nextSlide) {
						var $carousel = $(this),
							$currentSlide = $carousel.find(SELECTORS.CAROUSEL_SLIDE).eq(nextSlide);

						_checkCarouselTheme($carousel, $currentSlide);
					}
				}
			};

			if (type === 'hero' || type === 'default') {
				return options;
			}

			return false;
		};

		/**
		 * If required, equalise the heights of the carousel slides so
		 * it doesn't look weird between slides with various heights
		 *
		 * @memberof DDIGITAL.carousel
		 * @param  {Object} $carousel jQuery object of the carousel item
		 * @private
		 */
		_equaliseHeights = function($carousel) {
			var equaliseSlideHeights;

			equaliseSlideHeights = function() {
				$carousel.find(SELECTORS.CAROUSEL_SLIDE).css('height', '');

				var trackHeight = parseInt($carousel.find(SELECTORS.CAROUSEL_TRACK).height(), 10);

				$carousel.find(SELECTORS.CAROUSEL_SLIDE).css('height', trackHeight + 'px');
			};

			$(window).on('resize.carousel', $.debounce(250, equaliseSlideHeights));
			equaliseSlideHeights();
		};

		/**
		 * Enable the carousel
		 *
		 * @memberof DDIGITAL.carousel
		 * @param  {Object} $carousel jQuery object of the carousel item
		 * @private
		 */
		_enable = function($carousel) {
			var options = _getCarouselOptions($carousel);

			if ($carousel.data(ATTRS.HAS_CAROUSEL)) {
				console.warn('Carousel is already enabled');
				return;
			}

			$.extend(options, constants.DEFAULT_OPTIONS, options.opts);

			var slick = $carousel.slick(options);
			if (slick.length) {
				slick = slick[0].slick;
				// Patch slick accessibility attr method
				Object.getPrototypeOf(slick).initADA = _initADA;
			}

			// Remove attr manually once
			$carousel.find('.slick-list').removeAttr('aria-live');
			$carousel.find('.slick-track').removeAttr('role');
			$carousel.find('.hero-carousel-item').removeAttr('role').removeAttr('aria-describedby');

			$carousel.data(ATTRS.HAS_CAROUSEL, true);

			// check theme (dark or light) on load
			_checkCarouselTheme($carousel, $carousel.find(SELECTORS.CAROUSEL_SLIDE).eq(0));

			if (options.adaptiveHeight !== true) {
				_equaliseHeights($carousel, options);
			}
			// apply events
			if (options.events) {
				for (var key in options.events) {
					if (options.events.hasOwnProperty(key)) {
						$carousel.on(key, options.events[key]);
					}
				}
			}
		};

		/**
		 * Patch slick method for generating a11y attr
		 * Copied from source code
		 */
		_initADA = function() {
			var _this = this;

			_this.$slides.add(_this.$slideTrack.find('.slick-cloned')).attr({
				'aria-hidden': 'true',
				'tabindex': '-1'
			}).find('a, input, button, select').attr({
				tabindex: '-1'
			});

			// Remove not needed attr
			// _this.$slideTrack.attr('role', 'listbox');

			// _this.$slides.not(_this.$slideTrack.find('.slick-cloned')).each(function(i) {
			// 	$(this).attr({
			// 		'role': 'option',
			// 		'aria-describedby': 'slick-slide' + _this.instanceUid + i + ''
			// 	});
			// });

			if (_this.$dots !== null) {
				_this.$dots.attr('role', 'tablist').find('li').each(function(i) {
					$(this).attr({
						'role': 'presentation',
						'aria-selected': 'false',
						'aria-controls': 'navigation' + _this.instanceUid + i + '',
						'id': 'slick-slide' + _this.instanceUid + i + ''
					});
				})
					.first().attr('aria-selected', 'true').end()
					.find('button').attr('role', 'button').end()
					.closest('div').attr('role', 'toolbar');
			}

			_this.$slideTrack.find('.slick-active').attr({
				'aria-hidden': 'false'
			}).find('a, input, button, select').attr({
				tabindex: '0'
			});
		};

		/**
		 * Disable the carousel
		 *
		 * @memberof DDIGITAL.carousel
		 * @param  {Object} $carousel jQuery object of the carousel item
		 * @private
		 */
		_disable = function($carousel) {
			if ($carousel.data(ATTRS.HAS_CAROUSEL)) {
				$carousel.slick('unslick');
			}

			$carousel.data(ATTRS.HAS_CAROUSEL, false);
		};

		/**
		 * Init the carousel with checking code to detect when changes should be made to it on screen resize
		 *
		 * @memberof DDIGITAL.carousel
		 */
		init = function() {
			var check;

			check = function() {
				$(SELECTORS.CAROUSEL).each(function(i, el) {
					var $carousel = $(el),
						options = _getCarouselOptions($carousel);

					if (options === false) {
						_disable($carousel);
						return;
					}

					if ($carousel.data(ATTRS.HAS_CAROUSEL) === false) {
						_enable($carousel);
					}
				});
			};

			$(SELECTORS.CAROUSEL).each(function(i, el) {
				$(el).data(ATTRS.HAS_CAROUSEL, false);
			});
			var prevAutoplayValue = constants.DEFAULT_AUTOPLAY;
			$(SELECTORS.HERO_CAROUSEL).on('focusin', function() {
				var $carousel = $(SELECTORS.CAROUSEL);
				prevAutoplayValue = $carousel.slick('slickGetOption', 'autoplay');
				$carousel
					.slick('slickPause')
					.slick('slickSetOption', 'autoplay', false);
			});

			$(SELECTORS.HERO_CAROUSEL).on('focusout', function() {
				var $carousel = $(SELECTORS.CAROUSEL);
				$carousel
					.slick('slickPlay')
					.slick('slickSetOption', 'autoplay', prevAutoplayValue);
			});

			var prevAutoplayValue = constants.DEFAULT_AUTOPLAY;
			$(SELECTORS.HERO_CAROUSEL).on('focusin', function() {
				var $carousel = $(SELECTORS.CAROUSEL);
				prevAutoplayValue = $carousel.slick('slickGetOption', 'autoplay');
				$carousel
					.slick('slickPause')
					.slick('slickSetOption', 'autoplay', false);
			});

			$(SELECTORS.HERO_CAROUSEL).on('focusout', function() {
				var $carousel = $(SELECTORS.CAROUSEL);
				$carousel
					.slick('slickPlay')
					.slick('slickSetOption', 'autoplay', prevAutoplayValue);
			});

			$(SELECTORS.PLAY_PAUSE_BUTTON).click(function onPlayPauseClick() {
				var $carousel = $(SELECTORS.CAROUSEL);
				var wasAutoplaying = prevAutoplayValue;
				prevAutoplayValue = !prevAutoplayValue;
				$carousel
					.slick(wasAutoplaying ? 'slickPause' : 'slickPlay')
					.slick('slickSetOption', 'autoplay', !wasAutoplaying);

				var isAutoplaying = $carousel.slick('slickGetOption', 'autoplay');
				var $button = $(this);
				$button
					.attr('aria-pressed', !isAutoplaying)
					.attr('aria-label', isAutoplaying ? 'Pause Slideshow' : 'Play Slideshow');
				$button.find('.svg-icon use')
					.attr('xlink:href', isAutoplaying ? '#icon--pause' : '#icon--play');
			});

			// Avoid "focus" on mouse clicks
			$(SELECTORS.PLAY_PAUSE_BUTTON).mousedown(function onPlayPauseMouseDown(event) {
				event.preventDefault();
			});
			$(SELECTORS.PLAY_PAUSE_BUTTON).mouseup(function onPlayPauseMouseUp() {
				var $button = $(this);

				// in case buton is focused but we click with mouse, blur so we remove :focus style
				$button.blur();
			});

			// add a size check using enquire here and call `check` on size change.
			// in order to change options depending on screensize, use the _getCarouselOptions
			// function to do a size check there. To disable the carousel completely return false
			// from _getCarouselOptions
			check();
		};

		return {
			init: init
		};
	}());

}(DDIGITAL, jQuery));
