// ==========================================================================
// M01 - GLOBAL HEADER
// ==========================================================================

(function(NAMESPACE, $) {

	'use strict';

	/**
	 * Global header functions
	 *
	 * @namespace globalHeader
	 * @memberof DDIGITAL
	 * @version 1.0.0
	 * @author Deloitte Digital Australia
	 */
	NAMESPACE.globalHeader = (function() {
		var SELECTORS,
			init,
			callbacks = new $.Callbacks(),
			_openLoginMenu,
			closeLoginMenu,
			toggleLoginMenu,
			resetButtonLabel;

		SELECTORS = {
			GLOBAL_HEADER: '.global-header',
			LOGIN_BUTTON: '.login',
			LOGIN_OPTIONS: '.login-options',
			LOGIN_CLOSE: '.login-top-close-btn ',
			LOGIN_DESCRIPTION: '#login-description',
			SCREEN_OVERLAY: '.overlay-for-nav'
		};

		// Let 'button' be the login button.
		var button = document.querySelector(SELECTORS.LOGIN_BUTTON);
		// 	Let 'menu' be the navigation menu associated with 'button'.
		var menu = document.querySelector(SELECTORS.LOGIN_OPTIONS);
		var closeBtn = document.querySelector(SELECTORS.LOGIN_CLOSE);
		var overlay = document.querySelector(SELECTORS.SCREEN_OVERLAY);

		/**
		 * Open login menu
		 * @memberof DDIGITAL.globalHeader
		 */
		_openLoginMenu = function() {
			$('.supports-js').addClass('overflow-full-screen');
			callbacks.add(function() {
				$(SELECTORS.LOGIN_DESCRIPTION).focus();
			});

			// Update assistive technologies about the status of 'button'.
			menu.classList.add('is-active');
			menu.setAttribute('aria-hidden', false);
			button.setAttribute('aria-label', 'Click to close the Login menu');
			button.setAttribute('aria-expanded', true);
			$(SELECTORS.SCREEN_OVERLAY).show();
			callbacks.fire();
		};

		resetButtonLabel = function() {
			button.setAttribute('aria-label', 'Click to open the login menu');
			button.removeEventListener('focusout', resetButtonLabel, true);
		};

		/**
		 * Close login menu
		 * @param {Event} event - The event object.
		 * @memberof DDIGITAL.globalHeader
		 * @listens MouseEvent#click
		 */
		closeLoginMenu = function(event) {
			// Let 'target' be the target of this event.
			var target = event.target;
			$('.supports-js').removeClass('overflow-full-screen');
			$('.cm-floating-form').show();

			// If 'target' is anything but 'button' and 'menu'.
			if (target !== button && target !== menu) {
				// Hide 'menu'.
				button.classList.remove('opened');
				// Update assistive technologies about the status of 'button'.
				menu.classList.remove('is-active');
				menu.setAttribute('aria-hidden', true);
				button.setAttribute('aria-label', 'Login menu closed, click to open the login menu again');
				button.setAttribute('aria-expanded', false);
				$(SELECTORS.SCREEN_OVERLAY).hide();
				button.addEventListener('focusout', resetButtonLabel, true);
				// Set focus to 'button'.
				button.focus();

				// Stop listening for click events outside 'menu' since 'menu' is now hidden.
				document.removeEventListener('click', closeLoginMenu, true);
			}
		};

		/**
		 * Toggle login menu
		 * @memberof DDIGITAL.globalHeader
		 * @listens Event#click
		 */
		toggleLoginMenu = function() {
			// Let 'classList' be a collection of class values associated with 'button'.
			var classList = button.classList;
			// Hide or show 'menu' accordingly.
			classList.toggle('opened');

			// If 'menu' is visible.
			if (classList.contains('opened')) {
				// Listen for click events occurring outside 'menu'.
				closeBtn.addEventListener('click', toggleLoginMenu, true);
				overlay.addEventListener('click', toggleLoginMenu, true);
				_openLoginMenu();
				$('.cm-floating-form').hide();
			} else {
				closeLoginMenu({});
			}
		};

		init = function() {
			button.addEventListener('click', toggleLoginMenu, true);
		};

		return {
			init: init,
			closeLoginMenu: closeLoginMenu,
			toggleLoginMenu: toggleLoginMenu
		};
	})();
})(DDIGITAL, jQuery);
