// ==========================================================================
// FORMATTER
// ==========================================================================

/**
 * Deloitte Digital global namespace
 * @namespace DDIGITAL
 */
(function(NAMESPACE) {

	'use strict';

	/**
	 * Utility namespace
	 * @namespace DDIGITAL.util
	 * @memberof DDIGITAL
	 */
	NAMESPACE.util = NAMESPACE.util || {};

	/**
	 * Breakpoints for JavaScript. Works with the Deloitte Digital SCSS @bp mixin
	 *
	 * @namespace formatter
	 * @memberof DDIGITAL.util
	 * @version 1.0.0
	 * @author Deloitte Digital Australia deloittedigital@deloitte.com.au
	 */
	NAMESPACE.util.formatter = (function() {
		var toCurrency,
			abbrNum,
			roundNum,
			isNumber;

		/**
		 * Converts a number to a currency format.
		 *
		 * @memberof DDIGITAL.util.formatter
		 * @param  {Number} number The number to convert to a currency
		 * @param  {Number} [decimals=2] The number of decimals to display
		 * @param  {String} [decimalSep=.] String for the decimal separator
		 * @param  {String} [thousandsSep=,] String for the thousands separator
		 *
		 * @example
		 * NAMESPACE.util.formatter.toCurrency(1400, 2, '.', ','); // returns 1,400.00
		 */
		toCurrency = function(number, decimals, decimalSep, thousandsSep) {
			var c = isNaN(decimals) ? 2 : Math.abs(decimals), //if decimal is zero we must take it, it means user does not want to show any decimal
				d = decimalSep || '.', //if no decimal separator is passed we use the dot as default decimal separator (we MUST use a decimal separator)

				t = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep, //if you don't want to use a thousands separator you can pass empty string as thousandsSep value

				sign = (number < 0) ? '-' : '',

				//extracting the absolute value of the integer part of the number and converting to string
				i = parseInt(number = Math.abs(number).toFixed(c), 10) + '',

				j;
			j = ((j = i.length) > 3) ? j % 3 : 0;
			return sign + (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) + (c ? d + Math.abs(number - i).toFixed(c).slice(2) : '');
		};

		/**
		 * Abbreviates a number to use k, m, b or t after it to help shorten the phsyical space taken up by the number
		 *
		 * @memberof DDIGITAL.util.formatter
		 * @param  {Number} number The number to abbreviate
		 * @param  {Number} [decimals=2] The number of decimals to display
		 *
		 * @example
		 * NAMESPACE.util.formatter.abbrNum(1000000, 2); // returns 1m
		 *
		 * @example
		 * DDIGITAL.util.formatter.abbrNum(999999, 0); // returns 1000k
		 *
		 * @example
		 * DDIGITAL.util.formatter.abbrNum(999999, 3); // returns 999.999k
		 *
		 * @example
		 * DDIGITAL.util.formatter.abbrNum(1999999, 5); // returns 2m
		 *
		 * @example
		 * DDIGITAL.util.formatter.abbrNum(1999999, 6); // returns 1.999999m
		 */
		abbrNum = function(number, decimals) {
			// 2 decimal places => 100, 3 => 1000, etc
			decimals = Math.pow(10, decimals);

			// Enumerate number abbreviations
			var abbrev = ['k', 'm', 'b', 't'];

			// Go through the array backwards, so we do the largest first
			for (var i = abbrev.length - 1; i >= 0; i -= 1) {

				// Convert array index to "1000", "1000000", etc
				var size = Math.pow(10, (i + 1) * 3);

				// If the number is bigger or equal do the abbreviation
				if (size <= number) {
					// Here, we multiply by decimals, round, and then divide by decimals.
					// This gives us nice rounding to a particular decimal place.
					number = Math.round(number * decimals / size) / decimals;

					// Add the letter for the abbreviation
					number += abbrev[i];

					break;
				}
			}

			return number;
		};

		/**
		 * Rounds a number to the specified number of decimal places
		 *
		 * @memberof DDIGITAL.util.formatter
		 * @param  {Number} number The number to round
		 * @param  {Number} [decimals=2] The number of decimals to display
		 *
		 * @example
		 * NAMESPACE.util.formatter.roundNum(3.3333, 2); // returns 3.33
		 *
		 * @example
		 * DDIGITAL.util.formatter.roundNum(3.3333, 0); // returns 3
		 *
		 * @example
		 * DDIGITAL.util.formatter.roundNum(666.666, 0); // returns 667
		 *
		 * @example
		 * DDIGITAL.util.formatter.roundNum(666.666, 2); // returns 666.67
		 */
		roundNum = function(number, decimals) {
			return Math.round(number * Math.pow(10, decimals)) / Math.pow(10, decimals);
		};

		/**
		 * Checks if a value is a number
		 *
		 * @memberof DDIGITAL.util.formatter
		 * @param  {Number} value The value to check if it's a number
		 * @return {Boolean}
		 */
		isNumber = function(value) {
			var isANum = (!isNaN(value - 0) && value !== null && value !== '' && value !== false);
			return isANum;
		};

		return {
			toCurrency: toCurrency,
			abbrNum: abbrNum,
			roundNum: roundNum,
			isNumber: isNumber
		};
	}());

}(DDIGITAL));
