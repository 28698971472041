window.DDIGITAL = window.DDIGITAL || {};

// ==========================================================================
// Decision Aid
// ==========================================================================

(function(NAMESPACE, $) {
	'use strict';

	NAMESPACE.decision = (function() {
		var init;
		init = function() {
			$(document).ready(function() {
				const decisionaidmodal = $('#decision-aid-modal');
				const openDecisionAidModalBtn = $('#open-modal-btn');

				const decisionAidClose = $('.decision-close-btn');
				const decisionAidRestart = $('.decision-restart-btn');
				const decisionAidCloseShare = $('.decision-aid-share-modal-close-icon');
				const decisionAidShareCancel = $(
					'.decision-aid-share-modal-body-button-cancel'
				);

				const modalSteps = decisionaidmodal.find('.decision-modal-step');
				const backBtns = decisionaidmodal.find('.decision-back-btn');
				const nextBtns = decisionaidmodal.find('.decision-next-btn');
				const restartBtns = decisionaidmodal.find('.decision-restart-btn');
				const shareBtn = decisionaidmodal.find('.decision-share-btn');
				const shareDefaultHeader = $('#hdnShareDefaultHeader').val();
				const shareAutoHeader = $('#hdnShareAutoHeader').val();

				let emailArticles = [];
				const radioButtons = decisionaidmodal.find('.decision-radio');
				const programContainer = $('#programContainer');
				const articles = programContainer.find('.single-result');
				const pdf = $('#pdfContainer');
				const sharePopupModal = $('#decision-aid-share-popup-modal');
				const popupTitle = $('#decision-share-modal-title');
				const emailInput = $('#decision-share-email');
				const shareButton = $('#button-share');
				const emailError = $('#emailError');
				const emailShareInterval = $('#hdnShareInterval').val() * 1000;
				const messageShareInterval = $('#hdnMessageInterval').val() * 1000;
				const emailSuccessMessage = $('#email-success-message');
				const emailErrorMessage = $('#email-error-message');
				//Get the container  styling
				const outsideContainer = $('.l-content-container');
				let currentStep = 0;
				let selectedValues = [];
				let selectedValueFlag = [];
				let openDecisionAIdSharePopup = true;
				let timeOutId = null;

				$(window).on('beforeunload', function() {
					const sectionIdToScroll = 'decision-aid-page';
					if (window.location.href.indexOf(sectionIdToScroll) > -1) {
						$('body').css('height', '');
						$('#' + sectionIdToScroll)
							.get(0)
							.scrollIntoView({
								behavior: 'auto',
								block: 'center',
								inline: 'center'
							});
					}
				});

				emailSuccessMessage.css('display', 'none');
				emailErrorMessage.css('display', 'none');

				function decisionAidOpenModal() {
					$('.emergency-banner').css('display', 'none');
					$('body, html').scrollTop(0);

					decisionaidmodal.css('display', 'flex');

					$('body').css('overflow', 'hidden');
					$('body').css('height', '100%');
					outsideContainer.addClass('decision-aid-remove-styling');
					openDecisionAIdSharePopup = true;
				}

				function openDecisionAidShareModal() {
					sharePopupModal.css('display', 'flex');
					emailInput.val('');
					emailError.css('display', 'none');
					shareButton.prop('disabled', true);
				}
				function openDecisionAidShareModalClick() {
					popupTitle.text(shareDefaultHeader);
					clearTimeout(timeOutId);
					openDecisionAIdSharePopup = false;
					openDecisionAidShareModal();
				}
				function closeDecisionAidShareModal() {
					sharePopupModal.css('display', 'none');
					emailSuccessMessage.removeClass('email-message-hide');
					emailErrorMessage.removeClass('email-message-hide');
					emailSuccessMessage.css('display', 'none');
					emailErrorMessage.css('display', 'none');
					clearTimeout(timeOutId);
					openDecisionAIdSharePopup = true;
				}

				// Function to check if a radio button is selected
				function decisionAidIsRadioButtonSelected(step) {
					const radioButtonsInStep = modalSteps
						.eq(step)
						.find('.decision-radio');
					for (let i = 0; i < radioButtonsInStep.length; i++) {
						if (radioButtonsInStep[i].checked) {
							return true;
						}
					}
					return false;
				}

				// Function to show the current step
				function decisionAidShowStep(step) {
					// Hide all the modal steps
					modalSteps.hide();

					// Show the current step
					$(modalSteps[step]).show();

					// Hide restart button on first page if no radio selected
					if (step === 0 && !decisionAidIsRadioButtonSelected(step)) {
						$(restartBtns[step]).hide();
					}

					if (step === modalSteps.length - 1) {
						$(nextBtns[step]).hide();
					}
				}

				decisionAidShowStep(currentStep);

				function decisionAidRestartStep() {
					$('.decision-radio').each(function() {
						$(this).prop('checked', false);
						$(this).parent().removeClass('question-checked');
					});

					nextBtns.prop('disabled', true);

					currentStep = 0;
					selectedValues = [];
					decisionAidShowStep(currentStep);
				}

				function decisionAidCloseModal() {
					decisionaidmodal.css('display', 'none');
					decisionAidRestartStep();
					$('body').css('overflow', 'scroll');
					$('body').css('height', 'unset');

					outsideContainer.removeClass('decision-aid-remove-styling');
					clearTimeout(timeOutId);
					$('.emergency-banner').css('display', 'block');
				}

				function decisionAidNextStep() {
					if (currentStep < modalSteps.length - 1) {
						const radioButtonsInStep = modalSteps
							.eq(currentStep)
							.find('.decision-radio');

						radioButtonsInStep.each(function() {
							if ($(this).is(':checked')) {
								selectedValues[currentStep] = $(this).val();
							}
						});
						currentStep = currentStep + 1;
						decisionAidShowStep(currentStep);
					}

					articles.css('display', 'none');

					if (currentStep === modalSteps.length - 1) {
						const filteredArticles = Array.from(articles).filter((article) =>
							selectedValues.includes(article.id)
						);

						let valueFlags = Array.from(articles).filter((article) =>
							selectedValueFlag.push(article.getAttribute('value'))
						);

						for (let i = modalSteps.length - 1; i <= articles.length - 1; i++) {
							if (selectedValueFlag[i] === '1') {
								filteredArticles.push(articles[i]);
							}
						}

						emailArticles = filteredArticles.map((item, index) => {
							const { id } = item;
							const title = $(`#${id}`).find('h4').text();
							const link = $(`#${id}`).find('a').attr('href');
							return { id: index + 1, title: title, link: link };
						});

						shareBtn.css('display', 'block');
						for (let i = 0; i <= selectedValues.length - 1; i++) {
							if (selectedValues[i].length > 4) {
								const showEl = $('.result-message');
								showEl.css('display', 'block');
								filteredArticles.forEach((article) => {
									$(article).css('display', 'block');
								});
								pdf.css('display', 'none');
								const hideEl = $('.result-message');
								hideEl.css('display', 'none');

								if (openDecisionAIdSharePopup) {
									timeOutId = setTimeout(() => {
										popupTitle.text(shareAutoHeader);
										openDecisionAidShareModal();
									}, emailShareInterval);
									openDecisionAIdSharePopup = false;
								}
								return;
							} else {
								const showEl = $('.result-message');
								showEl.css('display', 'block');
								filteredArticles.forEach((article) => {
									$(article).css('display', 'block');
								});
								pdf.css('display', 'block');
								const hideEl = $('.result-message');
								hideEl.css('display', 'none');

								if (openDecisionAIdSharePopup) {
									timeOutId = setTimeout(() => {
										popupTitle.text(shareAutoHeader);
										openDecisionAidShareModal();
									}, emailShareInterval);
									openDecisionAIdSharePopup = false;
								}

							}
						}
					}
				}

				function decisionAidPrevStep() {
					if (currentStep === 0) {
						decisionAidCloseModal();
					}
					if (currentStep === modalSteps.length - 1) {
						decisionAidRestartStep();
						clearTimeout(timeOutId);
						openDecisionAIdSharePopup = true;
					}

					if (currentStep > 0) {
						currentStep = currentStep - 1;
						decisionAidShowStep(currentStep);
					}
				}

				for (let i = 0; i < backBtns.length; i++) {
					backBtns.eq(i).on('click', decisionAidPrevStep);
				}

				for (let i = 0; i < nextBtns.length; i++) {
					nextBtns.eq(i).on('click', decisionAidNextStep);
				}

				for (let i = 0; i < radioButtons.length; i++) {
					radioButtons.eq(i).on('click', function(e) {
						const step = e.target.closest('.decision-modal-step');
						const buttons = $(step).find('.decision-radio');

						buttons.each(function() {
							$(this).parent().removeClass('question-checked');
						});
						$(e.target).parent().addClass('question-checked');
						if (decisionAidIsRadioButtonSelected(currentStep)) {
							nextBtns.eq(currentStep).prop('disabled', false);
							restartBtns.eq(0).css('display', 'block');
						} else {
							nextBtns.eq(currentStep).prop('disabled', true);
						}
					});
				}

				function isValidEmail(email) {
					const emailREgex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
					return emailREgex.test(email);
				}

				emailInput.on('input', function() {
					const emailValue = emailInput.val();
					if (isValidEmail(emailValue)) {
						emailError.css('display', 'none');
						shareButton.prop('disabled', false);
					} else {
						emailError.css('display', 'none');
						shareButton.prop('disabled', true);
					}
				});
				emailInput.on('blur', function() {
					const emailValue = emailInput.val();
					if (!isValidEmail(emailValue)) {
						emailError.css('display', 'inline');
					}
				});

				function hideSuccessErrorMessageDiv() {
					setTimeout(() => {
						emailSuccessMessage.addClass('email-message-hide');
						emailErrorMessage.addClass('email-message-hide');
					}, messageShareInterval - 1000);
					setTimeout(() => {
						emailSuccessMessage.css('display', 'none');
						emailErrorMessage.css('display', 'none');
					}, messageShareInterval);
				}

				function shareEmail(apiData) {
					var fbEmailShareUrl =
						window.location.origin + sharePopupModal.attr('data-url');
					$.ajax({
						url: fbEmailShareUrl,
						type: 'POST',
						data: JSON.stringify(apiData),
						contentType: 'application/json',
						success: function(result) {
							if (result.status === 'OK') {
								emailSuccessMessage.css('display', 'flex');
								hideSuccessErrorMessageDiv();
							} else {
								emailErrorMessage.css('display', 'flex');
								hideSuccessErrorMessageDiv();
							}
						},
						error: function(error) {
							console.error('Error:', error);
							emailErrorMessage.css('display', 'flex');
							hideSuccessErrorMessageDiv();
						}
					});
				}

				function decisionAidShareClick() {
					const emailValue = emailInput.val();
					const apiData = { email: emailValue, programs: emailArticles };
					shareEmail(apiData);
					closeDecisionAidShareModal();
				}

				openDecisionAidModalBtn.on('click', decisionAidOpenModal);

				decisionAidClose.on('click', decisionAidCloseModal);

				decisionAidRestart.on('click', decisionAidRestartStep);

				shareBtn.on('click', openDecisionAidShareModalClick);
				decisionAidCloseShare.on('click', closeDecisionAidShareModal);

				decisionAidShareCancel.on('click', closeDecisionAidShareModal);

				shareButton.on('click', decisionAidShareClick);
			});
		};
		return {
			init: init
		};
	})();
})(DDIGITAL, jQuery);
