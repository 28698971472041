// ==========================================================================
// ANALYTICS - SI PORTAL D1
// ==========================================================================

(function(NAMESPACE) {

	'use strict';

	NAMESPACE.analyticsPortal = (function() {

		var init,
			dataLayer = window.dataLayer = window.dataLayer || [],
			_pushUserId,
			_pushHeaderNavEvent,
			_pushNewsAnnouncementsCampaignEvent,
			_pushReportsEvent,
			_pushQuickLinksEvent,
			_pushDasboardMainNavEvent,
			_pushDashboardReportLinksEvent;

		_pushUserId = function() {
			// The User ID is stored in a hidden input field added by BED
			var uidElement = document.querySelector('#uid');

			if (uidElement) {
				dataLayer.push({
					'&uid': uidElement.value
				});

				uidElement.parentNode.removeChild(uidElement);
			} else {
				return;
			}
		};

		_pushHeaderNavEvent = function(event) {
			var target = event.target,
				menuItem;

			if (target && !target.classList.contains('language-option')) {
				menuItem = target.innerText;

				dataLayer.push({
					event : 'Selected Header Nav',
					action: 'Click',
					headernav: menuItem
				});
			}
		};

		_pushNewsAnnouncementsCampaignEvent = function(event) {
			var contentTile = event.currentTarget,
				newsArticle;

			if (contentTile) {
				newsArticle = contentTile.querySelector('h2');

				dataLayer.push({
					event: 'News Announcement Header',
					action: 'Click',
					newstitle: newsArticle.innerText
				});
			}
		};
		// Markup change between D1 and D2. This version is left unchanged to account for the D1 version.
		_pushReportsEvent = function(event) {
			var reportTile = event.currentTarget,
				classes,
				linkName;
			// Analytics for claims tab is handled in the React module.
			if (!reportTile.classList.contains('dashboard-claims-link')) {
				classes = reportTile.className;
				linkName = classes.slice(10, classes.length);

				dataLayer.push({
					event: 'Reports',
					action: 'Click',
					clickreports: linkName
				});
			}
		};

		_pushQuickLinksEvent = function(event) {
			var linkTitle = event.currentTarget;

			if (linkTitle) {
				dataLayer.push({
					event: 'Quick Links',
					action: 'Click',
					clickquicklinks: linkTitle.innerText
				});
			}
		};

		_pushDasboardMainNavEvent = function(event) {
			var navItemTitle = event.target.innerText;

			if (navItemTitle) {
				dataLayer.push({
					event: 'Dasboard Main Navigation',
					action: 'Click',
					dashmainnav_button: navItemTitle
				});
			}
		};

		_pushDashboardReportLinksEvent = function(event) {
			var reportsTabLinkTitle = event.currentTarget.querySelector('.content strong').innerText;

			if (reportsTabLinkTitle) {
				dataLayer.push({
					event: 'Reports Tab',
					action: 'Click',
					reportstab_button: reportsTabLinkTitle
				});
			}
		};

		init = function() {
			var headerElement = document.querySelector('header.is-portal'),
				headerLinks = document.querySelectorAll('.header-links li'),
				newsStory = document.querySelectorAll('.cm-content-tile'),
				tabsContainer = document.querySelector('.tabs-container-portal'),
				quickLinks = document.querySelectorAll('.quick-links li'),
				dashboardMainNavLinks = document.querySelectorAll('.cm-hero-banner-quick-links .quick-links a'),
				dashboardReportsTabLinks = document.querySelectorAll('.tabs-container-portal .cm-icon-link-tiles a:not(.dashboard-claims-link)');

			// Tracks the User ID
			if (headerElement) {
				window.addEventListener('load', _pushUserId, true);
			}

			// Tracks header nav clicks by users
			// (eg. Language, News & Announcement, Accessibility, Contact Us)
			if (headerLinks) {
				for (var i = 0; i < headerLinks.length; i++) {
					headerLinks[i].addEventListener('click', _pushHeaderNavEvent, true);
				}
			}

			// Tracks what news/announcement users clicked on the portal home page
			// (eg. Title of news 1, Title of news 2, Title of news 3, See all news)
			if (newsStory) {
				for (var i = 0; i < newsStory.length; i++) {
					newsStory[i].addEventListener('click', _pushNewsAnnouncementsCampaignEvent, true);
				}
			}

			// Tracks what reports users clicked on the portal home page - claims and reports
			// (eg. Liability, Motor Vehicle, Property, See all reports)
			if (tabsContainer) {
				var tabs = document.querySelectorAll('.tabs-nav li button'),
					activeTab = document.querySelector('.tabs-nav li.is-active button'),
					linkItems;

				// add listener to override default active tab and link items
				if (tabs.length > 1) {
					for (var i = 0; i < tabs.length; i++) {
						tabs[i].addEventListener('click', function(event) {
							// ddTabs uses velocity animation to switch tab content
							// The 'is-active' has a 'velocity-animating' state on the previous 'is-active' tab
							// before updating the 'is-active tab hence the code below
							if (activeTab === event.currentTarget) {
								linkItems = document.querySelectorAll('.tabs .tab.is-active .link-item');
							} else {
								linkItems = document.querySelectorAll('.tabs .tab.is-hidden .link-item');
							}

							activeTab = event.currentTarget;

							if (linkItems) {
								for (var i = 0; i < linkItems.length; i++) {
									linkItems[i].addEventListener('click', _pushReportsEvent, true);
								}
							}

							dataLayer.push({
								event: 'D3 Dashboard Tabs',
								d3_dashboard_tabs : event.target.innerText
							});
						});
					}
				} else {
					activeTab = document.querySelector('.tab.is-active'),
						linkItems = activeTab.querySelectorAll('.link-item');

					if (linkItems) {
						for (var i = 0; i < linkItems.length; i++) {
							linkItems[i].addEventListener('click', _pushReportsEvent, true);
						}
					}
				}
			}

			// Tracks what reports users clicked on the portal home page
			// (eg. Liability, Motor Vehicle, Property, See all reports)
			if (quickLinks) {
				for (var i = 0; i < quickLinks.length; i++) {
					quickLinks[i].addEventListener('click', _pushQuickLinksEvent, true);
				}
			}

			// Tracks which hero banner quick link the user clicked on the portal home page
			// eg Dynamic Reporting, Support Desk, Certificate of Currency or Desk
			if (dashboardMainNavLinks) {
				for (var i = 0; i < dashboardMainNavLinks.length; i++) {
					dashboardMainNavLinks[i].addEventListener('click', _pushDasboardMainNavEvent, true);
				}
			}

			// Tracks which link in the dashboard reports tab the user clicked on the portal home page
			// eg Liability, Property, Vehicle, Workers Compensation, Miscellaneous Cover
			if (dashboardReportsTabLinks) {
				for (var i = 0; i < dashboardReportsTabLinks.length; i++) {
					dashboardReportsTabLinks[i].addEventListener('click', _pushDashboardReportLinksEvent, true);
				}
			}
		};

		return {
			init: init
		};
	}());

}(DDIGITAL, jQuery));
