/* ==========================================================================
 * HORIZONTAL FILTER BAR
 *
 * As of the time of this writing, the horizontal filter bar is visually
 * presented as a tabbed interface on a desktop computer. It is presented as a
 * dialog on a mobile device. However, the horizontal filter bar is operated as
 * a dialog on both views. This is for accessibility purposes. The following
 * piece of code is about the implementation of the horizontal filter bar that
 * includes two dialogs.
 * In order to keep the markup the same for both desktop and mobile, this module
 * was written - see _cm-horizontal-search-filter-with-modal.erb
 * ========================================================================== */

(function(NAMESPACE, $) {

	'use strict';

	NAMESPACE.horizontalFilterBarModal = (function() {
		var SELECTORS,

			// ------ Private functions
			_toggleDialog,
			_closeDialog,
			_toggleTabPanel,
			_convertCheckboxToRadioButton,
			_focusWithin,
			_modalLock,
			init,
			container;

		SELECTORS = {
			CONTAINER: '.cm-horizontal-filter-bar',
			DIALOG: '.cm-horizontal-filter-bar .dialog',
			ACCESSIBLE_MODAL: '.accessible-modal',
			MODAL_LOCKED: 'modal-locked'
		};

		/** Open and close a dialog when appropriate.
		 * @param {MouseEvent} event
		 * @listens MouseEvent.type === 'click'
		 */
		_toggleDialog = function(event) {
			var target = event.target,
				button,
				descendant,
				dialog,
				dialog2,
				ID;

			// Let `target` be the target of this event.
			// If `target` is a button that may open a dialog.
			if (target.classList.contains('dialog-opener')) {
				// Let `button` be `target`.
				button = target;
				// Let `dialog` be the dialog that `button` may open.
				ID = button.getAttribute('aria-controls');
				dialog = document.querySelector('#' + ID);

				// If `dialog` is flagged as 'closed'.
				if (!dialog.classList.contains('open')) {
					// If another dialog is already flagged as 'open'.
					dialog2 = container.querySelector('.open');

					if (dialog2) {
						// Let `dialog2` be that dialog.
						// Flag `dialog2` as 'closed'.
						dialog2.classList.remove('open');
						dialog2.previousElementSibling.setAttribute('aria-expanded', false);

						// Stop monitoring clicks ocurring within `dialog2`.
						dialog2.removeEventListener('click', _closeDialog, true);
						dialog2.removeEventListener('click', _toggleTabPanel, true);
					}

					// Flag `dialog` as 'open'.
					dialog.classList.add('open');
					button.setAttribute('aria-expanded', true);

					// Start animation of `dialog`.
					requestAnimationFrame(function() {
						dialog.classList.add('is-expanded');
					});

					// Set focus to `dialog`'s first focusable descendant.
					descendant = dialog.querySelector('button, input, select');
					descendant.focus();

					// Start monitoring clicks ocurring within `dialog`.
					dialog.addEventListener('click', _closeDialog, true);
					dialog.addEventListener('click', _toggleTabPanel, true);
				} else {
					// Otherwise if `dialog` is flagged as 'open'.
					// Flag `dialog` as 'closed'.
					dialog.classList.remove('open');
					button.setAttribute('aria-expanded', false);

					// Stop monitoring clicks ocurring within `dialog`.
					dialog.removeEventListener('click', _closeDialog, true);
					dialog.removeEventListener('click', _toggleTabPanel, true);

					// Set focus to `button`.
					button.focus();
				}
			}
		};

		/** Close a dialog.
		 * @param {MouseEvent} event
		 * @listens MouseEvent.type === 'click'
		 */
		_closeDialog = function(event) {
			// Let `target` be the target of this event.
			var target = event.target,
				button, dialog, ID;
			// If `target` may be used to close a dialog.
			if (target.classList.contains('dialog-closer')) {
				// Let `button` be `target`.
				button = target;
				// Let `dialog` be the dialog `button` is associated with.
				dialog = button.parentElement.parentElement;
				// Flag `dialog` as 'closed'.
				dialog.classList.remove('open');
				dialog.previousElementSibling.setAttribute('aria-expanded', false);

				// Set focus to the button that may be used to open `dialog`.
				ID = dialog.id;
				document.querySelector('[aria-controls=' + ID + ']').focus();
			}
		};

		/*
		* This function converts checkbox functionality to act like radio  button
		* */
		_convertCheckboxToRadioButton = function() {
			$('input[name^="search-facet-location-"]').on('click', function(event) {
				var target = event.target;
				var selectedCheckbox = $(target).attr('name');
				var checkboxUl = $(target).parentsUntil('ul').parent();
				checkboxUl.find('input[name!="' + selectedCheckbox + '"]').each(function(i, item) {
					$(item).attr('checked', false);
				});
			});
		};

		/** Open or close a tab panel when appropriate.
		 * @param {MouseEvent} event
		 * @listens MouseEvent.type === 'click'
		 */
		_toggleTabPanel = function(event) {
			// Let `target` be the target of this event.
			var target = event.target,
				tab, isExpanded;

			// If `target` is a tab.
			if (target.classList.contains('tab-mobile')) {
				// Let `tab` be `target`.
				tab = target;
				isExpanded = tab.getAttribute('aria-expanded');

				// If the tab panel associated with `tab` is flagged as 'open'.
				if (isExpanded === 'true') {
					// Flag that tab panel as 'closed'.
					tab.setAttribute('aria-expanded', false);
					tab.nextElementSibling.classList.remove('is-expanded');
					// Otherwise, if the tab panel is flagged as 'closed'.
				} else {
					// Flag that tab panel as 'open'.
					tab.setAttribute('aria-expanded', true);

					// Start animation of that tab panel.
					requestAnimationFrame(function() {
						tab.nextElementSibling.classList.add('is-expanded');
					});
				}
			}
		};

		/** Keep tab navigation within modal.
		 * @param {KeyEvent} event
		 * @listens KeyEvent.type === 'keydown'
		 */
		_focusWithin = function(event) {
			if (event.keyCode === 9) {
				var modal = $(event.currentTarget);
				if (modal.hasClass(SELECTORS.MODAL_LOCKED)) {
					var focusable = modal.find(':focusable'),
						first = focusable.first(),
						last = focusable.last(),
						focused = $(event.target);

					if (focused.is(first) && event.shiftKey) {
						event.preventDefault();
						last.focus();
					} else if (focused.is(last) && !event.shiftKey) {
						event.preventDefault();
						first.focus();
					}
				}
			}
		};

		/** Add modal lock class for mobile breakpoint.
		 */
		_modalLock = function(dialog) {
			var isPopup = window.matchMedia('screen and (max-width: 64em)').matches;
			dialog.each(function(i, elem) {
				if (isPopup) {
					$(elem).addClass(SELECTORS.MODAL_LOCKED);
				} else {
					$(elem).removeClass(SELECTORS.MODAL_LOCKED);
				}
			});
		};

		init = function() {
			// Let 'container' be the horizontal filter bar.
			container = document.querySelector(SELECTORS.CONTAINER);

			if (container) {
				// Start monitoring clicks.
				//Expand on click of specifit links
				$('#button-2-tab').click(function(event) {
					event.preventDefault();
					var ID = $(this).attr('id').replace('-tab', '');
					$('#' + ID).focus().click();
				});
				$('#button-0-tab').click(function(event) {
					event.preventDefault();
					var ID = $(this).attr('id').replace('-tab', '');
					$('#' + ID).focus().click();
				});
				container.addEventListener('click', _toggleDialog, true);
				_convertCheckboxToRadioButton();
			}

			var modals = $(SELECTORS.ACCESSIBLE_MODAL),
				dialog = $(SELECTORS.DIALOG);

			if (modals.length) {
				modals.each(function(i, elem) {
					$(elem).on('keydown', _focusWithin);
				});
			}

			if (dialog.length) {
				_modalLock(dialog);
				$(window).resize(function() {
					_modalLock(dialog);
				});
			}

		};

		return {
			init: init
		};
	}());

}(DDIGITAL, jQuery));
