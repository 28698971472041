$(window).load(function() {
	if (IS_QLIK_PAGE) {
		var script = document.createElement('script');
		script.onload = function() {
			var qlikScript = document.createElement('script');

			if (window.location.hostname === 'www.icare.nsw.gov.au') {
				qlikScript.src =  ASSETS_PREFIX + 'js/script-qlik.js';
			} else {
				qlikScript.src =  ASSETS_PREFIX + 'js/script-qlik-dev.js';
			}
			document.body.append(qlikScript);
		};
		if (window.location.hostname === 'www.icare.nsw.gov.au') {
			script.src = 'https://icare-qlkprod.icare.nsw.gov.au/resources/assets/external/requirejs/require.js';
		} else {
			script.src = 'https://dataqap01asi1ic.ic.nsw.gov.au/resources/assets/external/requirejs/require.js';
		}
		document.body.append(script);
	}
});
