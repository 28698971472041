// ==========================================================================
// AUTOCOMPLETE
// ==========================================================================

(function(NAMESPACE, $) {

	'use strict';

	var trapFocus = function(elem) {
		var tabbable = elem.find('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');
		var firstTabbable = tabbable.first();
		var lastTabbable = tabbable.last();
		firstTabbable.focus();
		lastTabbable.on('keydown', function(e) {
			if ((e.which === 9 && !e.shiftKey)) {
				e.preventDefault();
				firstTabbable.focus();
			}
		});
		firstTabbable.on('keydown', function(e) {
			if ((e.which === 9 && e.shiftKey)) {
				e.preventDefault();
				lastTabbable.focus();
			}
		});
	};

	NAMESPACE.mobileMultilingualModal = (function() {
		var init,
			SELECTORS;

		SELECTORS = {
            ONSCREEN_NAV: '.nav-toggle',
            LANGUAGE_SELECT_ELEMENT: 'select.goog-te-combo',
			MODAL: 'modal-multilingual-disclaimer'
        };

		init = function() {
            $(SELECTORS.ONSCREEN_NAV).on('click', function() {
                $(SELECTORS.LANGUAGE_SELECT_ELEMENT).on('change', function() {
					NAMESPACE.modal.open(SELECTORS.MODAL);
					trapFocus($('#' + SELECTORS.MODAL));
				});
            });
		};

		return {
			init: init
		};

	}());

}(DDIGITAL, jQuery));
