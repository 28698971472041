/* eslint-disable no-undef */
window.DDIGITAL = window.DDIGITAL || {};

// ==========================================================================
// Customer Feedback
// ==========================================================================

(function(NAMESPACE, $) {
    'use strict';

    NAMESPACE.customerFeedback = (function() {
        var SELECTORS, init;

        SELECTORS = {
            CUSTOMER_FEEDBACK_MODAL: '#customer-feedback-modal',
            MODAL_FORM: '#feedback-modal',
            MODAL_FEEDBACK: '#feedback-form-modal',
            MODAL_FEEDBACK_CLOSE: '#feedback-thank-page',
            SUBMIT_BTN: '.feedback-modal-button',
            FEEDBACK_RADIO: '.feedbackForm-modal',
            CUSTOMER_FEEDBACK_SESSION_VISIT: '#hdnFeedbackSession',
            TIME_INTERVAL: '#hdnFeedbackInterval',
            LEAVING_POPUP_MODAL: '#leaving-popup-modal',
            LEAVING_RADIO_BTNS: '#leaving-popup-modal .leaving-popup-radio',
            LEAVING_SUBMIT_BTN: '#leaving-popup-modal .leaving-popup-btn',
            SHOW_LEAVING_MESSAGE: '.leaving-popup-show-message',
            LEAVING_SESSION_ID: '#hdnLeavingSession',
            LEAVING_MESSAGE_TIME_INTERVAL: '#hdnLeavingPopupMessageInterval',
            PAGE_COVER_DEPTH: '#hdnLeavingPopupDepth',
            LEAVING_IDLE_THRESHOLD: '#hdnLeavingPopupInterval',
            ANSWER_1: '#answer1',
            ANSWER_2: '#answer2',
            ANSWER_3: '#answer3',
            BODY: 'body',
            SELECT_ELEMENT: '.feedback-modal-body'
        };

        let customerfeedbackmodaldisplay = false;
        let customerfeedbackhidePopup = localStorage.getItem('cfhidePopup');
        const cflocalStorageVisitId = localStorage.getItem('rxvisitid');
        let cfsessionVisitId = null;
        let showCfThankYou = false;
        let pageHtml = document.documentElement;
        let pageHeight = document.body.scrollHeight;
        let mouseDepthFlag = 0;
        let timeInterval = null;
        let leavingMessageTimeInterval = null;
        let leavingDepth = null;
        let leavingIdleThreshold = null;
        let hideLeavingPopup = localStorage.getItem('hideLeavingPopup');
        const leavingLocalStorageVisitId =
            localStorage.getItem('leavingrxvisitid');
        let leavingSessionVisitId = null;
        let showFeedbackQuestion = 0;
        let cfTimeoutId = 0;
        let leavingMessageTimeoutId = 2;
        let cfPopupId = 3;
        let leavingPopupSubmit = 0;
        let leavingIdleTime = 0;
        let leavingEvents = [
            'mouseup',
            'mousemove',
            'mousedown',
            'keydown',
            'scroll',
            'click'
        ];
        let leavingIdleInterval = 0;
        let required1 = false;
        let required2 = false;
        let required3 = false;
        let answer1Val;
        let answer2Val;
        let answer3Val;

        const checkShowFeedbackQuestions = () => {
            showFeedbackQuestion =
                $(SELECTORS.MODAL_FORM).length !== 0 &&
                $(SELECTORS.MODAL_FORM).css('display') === 'flex' &&
                $(SELECTORS.MODAL_FEEDBACK_CLOSE).length !== 0 &&
                $(SELECTORS.MODAL_FEEDBACK_CLOSE).css('display') !== 'block';
            return showFeedbackQuestion;
        };

        const handleResize = () => {
            pageHeight = document.documentElement.scrollHeight;
        };

        const handleMouseMove = (e) => {
            const target = e.target;
            const rect = target.getBoundingClientRect();

            const mousePageX = e.pageX;
            const mousePageY = e.pageY;

            const mouseCoverRatio =
                mousePageY / document.documentElement.scrollHeight;
            if (mouseCoverRatio >= leavingDepth) {
                mouseDepthFlag = 1;
            }
        };

        const resetLeavingIdleTime = () => {
            leavingIdleTime = 0;
        };

        function openLeavingPopupModal() {
            $(SELECTORS.MODAL_FORM).length !== 0
                ? $(SELECTORS.MODAL_FORM).css('visibility', 'hidden')
                : null;
            $(SELECTORS.SHOW_LEAVING_MESSAGE).removeClass(
                'leaving-popup-message-hide'
            );
            $(SELECTORS.LEAVING_POPUP_MODAL).css('display', 'flex');
            pageHtml.removeEventListener('mousemove', handleMouseMove, false);
            window.removeEventListener('resize', handleResize);
            // eslint-disable-next-line no-use-before-define
            pageHtml.removeEventListener('mouseleave', handleMouseLeave);
            leavingEvents.forEach(function(e) {
                document.removeEventListener(e, resetLeavingIdleTime);
            });
            $(SELECTORS.BODY).attr('aria-hidden', 'true');
            $(SELECTORS.LEAVING_POPUP_MODAL).attr('aria-hidden', 'false');
            if ($(SELECTORS.CUSTOMER_FEEDBACK_MODAL).length !== 0) {
                $(SELECTORS.CUSTOMER_FEEDBACK_MODAL).css(
                    'visibility',
                    'hidden'
                );
            }
            clearInterval(leavingIdleInterval);
        }

        const handleMouseLeave = () => {
            if (
                $(SELECTORS.LEAVING_POPUP_MODAL).length !== 0 &&
                mouseDepthFlag &&
                hideLeavingPopup === null &&
                !checkShowFeedbackQuestions()
            ) {
                openLeavingPopupModal();
            }
        };

        const leavingTimerIncrement = () => {
            if (!checkShowFeedbackQuestions()) {
                leavingIdleTime = leavingIdleTime + 1;
                if (leavingIdleTime >= leavingIdleThreshold) {
                    openLeavingPopupModal();
                    clearInterval(leavingIdleInterval);
                }
            }
        };

        function setLeavingPopupLocalStorage() {
            localStorage.setItem('hideLeavingPopup', true);
            if (leavingSessionVisitId) {
                localStorage.setItem('leavingrxvisitid', leavingSessionVisitId);
            }
        }

        function closeLeavingPopupModal(val) {
            if (val !== 1) {
                dataLayer.push({
                    event: 'exitwithout_feedback',
                    nofeedback: 'nofeedback'
                });
            }
            $(SELECTORS.LEAVING_POPUP_MODAL).css('display', 'none');
            if (!showCfThankYou && $(SELECTORS.MODAL_FORM).length !== 0) {
                $(SELECTORS.MODAL_FORM).css('visibility', 'visible');
            }
            if (leavingPopupSubmit) {
                cfPopupId = setTimeout(() => {
                    $(SELECTORS.CUSTOMER_FEEDBACK_MODAL).length !== 0
                        ? $(SELECTORS.CUSTOMER_FEEDBACK_MODAL).css(
                              'visibility',
                              'visible'
                          )
                        : null;
                }, leavingMessageTimeInterval);
            } else {
                $(SELECTORS.CUSTOMER_FEEDBACK_MODAL).length !== 0
                    ? $(SELECTORS.CUSTOMER_FEEDBACK_MODAL).css(
                          'visibility',
                          'visible'
                      )
                    : null;
            }

            $(SELECTORS.BODY).attr('aria-hidden', 'false');
            $(SELECTORS.LEAVING_POPUP_MODAL).attr('aria-hidden', 'true');
            setLeavingPopupLocalStorage();
            clearTimeout(leavingMessageTimeoutId);
            clearInterval(leavingIdleInterval);
        }

        function submitLeavingPopupFeedback() {
            leavingPopupSubmit = 1;
            closeLeavingPopupModal(1);
            $(SELECTORS.SHOW_LEAVING_MESSAGE).css('display', 'flex');
            leavingMessageTimeoutId = setTimeout(() => {
                $(SELECTORS.SHOW_LEAVING_MESSAGE).addClass(
                    'leaving-popup-message-hide'
                );
            }, leavingMessageTimeInterval);

            dataLayer.push({
                event: 'exit_feedback',
                feedbackoption: document.querySelector(
                    'input[name="leavingUserOptions"]:checked'
                ).value
            });
        }

        function feedbackDisplaySubmit() {
            $(SELECTORS.SUBMIT_BTN).first().prop('disabled', false);
        }

        function setLocalStorage() {
            localStorage.setItem('cfhidePopup', true);
            if (cfsessionVisitId) {
                localStorage.setItem('rxvisitid', cfsessionVisitId);
            }
        }

        function openCustomerFeedbackModal() {
            if ($(SELECTORS.CUSTOMER_FEEDBACK_MODAL).length !== 0) {
                $(SELECTORS.CUSTOMER_FEEDBACK_MODAL).css('display', 'flex');
                $(SELECTORS.BODY).attr('aria-hidden', 'true');
                $(SELECTORS.CUSTOMER_FEEDBACK_MODAL).attr(
                    'aria-hidden',
                    'false'
                );
                $(SELECTORS.MODAL_FORM).length !== 0
                    ? $(SELECTORS.MODAL_FORM).css('visibility', 'visible')
                    : null;
            }
        }

        function shareYourFeedback() {
            if ($(SELECTORS.CUSTOMER_FEEDBACK_MODAL).length !== 0) {
                $(SELECTORS.CUSTOMER_FEEDBACK_MODAL).css('display', 'none');
                $(SELECTORS.MODAL_FORM).css('display', 'flex');
            }
        }

        function closeCustomerFeedbackModal(n) {
            if ($(SELECTORS.CUSTOMER_FEEDBACK_MODAL).length !== 0) {
                $(SELECTORS.CUSTOMER_FEEDBACK_MODAL).css('display', 'none');
                $(SELECTORS.MODAL_FORM).css('display', 'none');
                $(SELECTORS.BODY).attr('aria-hidden', 'false');
                $(SELECTORS.CUSTOMER_FEEDBACK_MODAL).attr(
                    'aria-hidden',
                    'true'
                );
                setLocalStorage();
            }
        }

        function feedbackmodalrating(element) {
            var $element = $(element);
            var parent = $element.parent();
            var ratingButtons = parent.find('.feedback-modal-rating-button');
            ratingButtons.each(function() {
                $(this).removeClass('rating-button');
            });

            $element.addClass('rating-button');

            if (parent.attr('id') === 'answer1') {
                answer1Val = $element.val();
            }
            if (parent.attr('id') === 'answer2') {
                answer2Val = $element.val();
            }
            if (parent.attr('id') === 'answer3') {
                answer3Val = $element.val();
            }
        }

        function validateForm(event) {
            event.preventDefault();
            $(SELECTORS.MODAL_FEEDBACK).css('display', 'none');
            $(SELECTORS.MODAL_FEEDBACK_CLOSE).css('display', 'block');
            showCfThankYou = true;
            setLocalStorage();
            dataLayer.push({
                event: 'customerfeedbackdata',
                customerselection:
                    answer1Val + ' - ' + answer2Val + ' - ' + answer3Val
            });
        }

        const excutePopup = () => {
            window.submitLeavingPopupFeedback = submitLeavingPopupFeedback;
            window.feedbackmodalrating = feedbackmodalrating;
            window.validateForm = validateForm;
            window.shareYourFeedback = shareYourFeedback;
            window.closeCustomerFeedbackModal = closeCustomerFeedbackModal;
            window.closeLeavingPopupModal = closeLeavingPopupModal;

            timeInterval = $(SELECTORS.TIME_INTERVAL).val() * 1000 || 3000;
            leavingMessageTimeInterval =
                $(SELECTORS.LEAVING_MESSAGE_TIME_INTERVAL).val() * 1000 || 3000;
            leavingDepth =
                parseFloat($(SELECTORS.PAGE_COVER_DEPTH).val()) || 0.9;
            leavingIdleThreshold =
                $(SELECTORS.LEAVING_IDLE_THRESHOLD).val() * 1000 || 90 * 1000;
            leavingSessionVisitId =
                $(SELECTORS.LEAVING_SESSION_ID) &&
                $(SELECTORS.LEAVING_SESSION_ID).val();
            cfsessionVisitId = $(
                SELECTORS.CUSTOMER_FEEDBACK_SESSION_VISIT
            ).val();

            if (
                $(SELECTORS.LEAVING_POPUP_MODAL).length !== 0 &&
                !hideLeavingPopup
            ) {
                window.addEventListener('load', function() {
                    leavingEvents.forEach(function(e) {
                        document.addEventListener(e, resetLeavingIdleTime);
                    });
                });

                leavingIdleInterval = setInterval(leavingTimerIncrement, 1000);
            }
            if (
                $(SELECTORS.LEAVING_POPUP_MODAL).length !== 0 &&
                !hideLeavingPopup
            ) {
                pageHtml.addEventListener('mouseleave', handleMouseLeave);
                window.addEventListener('resize', handleResize);
            }
            pageHtml.addEventListener('mousemove', handleMouseMove);

            if ($(SELECTORS.LEAVING_RADIO_BTNS).length !== 0) {
                $(SELECTORS.LEAVING_RADIO_BTNS).each(function() {
                    $(this).click(function(e) {
                        e.target.parentElement.classList.add(
                            'question-checked'
                        );
                        $(SELECTORS.LEAVING_SUBMIT_BTN)
                            .first()
                            .prop('disabled', false);
                    });
                });
            }

            if (
                $(SELECTORS.ANSWER_1).length !== 0 &&
                $(SELECTORS.ANSWER_1).attr('data-required') === 'required'
            ) {
                required1 = true;
            }
            if (
                $(SELECTORS.ANSWER_2).length !== 0 &&
                $(SELECTORS.ANSWER_2).attr('data-required') === 'required'
            ) {
                required2 = true;
            }
            if (
                $(SELECTORS.ANSWER_3).length !== 0 &&
                $(SELECTORS.ANSWER_3).attr('data-required') === 'required'
            ) {
                required3 = true;
            }

            if (
                !required1 &&
                !required2 &&
                !required3 &&
                $(SELECTORS.SUBMIT_BTN).first().length !== 0
            ) {
                $(SELECTORS.SUBMIT_BTN).first().prop('disabled', false);
            }

            $(SELECTORS.SELECT_ELEMENT).each(function() {
                $(this).click(function handleClick(event) {
                    var question1 = document.forms['feedbackForm']['answer1'];
                    var question2 = document.forms['feedbackForm']['answer2'];
                    var question3 = document.forms['feedbackForm']['answer3'];

                    if (answer1Val === '' || answer1Val === undefined) {
                        answer1Val =
                            question1 != null || question1 !== undefined
                                ? question1.value
                                : '';
                    }
                    if (answer2Val === '' || answer2Val === undefined) {
                        answer2Val =
                            question2 != null || question2 !== undefined
                                ? question2.value
                                : '';
                    }
                    if (answer3Val === '' || answer3Val === undefined) {
                        answer3Val =
                            question3 != null || question3 !== undefined
                                ? question3.value
                                : '';
                    }

                    if (required1) {
                        if (required2) {
                            if (required3) {
                                $(SELECTORS.SUBMIT_BTN)
                                    .first()
                                    .prop(
                                        'disabled',
                                        !(
                                            answer1Val &&
                                            answer2Val &&
                                            answer3Val
                                        )
                                    );
                            } else {
                                $(SELECTORS.SUBMIT_BTN)
                                    .first()
                                    .prop(
                                        'disabled',
                                        !(answer1Val && answer2Val)
                                    );
                            }
                        } else {
                            if (required3) {
                                $(SELECTORS.SUBMIT_BTN)
                                    .first()
                                    .prop(
                                        'disabled',
                                        !(answer1Val && answer3Val)
                                    );
                            } else {
                                $(SELECTORS.SUBMIT_BTN)
                                    .first()
                                    .prop('disabled', !answer1Val);
                            }
                        }
                    } else {
                        if (required2) {
                            if (required3) {
                                $(SELECTORS.SUBMIT_BTN)
                                    .first()
                                    .prop(
                                        'disabled',
                                        !(answer2Val && answer3Val)
                                    );
                            } else {
                                $(SELECTORS.SUBMIT_BTN)
                                    .first()
                                    .prop('disabled', !answer2Val);
                            }
                        } else {
                            if (required3) {
                                $(SELECTORS.SUBMIT_BTN)
                                    .first()
                                    .prop('disabled', !answer3Val);
                            } else {
                                $(SELECTORS.SUBMIT_BTN)
                                    .first()
                                    .prop('disabled', true);
                            }
                        }
                    }
                });
            });

            $(SELECTORS.FEEDBACK_RADIO).each(function() {
                $(this).click(feedbackDisplaySubmit);
            });

            if (customerfeedbackhidePopup) {
                if (cflocalStorageVisitId !== cfsessionVisitId) {
                    localStorage.removeItem('cfhidePopup');
                    localStorage.removeItem('rxvisitid');
                    customerfeedbackhidePopup = false;
                }
            }

            if (!customerfeedbackhidePopup) {
                cfTimeoutId = setTimeout(function() {
                    if (!customerfeedbackmodaldisplay) {
                        openCustomerFeedbackModal();
                        customerfeedbackmodaldisplay = true;
                    }
                }, timeInterval);
            }

            if (
                $(SELECTORS.LEAVING_POPUP_MODAL).length !== 0 &&
                hideLeavingPopup
            ) {
                if (leavingLocalStorageVisitId !== leavingSessionVisitId) {
                    localStorage.removeItem('hideLeavingPopup');
                    localStorage.removeItem('leavingrxvisitid');
                    hideLeavingPopup = false;
                }
            }
        };

        init = function() {
            $(document).ready(function($) {
                excutePopup();
            });
        };

        return {
            init: init
        };
    })();
})(DDIGITAL, jQuery);
