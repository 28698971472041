// ==========================================================================
// MODAL WINDOWS
// ==========================================================================

(function(NAMESPACE, $) {

	'use strict';

	NAMESPACE.modal = (function() {
		var _addDynamicModalConfiguration,
			openModal,
			closeModal,
			isModalOpen,
			init;

		_addDynamicModalConfiguration = function() {
			// DEMO Page example
			$.ddModals.dynamicModal.addType('modal-dynamic-example', {
				mq: '0,s',
				type: 'modal-dynamic-example',
				callback: function($modal) {
					NAMESPACE.expandCollapse.reset($modal, '-inmodal');
				}
			});

			// SEARCH/COLLETION Pages for DSP
			$.ddModals.dynamicModal.addType('modal-collection-header-sort', {
				mq: '0,xl',
				type: 'modal-collection-header-sort',
				callback: function() {}
			});

			// SEARCH/COLLETION Pages for DSP
			$.ddModals.dynamicModal.addType('modal-search-facets', {
				mq: '0,s',
				type: 'modal-search-facets',
				callback: function($modal) {
					NAMESPACE.expandCollapse.reset($modal, '-inmodal');
				}
			});
		};

		openModal = function(id, opener, callback) {
			$.ddModals.open(id, opener, callback);
		};

		closeModal = function(callback) {
			$.ddModals.close(callback);
		};

		isModalOpen = function() {
			return $.ddModals.isOpen();
		};

		init = function() {
			_addDynamicModalConfiguration();

			// Initialiser
			$('.js-modal-container').ddModals({
				repositionModalOnEvent: 'collapsed.ddExpandCollapse expanded.ddExpandCollapse',
				preOpen: function() {
					// close navigation
					$('.js-nav-offscreen').trigger('close.offscreen');
				}
			});
		};

		return {
			open: openModal,
			close: closeModal,
			isOpen: isModalOpen,
			init: init
		};

	}());

}(DDIGITAL, jQuery));
