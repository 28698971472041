// ==========================================================================
// CONTENT MODULES
// ==========================================================================

(function(NAMESPACE, $) {

	'use strict';

	/**
	 * Manages the widths of content modules so instead of
	 * using complicated and very specific CSS to detect which size the
	 * module should be we can do it in JavaScript
	 * Essentially is helping us achieve element media queries
	 *
	 * @namespace contentModules
	 * @memberof DDIGITAL
	 * @version 0.0.5
	 * @author Deloitte Digital Australia
	 */
	NAMESPACE.contentModules = (function() {
		var CONST,
			CLASSES,
			SELECTORS,
			_removeAllWidthClasses,
			_setAllToDefault,
			_checkContentModuleSize,
			init;

		CONST = {
			SMALL_WIDTH: 350,
			MEDIUM_WIDTH: 500,
			LARGE_WIDTH: 800
		};

		CLASSES = {
			IS_SMALL: 'is-small',
			IS_MEDIUM: 'is-medium',
			IS_LARGE: 'is-large'
		};

		SELECTORS = {
			CM: '.cm, .hm'
		};

		/**
		 * Removes all the width classes from a content module
		 *
		 * @memberof DDIGITAL.contentModules
		 * @private
		 */
		_removeAllWidthClasses = function($cm) {
			$cm.removeClass(CLASSES.IS_SMALL)
				.removeClass(CLASSES.IS_MEDIUM)
				.removeClass(CLASSES.IS_LARGE);
		};

		/**
		 * Set all the contentModules to default (small) size
		 *
		 * @memberof DDIGITAL.contentModules
		 * @private
		 */
		_setAllToDefault = function() {
			$(SELECTORS.CM).each(function(i, el) {
				_removeAllWidthClasses($(el));
			});
		};

		/**
		 * Check the size of the content module and apply a class
		 * depending on the size of the module
		 *
		 * @memberof DDIGITAL.contentModules
		 * @private
		 */
		_checkContentModuleSize = function() {
			$(SELECTORS.CM).each(function(i, el) {
				var $cm = $(el),
					cmWidth;

				// remove the classes before checking the width
				_removeAllWidthClasses($cm);

				// get current width
				cmWidth = $cm.width();

				// set width into ranges
				if (cmWidth >= CONST.SMALL_WIDTH && cmWidth < CONST.MEDIUM_WIDTH) {

					$cm.addClass(CLASSES.IS_SMALL);
					$cm.removeClass(CLASSES.IS_MEDIUM)
						.removeClass(CLASSES.IS_LARGE);

				} else if (cmWidth >= CONST.MEDIUM_WIDTH && cmWidth < CONST.LARGE_WIDTH) {

					$cm.addClass(CLASSES.IS_MEDIUM);
					$cm.removeClass(CLASSES.IS_SMALL)
						.removeClass(CLASSES.IS_LARGE);

				} else if (cmWidth >= CONST.LARGE_WIDTH) {

					$cm.addClass(CLASSES.IS_LARGE);
					$cm.removeClass(CLASSES.IS_SMALL)
						.removeClass(CLASSES.IS_MEDIUM);
				}
			});
		};

		/**
		 * Initialise the content module size checking on page load
		 * - Requires enquire.js
		 *
		 * @memberof DDIGITAL.contentModules
		 */
		init = function() {
			if (typeof (enquire) !== 'object') {
				console.error('DDIGITAL.contentModules: enquire.js is required.');
			}

			// register listeners for only the required breakpoints
			// because xs->s is fluid responsive, we need to check on resize
			// else, just need to check at the adaptive responsive breakpoints
			enquire.register(DD.bp.get('0,xxs'), {
				match: _setAllToDefault
			}).register(DD.bp.get('xs,s'), {
				match: function() {
					// use throttle to ensure that it runs ASAP
					$(window).on('resize.cm', $.throttle(200, _checkContentModuleSize));
				},
				unmatch: function() {
					// unbind the throttle event because it's not needed outside of this breakpoint
					$(window).off('resize.cm', $.throttle(200, _checkContentModuleSize));
				}
			}).register(DD.bp.get('m,m'), {
				match: _checkContentModuleSize
			}).register(DD.bp.get('l,l'), {
				match: _checkContentModuleSize
			}).register(DD.bp.get('xl,xl'), {
				match: _checkContentModuleSize
			}).register(DD.bp.get('xxl'), {
				match: _checkContentModuleSize
			});

			// do initial large check because sometimes enquire doesn't run on load
			if (DD.bp.is('xs')) {
				_checkContentModuleSize();
			}
		};

		return {
			init: init
		};
	}());

}(DDIGITAL, jQuery));
