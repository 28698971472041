/* The following piece of code records data that is sent to the Google Analytics API. */

(function() {
	'use strict';

	// Let 'dataLayer' be the data that is eventually sent to the Google Analytics API.
	var dataLayer = window.dataLayer = window.dataLayer || [];


	/** Record the language in which the current page has been translated.
	 * @listens Event.type === 'languagechange'
	 */
	function selectedLanguage() {
		// Let 'panel' be the multilingual panel.
		var panel = document.querySelector('.multilingual-ui-panel');

		if (panel) {
			// Let 'language' be the language the current page has been translated into.
			var LANGUAGE = panel.querySelector('.is-selected');

			if (LANGUAGE) {
				// Send 'language' to the Google Analytics API.
				dataLayer.push({
					event: 'Selected Languages',
					selectlanguage: 'Click',
					language: LANGUAGE.textContent
				});
			}
		}
	}


	/** Record if the play, pause or stop button has been pressed. These
	 * buttons are part of the ReadSpeaker UI.
	 * @param {MouseEvent} event - The event object
	 * @listens MouseEvent.type === 'click'
	 */
	function ReadSpeaker(event) {
		// Let 'target' be the whole Read Speaker user interface.
		var target = event.target,
			action, pageTitle;

		var SELECTOR = '.rsbtn_pause, .rsbtn_play, .rsbtn_stop',
			// Let 'button' be the 'PLAY', 'PAUSE' or 'STOP' button (if pressed).
			button = $(target).closest(SELECTOR)[0];

		// If 'button' was indeed pressed.
		if (button) {
			// Let 'action' be the action taken after pressing 'button' (play, pause or stop).
			if (button.classList.contains('.rsbtn_play')) {
				action = 'PLAY';
			} else {
				action = button.title.toUpperCase();
			}

			// Let 'pageTitle' be the title of the page.
			pageTitle = document.title;

			// Send 'action' and 'pageTitle' to the Google Analytics API.
			dataLayer.push({
				event: 'Read Speaker',
				action: action,
				readcontent: pageTitle
			});
		}
	}


	/** Record if the user has changed the font of the page. It also records if
	 * high-contrast has been switch on or off. This is done through the
	 * accessibility panel.
	 * @param {MouseEvent} event - The event object
	 * @listens MouseEvent.type === 'click'
	 */
	function AccessibilitySettings(event) {
		// Let 'form' be the form element that contains the button that controls accessibility settings.
		var form = event.target.form;

		// Let 'font' be the font currently selected by the user.
		var font = form.querySelector('#font_selection').value,
			// Let 'option' be the high contrast mode option selected.
			option = form.querySelector('[name=high_contrast_mode]:checked');
		option = option.value;

		// Send 'font' and 'option' to the Google Analytics API.
		dataLayer.push({
			event: 'Accessibility Settings',
			options: option,
			selectedfont: font
		});
	}


	/** Record if the user of the 'Feedback Form' wishes to be contacted, by
	 * what means and the service line associated with his enquiry.
	 * @param {Event} event - The event object
	 * @listens Event.type === 'submit'
	 */
	function SubmittedFeedbacks(event) {
		// Let 'form' be the target of this event.
		var form = event.target;
		// Let 'scheme' be the scheme selected (if any).
		var scheme = form.querySelector('[name=Key_ProductArea]:checked');
		scheme = scheme ? scheme.value : '';
		// Let 'contactOption' be how the user wishes to be contacted (if any).
		var contactOption = form.querySelector('[name=Key_ContactBy]:checked');
		contactOption = contactOption ? contactOption.value : '';

		// If the user has selected a scheme and a contact option.
		if (scheme && contactOption) {
			// Send 'scheme' and 'contactOption' to the Google Analytics API.
			dataLayer.push({
				event: 'Submitted Feedbacks',
				schemes: scheme,
				contactoption: contactOption
			});
		}
	}


	/** Record which filters the user has selected in the 'News and Stories'
	 * page.
	 * @param {Event} event - The event object
	 * @listens Event.type === 'submit'
	 */
	function NSFilterCombos(event) {
		// Let 'form' be the target of this event.
		var form = event.target,
			// Let 'checkBoxes' be a list of checked checkboxes contained by 'form'.
			checkBoxes = form.querySelectorAll('input[type=checkbox]:checked'),
			// Let 'data' be the data that will be sent to the Google Analytics API.
			data = '',
			checkbox, value;

		var i = 0,
			l = checkBoxes.length;

		// For each checkbox in 'checkBoxes'.
		for (; i < l; i++) {
			// Let 'checkbox' be the current checkbox.
			checkbox = checkBoxes[i];
			// Let 'value' be the value of 'checkbox'.
			value = checkbox.dataset.filterAnalytics;
			// Append 'value' to 'data'.
			data += value + ', ';
		}

		// Send 'data' to the Google Analytics API.
		dataLayer.push({
			event: 'NS Filter Combos',
			nsfilter: 'clicks',
			filtercombos: data
		});
	}

	function bannerLinksTracking(event) {
		var linkText = event.target.text;
		dataLayer.push({
			event: 'hero banner buttons',
			buttonanchortext: linkText
		});
	}

	function explainerClicked(src) {
		return function() {
			// Avoid race condition
			setTimeout(function() {
				if (src.className.includes('is-expanded')) {
					dataLayer.push({
						event  : 'explainer_expanded',
						explainermodulename : src.text
					});
				}
			}, 1);
		};
	}


	/** Activates data-recording function based on whether a component is
	 * present in the page or not.
	 * @listens Event.type === 'DomContentLoaded'
	 */
	function onDOMContentLoaded() {
		// Multilingual Panel
		document.addEventListener('languagechange', selectedLanguage, true);


		// ReadSpeaker
		var ReadSpeakerUI = document.querySelector('.rsbtn');

		if (ReadSpeakerUI) {
			ReadSpeakerUI.addEventListener('click', ReadSpeaker, true);
		}


		// Accessibility Panel
		var a11ySettings = document.querySelector('.js-submit-a11y-settings');

		if (a11ySettings) {
			a11ySettings.addEventListener('click', AccessibilitySettings, true);
		}


		// Feedback Form
		var feedbackForm = document.querySelector('#feedback-form');

		if (feedbackForm) {
			feedbackForm.addEventListener('submit', SubmittedFeedbacks, true);
		}


		// News and Stories Page
		var newsStoriesPage = document.querySelector('#news-stories');

		if (newsStoriesPage) {
			newsStoriesPage.addEventListener('submit', NSFilterCombos, true);
		}

		var bannerLinks = document.getElementsByClassName('banner-link');
		if (bannerLinks) {
			for (var i = 0; i < bannerLinks.length; i++) {
				bannerLinks[i].addEventListener('click', bannerLinksTracking, true);
			}
		}

		var explainerModules = document.querySelectorAll('.cm-explainer-module .js-ec-link');
		if (explainerModules) {
			for (var i = 0; i < explainerModules.length; i++) {
				explainerModules[i].addEventListener('click', explainerClicked(explainerModules[i]), true);
			}
		}
	}

	document.addEventListener('DOMContentLoaded', onDOMContentLoaded, true);
}());
