// ==========================================================================
// MAP
// ==========================================================================

(function(NAMESPACE, $) {

	'use strict';

	/**
	 * @namespace map
	 * @memberof DDIGITAL
	 * @version 0.1.0
	 * @author Deloitte Digital Australia
	 */
	NAMESPACE.map = (function() {
		var APIS,
			SELECTORS,
			ATTRIBUTES,
			init,
			createMaps,
			initMapModule,
			insertMapsApiScriptIfNil,
			_addAccessibleMaps,
			markerListData = [];

		APIS = {
			MAPS_API_SRC: 'https://maps.googleapis.com/maps/api/js?callback=DDIGITAL.map.createMaps&key='
		};

		SELECTORS = {
			MARKER_LIST: '.marker-list li',
			MAP_CONTAINER: '.map-container',
			MAP_MODULE: '.cm-map',
			MARKER_LIST_ITEM: {
				GEO_NAME: '.geo-name',
				GEO_LATITUDE: '.p-latitude',
				GEO_LONGITUDE: '.p-longitude'
			},
			MAP_CONTENT: '.content'
		};

		ATTRIBUTES = {
			API_KEY: 'data-map-api-key',
			GEO_HREF: 'data-geo-href'
		};

		insertMapsApiScriptIfNil = function($mapModule) {

			// Update the key to have a key if there isn't one.
			if (!APIS.MAPS_API_SRC.split('key=')[1]) {
				APIS.MAPS_API_SRC += $mapModule.attr(ATTRIBUTES.API_KEY);
			}

			// If there is no google maps API script, insert it.
			if ($('script[src="' + APIS.MAPS_API_SRC + '"]').length === 0) {

				var tag,
					firstScript;

				tag = document.createElement('script');
				tag.src = APIS.MAPS_API_SRC;

				firstScript = document.getElementsByTagName('script')[0];
				firstScript.parentNode.insertBefore(tag, firstScript);

			}
		};

		// This function is called asynchronously by the google maps lib after load completes.
		createMaps = function() {

			var $mapModules = $(SELECTORS.MAP_MODULE);

			$.each(markerListData, function(index, mapData) {

				var $currentMapModule = $($mapModules[index]),
					mapContainer = $currentMapModule.find(SELECTORS.MAP_CONTAINER)[0],
					infoWindow = null,
					initialZoomAttr = $currentMapModule.attr('data-initial-zoom'),
					zoom = initialZoomAttr && initialZoomAttr.length > 0 ? parseInt(initialZoomAttr) : 15,
					mapOptions = {
						zoom: zoom
					},
					map = new google.maps.Map(mapContainer, mapOptions),
					mapMarkers = [];

				mapData.each(function(index) {

					var data = $(this)[0],
						marker = new google.maps.Marker({
							position: new google.maps.LatLng(data.latitude, data.longitude),
							title: data.title
						});

					marker.addListener('click', function(e) {

						if (infoWindow) {
							infoWindow.close();
						}

						var indexOfMatch = -1,
							moduleContent,
							i;

						for (i = 0; i < mapMarkers.length; i += 1) {

							if (e.latLng === mapMarkers[i][0].getPosition()) {
								indexOfMatch = i;
								break;
							}

						}

						moduleContent = $currentMapModule
							.clone()
							.find(SELECTORS.MAP_CONTENT)
							.removeClass('vh')
							.find('span.vh')
							.remove()
							.end()
							[indexOfMatch].outerHTML;

						infoWindow = new google.maps.InfoWindow({
							content: '' + moduleContent,
							maxWidth: 300
						});

						infoWindow.open(map, marker);
					});

					// A 2d array, with a marker and the index of the data it is referring to;
					mapMarkers.push([marker, index]);
					marker.setMap(map);

				});

				// Fit the map around multiple markers
				if (mapMarkers.length !== 1) {

					var bounds = new google.maps.LatLngBounds(),
						i;

					for (i = 0; i < mapMarkers.length; i += 1) {
						bounds.extend(mapMarkers[i][0].getPosition());
					}

					map.fitBounds(bounds);

				} else {

					map.setCenter(mapMarkers[0][0].getPosition());

				}

				// Add accessibility
				if (mapContainer) {

					if ($('.d-touch').length === 0) {
						_addAccessibleMaps(mapContainer, map);
					}

				}

			});

		};

		initMapModule = function($mapModule) {

			insertMapsApiScriptIfNil($mapModule);

			var $markerList = $mapModule.find(SELECTORS.MARKER_LIST),
				moduleMarkerListData = [];

			moduleMarkerListData = $markerList.map(function() {

				var $listLi = $(this);

				return {
					latitude: $listLi.find(SELECTORS.MARKER_LIST_ITEM.GEO_LATITUDE).text(),
					longitude: $listLi.find(SELECTORS.MARKER_LIST_ITEM.GEO_LONGITUDE).text(),
					title: $listLi.find(SELECTORS.MARKER_LIST_ITEM.GEO_NAME).text()
				};

			});

			markerListData.push(moduleMarkerListData);

		};

		_addAccessibleMaps = function(canvas, map) {
			var attemptInterval = 2,
				maxAttempts = 18,
				mA = 0,
				notYet = true,
				addKey,
				titles = {
					'pan up': 1,
					'pan down': 1,
					'pan right': 1,
					'pan left': 1,
					'zoom in': 1,
					'zoom out': 1,
					'show street map': 1,
					'show satellite imagery': 1
				};

			// add keyboard accessibility to Google Maps
			addKey = function() {
				mA += 1;

				// only allow the function to be called a max number of times
				if (mA > maxAttempts) {
					return;
				}

				// for every DIV inside the map container
				$(canvas).find('div').each(function() {

					var title = this.getAttribute('title');

					if (title) {
						title = $.trim(title.toLowerCase());
					}

					// check if the title exists in our object
					if (titles.hasOwnProperty(title)) {
						var $el = $(this),
							hPan = Math.floor(canvas.offsetHeight / 4),
							wPan = Math.floor(canvas.offsetWidth / 4);

						titles[title] = $el;

						// set the DIV element to be clickable
						$el.attr({
							tabindex: '0',
							role: 'button'
						});

						// See http://www.visionaustralia.org/digital-access-googlemap

						$el.on('keydown.visionAusMaps', function(e) {

							var shouldPrevent = true,
								key = e.keyCode || e.which;

							if (key === 13) {
								$(this).trigger('click');
								shouldPrevent = false;
							} else if (key === 40) {//down
								map.panBy(0, wPan);
							} else if (key === 38) {//up
								map.panBy(0, -wPan);
							} else if (key === 37) {//left
								map.panBy(-hPan, 0);
							} else if (key === 39) {//right
								map.panBy(hPan, 0);
							} else {
								return;
							}

							if (shouldPrevent) {
								e.preventDefault();
								e.stopPropagation();
							}

						});

						// styling to give the focus state
						(function() {
							var mo = false,
								bo = $el.css('border'),
								ma = $el.css('margin'),
								bc = $el.css('background-color'),
								op = $el.css('opacity');

							$el.on('mouseover.visionAusMaps', function() {
								mo = true;
							}).on('mouseout.visionAusMaps', function() {
								mo = false;
							}).on('focus.visionAusMaps', function() {
								if (mo) {
									return;
								}

								$el.css({
									'border': '2px solid blue',
									'margin': '-2px',
									'background-color': 'transparent',
									'opacity': '1'
								});

							}).on('blur.visionAusMaps', function() {
								$el.css({
									'border': bo,
									'margin': ma,
									'background-color': bc,
									'opacity': op
								});
							});

							// when set to false, this means it's run and we dont need to run it again
							notYet = false;
						}());


					}
				});

				if (notYet) {
					// run until the maps have been loaded and we're able to bind events to it
					setTimeout(addKey, attemptInterval * 1000);
				}
			};

			addKey();
		};

		init = function() {

			$(SELECTORS.MAP_MODULE).each(function(index) {
				var $mapModule = $(this);
				initMapModule($mapModule, index);
			});

		};

		return {
			init: init,
			createMaps: createMaps
		};
	}());

}(DDIGITAL, jQuery));
