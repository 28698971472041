// ==========================================================================
// Insights Filters Panel
// ==========================================================================

(function(NAMESPACE, $) {

	'use strict';

	NAMESPACE.insightsFiltersPanel = (function() {

		$('.insights-filters-panel .btn-group').on('click', function(e) {

			if (!$(e.target).is('input') && !$(e.target).is('legend')) {

				var isOpen = $(e.target).closest('.btn-group').hasClass('open');

				$('.insights-filters-panel .btn-group').removeClass('open');
				$('.insights-filters-panel .btn-group button').attr('aria-expanded', 'false');

				if (!isOpen) {

					$(e.target).closest('.btn-group').addClass('open');
					$(e.target).find('button').attr('aria-expanded', 'true');

				}

			}

		});

		$('.dropdown-menu-industry fieldset').on('click', 'legend', function(e) {

			// if the panel is not open and we are not on mobile resolution
			if ($(e.target).css('cursor') !== 'default') {

				var isOpen = $(e.target).closest('fieldset').hasClass('open');

				// hiding all panes
				$('.dropdown-menu-industry fieldset').removeClass('open');

				if (!isOpen) {

					$(e.target).closest('fieldset').addClass('open');

				}

			}

		});

	}());

}(DDIGITAL, jQuery));
