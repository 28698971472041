(function() {
	'use strict';

	$.validator.addMethod('phone-characters', function(value) {
		// Let 'pattern' be a pattern that matches a digit.
		var pattern = /^[0-9+()]*$/;
		// Check if the value in the form control does not match 'pattern'.
		return pattern.test(value);
	}, 'Please enter only numbers 0-9 and special characters ( ) +');
}());
