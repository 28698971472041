/* ==========================================================================
 * LINK ICONS
 *
 * Instructions for the client:
 *
 * The JavaScript module that adds external link icons is located at
 * `source/js/modules/_linkIcons.js`. External link icons will be added to
 * any link with target="_blank"
 *
 * After editing source/js/modules/_linkIcons.js, the developer should run
 * `middleman build` to generate the compiled JavaScript file: build/js/script.js.
 * For more information about Middleman, visit: http://middlemanapp.com/
 *
 * There is another option for disabling the external link icons. If there is a
 * section of the page where no link icons should appear, you can add a
 * classname of "link-icons-disabled" to an element and no links inside this
 * element will have a link icon added.
 *
 * ========================================================================== */

(function(NAMESPACE, $) {

	'use strict';

	NAMESPACE.linkIcons = (function() {

		// Do not add icons to links that are descendents of elements with the following selector
		var IGNORE_PATTERN = '.link-icons-disabled',
			SELECTORS,
			_addClass,
			_findExternalLinks,
			init;

		SELECTORS = {
			RTE: '.list-item-description',
			LINKS_RELATED: '.cm-links-related'
		};

		_addClass = function($link, classname, caption, doAlways) {
			var $icon,
				$iconText;

			doAlways = doAlways || false;

			// Do not proceed if one of the parent elements of this link has a classname of "link-icons-disabled"
			// This test is contained in the addClass function so that it is only run on elements with a matching URL because it involved a lot of DOM lookups
			if (!doAlways && ($link.filter(IGNORE_PATTERN).length > 0 || $link.closest(IGNORE_PATTERN).length > 0)) {
				return;
			}
			// If this link contains an image and nothing else, don't add icons to it
			if ($link.children().length === 1 && $link.text() === '' && $link.children('img').length === 1) {
				return;
			}

			$icon = $link.find('.link-icon');
			if ($icon.length === 0) {
				if ($link.hasClass('link-caret-block')) {
					$link.append('<span class="link-icon"></span>');
				} else {
					var justText;

					if ($link.find('.cta, .cta-is-small').length !== 0) {
						justText = $link.clone().text();
					} else {
						justText = $link.clone().children().remove().end().text();
					}

					var linkWords = justText.split(' '),
						lastWord = linkWords.pop();

					// wrap the icon around the last word
					lastWord = '<span class="link-icon">' + lastWord + '<span class="vh"></span></span>';
					linkWords.push(lastWord);
					linkWords = linkWords.join(' ');

					var html = $link.html();
					$link.html(html.replace(justText, linkWords));

					$icon = $link.find('.link-icon');
				}
			}

			$iconText = $icon.find('.vh');

			$iconText.text($iconText.text() + caption);
			$link.addClass(classname);
		};

		/** Find all anchor elements that point to an external URL
		 * in some specified components.
		 * Display an associated icon that indicates the anchor is
		 * an external link. Append text that is only available to
		 * assistive technologies.
		 */
		_findExternalLinks = function() {
			// Let 'SELECTOR' be a list of CSS selectors matching anchor elements.
			var SELECTOR = [
				'.cm-buy-claims .link-item',
				'.cm-campaign-module a',
				'.cm-content-tile .cm-image-block-link',
				'.cm-featured-content-module .cm-image-block-link',
				'.cm-hero-banner-quick-links .quick-links a',
				'.cm-icon-title a',
				'.cm-links-downloads .link-item',
				'.cm-links-related .link-item',
				'.cm-numbered-list-module a',
				'.cm-provider-details a',
				'.cm-rich-text a:not(.faux-link)',
				'.cm-social-share a',
				'.collection-item',
				'.global-header .login-options a',
				'.header-links a',
				'.nav-footer a'
			].join(',');

			// Let 'anchors' be a list of anchors that 'SELECTOR' matches.
			var anchors = document.querySelectorAll(SELECTOR);

			function isExternalLink(href) {
				var sanitize = href.trim().toLowerCase();
				var internalLinks = ['https://policyportal.icare.nsw.gov.au/policy/make-a-payment/?utm_source=icare website&utm_medium=referral&utm_campaign=icare website link', 'https://policyportal.icare.nsw.gov.au/quote/retrieve-quote/?utm_source=icare website&utm_medium=referral&utm_campaign=icare website link'];

				return sanitize.indexOf('http') === 0 && (internalLinks.indexOf(sanitize) === -1);

			}

			// For each anchor in 'anchors'.
			for (var i = 0; i < anchors.length; i++) {
				/** @type {HTMLAnchorElement} */
				// Let 'anchor' be the current anchor.
				var anchor = anchors[i];
				// Let 'href' be the value of 'anchor's href attribute.
				var href = anchor.getAttribute('href') || '';
				if (isExternalLink(href)) {
					// Display an icon that indicates 'anchor' is an external link.
					anchor.classList.add('external-link-icon');
					// Let 'text' be a textual indication that 'anchor' is an external link.
					var text = document.createElement('span');
					text.textContent = '(external link)';
					// Make 'text' only visible to assistive technologies.
					text.classList.add('vh');
					anchor.appendChild(text);

					if ($(anchor).children().is('.cta, .cta-is-small, .cta-is-secondary, .cta-is-secondary-small')) {
						$(anchor).addClass('is-cta');
					}
				}
			}
		};

		init = function() {
			var $scopeRichText = $(SELECTORS.RTE).find(':not(.cm-numbered-list-module) a').not('.cta, .cta-is-small, .cta-is-secondary-small, .cta-is-secondary');
			var $scopeAllOtherLinks = [];

			// get all other links
			$('a').each(function() {
				var $currentLink = $(this);
				if (!$currentLink.not('.social-icon') && !$currentLink.closest('.cm-links-related')) {
					$scopeAllOtherLinks.push($(this));
				}
			});

			// ---- Rich Text, add external links. ----
			$scopeRichText.each(function() {
				var $richTextLink = $(this);

				var hostName = $richTextLink[0].host;
				if (!hostName) {
					return;
				}
				$richTextLink.each(function() {
					var host = new RegExp(location.host);
					if (!host.test(hostName)) {
						_addClass($(this), 'link-external', ' (External link)');
					}
				});
			});

			// --- All links other than Related Links & Rich Text links ---
			$scopeAllOtherLinks.forEach(function($link) {
				var hostName = $link[0].host;
				if (!hostName) {
					return;
				}
				var host = new RegExp(location.host);
				if (!host.test(hostName)) {
					$link.addClass('all-other-link-external');
					$link.append('<span class="vh">(External link)</span>');

				}
			});

			_findExternalLinks();
		};

		return {
			init: init
		};
	}());

}(DDIGITAL, jQuery));
