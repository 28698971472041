// ==========================================================================
// HELPER UTILITIES
// ==========================================================================

/**
 * Deloitte Digital global namespace
 * @namespace DDIGITAL
 */
(function(NAMESPACE, $) {

	'use strict';

	/**
	 * Utility namespace
	 * @namespace DDIGITAL.util
	 * @memberof DDIGITAL
	 */
	NAMESPACE.util = NAMESPACE.util || {};

	/**
	 * Helper functions to get older browsers more aligned with newer ones
	 *
	 * @namespace helpers
	 * @memberof DDIGITAL.util
	 * @version 1.0.0
	 * @author Deloitte Digital Australia deloittedigital@deloitte.com.au
	 */
	NAMESPACE.util.helpers = (function() {
		var _setResponsiveToStatic,
			_polyfillTextAreaMaxlength,
			_polyfillInputSiblingSelectors,
			_polyfillSelectorsForIE8,

			parseBoolean,
			triggerCallback,
			isValidCallback,
			init;

		/**
		 * Set the page options to be static instead of responsive
		 *
		 * @memberof DDIGITAL.util.helpers
		 * @private
		 */
		_setResponsiveToStatic = function() {
			NAMESPACE.IS_RESPONSIVE = false;

			DD.bp.options({
				isResponsive: false
			});
		};

		/**
		 * Text area polyfill to allow maxlength attribute
		 *
		 * @memberof DDIGITAL.util.helpers
		 * @private
		 */
		_polyfillTextAreaMaxlength = function() {
			$('textarea[maxlength]').each(function() {
				var $textarea = $(this),
					maxlength = parseInt($textarea.attr('maxlength'), 10);

				if (isNaN(maxlength) === false) { //make sure it's a number
					$textarea.on('keyup.textareaMaxlength blur.textareaMaxlength', function() {
						var val = $(this).val();
						if (val.length > maxlength) {
							$(this).val(val.substr(0, maxlength));
						}
					});
				}
			});
		};

		/**
		 * Add css classes in replacement for selectors that aren't supported in IE8
		 *
		 * @memberof DDIGITAL.util.helpers
		 * @private
		 */
		_polyfillSelectorsForIE8 = function() {
			$('li:last-child, th:last-child, td:last-child, tr:last-child').addClass('last-child');
			$('tr:nth-child(2n)').addClass('odd');
		};

		/**
		 * Checkbox polyfill for sibling selectors
		 *
		 * @memberof DDIGITAL.util.helpers
		 * @private
		 */
		_polyfillInputSiblingSelectors = function() {
			var checkValue = function($elem) {
				var $label = $('label[for="' + $elem.attr('id') + '"]');
				if ($elem.prop('checked')) {
					$elem.add($label).addClass('is-checked');
				} else {
					$elem.add($label).removeClass('is-checked');
				}

				// We modify the label as well as the input because authors may want to style the labels based on the state of the chebkox, and IE7 and IE8 don't fully support sibling selectors.
				return $elem;
			};

			$('input:radio, input:checkbox').each(function() {
				var $self = $(this);

				if ($self.prop('type') === 'radio') {
					$('input[name="' + $self.prop('name') + '"]').on('change.checkboxPolyfill', function() {
						checkValue($self);
					});
				} else if ($self.prop('type') === 'checkbox') {
					$self.change(function() {
						checkValue($self);
					});
				}

				// Check value when polyfill is first called, in case a value has already been set.
				checkValue($self);
			});
		};

		/**
		 * Trigger callback if its valid
		 *
		 * @memberof DDIGITAL.util.helpers
		 * @author Saxon Cameron (sacameron@deloitte.com.au)
		 * @param callback {Function}
		 */
		triggerCallback = function(callback) {
			if (isValidCallback(callback)) {
				return callback();
			}

			return false;
		};

		/**
		 * Check if callback is valid
		 *
		 * @memberof DDIGITAL.util.helpers
		 * @author Saxon Cameron (sacameron@deloitte.com.au)
		 * @param callback {Function}
		 */
		isValidCallback = function(callback) {
			return callback != null && typeof callback === 'function';
		};

		parseBoolean = function(boolean) {
			return boolean === true || boolean === 'true';
		};

		/**
		 * Initialiser for the helpers
		 *
		 * @memberof DDIGITAL.util.helpers
		 */
		init = function() {
			if ($('.lt-ie10').length) {
				_polyfillTextAreaMaxlength();
			}

			if ($('.lt-ie9').length) {
				_setResponsiveToStatic();
				_polyfillSelectorsForIE8();
				_polyfillInputSiblingSelectors();
			}

			// focus on an element without actually scrolling the page to it
			$.fn.noScrollFocus = function() {
				var x = window.scrollX,
					y = window.scrollY;

				this.focus();

				if ($('.lt-ie10').length === 0) {
					window.scrollTo(x, y);
				}

				return this; //chainability
			};
		};

		return {
			init: init,
			triggerCallback: triggerCallback,
			isValidCallback: isValidCallback,
			parseBoolean: parseBoolean
		};
	}());

}(DDIGITAL, jQuery));
