// ==========================================================================
// M01 - GLOBAL HEADER - PORTAL
// ==========================================================================

(function(NAMESPACE, $) {

	'use strict';

	/**
	 * Global header functions for Portal
	 *
	 * @namespace globalHeaderPortal
	 * @memberof DDIGITAL
	 * @version 1.0.0
	 * @author Deloitte Digital Australia
	 */
	NAMESPACE.globalHeaderPortal = (function() {
		var SELECTORS,
			init,
			_closeSitecoreSession,
			_closeOktaSession;

		SELECTORS = {
			GLOBAL_HEADER: '.global-header.is-portal',
			LOGOUT_BUTTON: '.logout'
		};

		/**
		 * Close Sitecore session
		 * Calls function to close Okta session
		 * @method _closeSitecoreSession
		 * @param {event} event Mouse click event
		 * @private
		 */
		_closeSitecoreSession = function(event) {
			event.preventDefault();
			$(SELECTORS.LOGOUT_BUTTON)
				.prop('disabled', true);

			$.ddModals.open('logout-modal');

			$.ajax({
				method: 'GET',
				// url: '/assets/json/okta-logout.json' // DEV ONLY
				url: '/api/portalauthentication/logout'
			})
			.success(function(resp) {
				_closeOktaSession(resp.data);
			})
			.fail(function(jqXHR, textStatus) {
				console.error('Request failed: ' + textStatus);
			});
		};

		/**
		 * Close Okta session
		 * Redirects user to login page
		 * @method _closeOktaSession
		 * @param {object} resp Response obejct from Sitecore API
		 * @private
		 */
		_closeOktaSession = function(data) {
			$.ajax({
				method: 'DELETE',
				xhrFields: {
					withCredentials: true
				},
				beforeSend: function(xhr) {
					xhr.setRequestHeader('Accept', 'application/json');
				},
				url: data.OktaLogoutUrl
			})
			.success(function() {
				window.location.replace(data.RedirectUrl);
			})
			.fail(function(jqXHR, textStatus) {
				console.error('Request failed: ' + textStatus);
			});
		};

		init = function() {

			if ($(SELECTORS.GLOBAL_HEADER).length) {
				$(SELECTORS.LOGOUT_BUTTON).on('click', _closeSitecoreSession);
			}
		};

		return {
			init: init
		};
	}());

}(DDIGITAL, jQuery));
