// ==========================================================================
// MULTI STEP FORM PROGRESS BAR CLASS
// ==========================================================================

(function(NAMESPACE, $) {

	'use strict';

	/**
	 * Forms namespace
	 *
	 * @namespace DDIGITAL.forms
	 * @memberof DDIGITAL
	 */
	NAMESPACE.forms = NAMESPACE.forms || {};

	/**
	 * Multistep namespace
	 *
	 * @namespace DDIGITAL.forms.multistep
	 * @memberof DDIGITAL
	 */
	NAMESPACE.forms.multistep = NAMESPACE.forms.multistep || {};

	/**
	 * @class ProgressBar
	 * @memberOf DDIGITAL.forms.multistep
	 *
	 * @param {Object} $container jQuery reference to the form wrapper
	 * @param {Object} settings Default settings hash
	 * @constructor
	 */
	var ProgressBar,
		CLASSES;

	CLASSES = {
		IS_COMPLETE: 'is-complete',
		IS_CURRENT: 'is-current'
	};

	/**
	 * @class ProgressBar
	 * @memberOf DDIGITAL.forms.multistep
	 *
	 * @param {Object} $container Reference to progress bar container
	 * @param {Object} settings Hash of configuration parameters
	 * @constructor
	 */
	ProgressBar = function($container, settings) {
		var defaults = {
			onStepClick: function() {},
			titleFormat: 'Step {{step.displayAt}} of {{totalSteps}} : {{step.title}}',
			displayStepTitle: true
		};

		this.$container = $container;
		this.initialState = this.$container.data('formProgressState');

		if (this.initialState.indexOf('%') !== -1) {
			this.type = 'percentage';
		}

		this.settings = $.extend(true, this.settings, defaults, settings);
	};

	ProgressBar.prototype = {

		TYPES: [
			'steps',
			'percentage'
		],

		TEMPLATES: {
			list: '<ul />',
			step: [
				'<li class="_step">',
				'<span class="_step-number">{{step.displayAt}}</span>',
				'<span class="_step-title vh">{{step.title}}</span>',
				'</li>'
			].join(''),
			title: '<p />',
			bar: [
				// Browsers that support HTML5 progress element will ignore the html inside
				// `progress` element. Whereas older browsers will ignore the `progress` element
				// and instead render the html inside it.
				'<progress max="100" value="">',
				'<div class="progress-bar">',
				'<span></span>',
				'</div>',
				'</progress>'
			].join('')
		},

		settings: {},

		type: 'steps',

		$container: null,
		$list: null,

		/**
		 * @type {Number}
		 */
		currentStep: 1,

		/**
		 * Initialize progress bar
		 * @memberOf DDIGITAL.forms.multiStep.ProgressBar
		 * @private
		 */
		init: function(service) {

			this.service = service;

			if (this.type === 'steps') {

				this._renderList();

				if (this.settings.displayStepTitle === true) {
					this._renderTitle();
				}

			} else if (this.type === 'percentage') {

				this._renderBar();

			}
		},

		/**
		 * Renders percentage progress bar
		 * @private
		 */
		_renderBar: function() {
			this.$bar = $(this.TEMPLATES.bar);
			this.$bar.appendTo(this.$container);
		},

		/**
		 * Stubs the nodes of the progress bar
		 * @private
		 */
		_renderList: function() {
			var steps = this.service.getSteps(),
				lastStep = 0;

			this.$list = $(this.TEMPLATES.list);
			this.$list.appendTo(this.$container);

			for (var i = 0; i < steps.length; i += 1) {

				if (steps[i].displayAt !== lastStep) {
					this._renderStep(steps[i]);
				}

				lastStep = steps[i].displayAt;
			}

			this.$steps = this.$list.children();
		},

		/**
		 * Renders the initial state of one node (step) of the progress bar
		 *
		 * @param {{ title: String, partial: String, step: Number, displayAt: Number, slug: String}} stepObject
		 * @private
		 */
		_renderStep: function(stepObject) {
			var template = this.TEMPLATES.step,
				$step;

			template = template.replace('{{step.title}}', stepObject.title);
			template = template.replace('{{step.displayAt}}', stepObject.displayAt.toString());

			$step = $(template);
			$step.data('step', stepObject);

			this.$list.append($step);
		},

		/**
		 * Stubs title element
		 *
		 * @private
		 */
		_renderTitle: function() {
			var template = this.TEMPLATES.title;

			this.$title = $(template);
			this.$title.html('&nbsp;');

			this.$title.appendTo(this.$container);
		},

		/**
		 * Updates progress bar title
		 *
		 * @param {Object} step Step to update the title to
		 * @private
		 */
		_updateTitle: function(step) {
			var title = this.settings.titleFormat;

			title = title.replace('{{step.displayAt}}', step.displayAt.toString())
				.replace('{{totalSteps}}', this.$steps.length)
				.replace('{{step.title}}', step.title);

			this.$title.text(title);
		},

		/**
		 * Updates progress bar to make the given step the current one
		 *
		 * @param {Object} step Step object
		 * @returns {boolean}
		 */
		setState: function(step) {
			var steps;

			if (step.step === this.currentStep && this.currentStep !== 1) {
				return false;
			}

			function updateStatus(_step, idx) {
				if (idx < step.displayAt) {
					if (typeof _step.canEdit === 'undefined' || _step.canEdit === true) {
						this._addLink(_step);
					}
					this._markComplete(_step);
				} else {
					this._removeLink(_step);
					this._markInComplete(_step);
				}
			}

			if (this.type === 'steps') {

				steps = this.service.getSteps();

				for (var i = 1; i <= steps.length; i += 1) {
					var _step = steps[i - 1];
					updateStatus.call(this, _step, i);
				}

				this._makeCurrent(step);
				this._updateTitle(step);

			} else {
				// @todo Write this feature
				console.warn('The progress bar type `percentage` is not yet supported');
			}
		},

		/**
		 * Marks the correct step in the list as the current one while unmarking the others
		 *
		 * @param {Object} step Current step
		 * @private
		 */
		_makeCurrent: function(step) {
			this.$steps
				.removeClass(CLASSES.IS_CURRENT)
				.eq(step.displayAt - 1)
				.addClass(CLASSES.IS_CURRENT);

			this.currentStep = step.step;
		},

		/**
		 * Marks given step as complete by adding a class name
		 *
		 * @param {Object} step Step to mark as complete
		 * @private
		 */
		_markComplete: function(step) {
			this.$steps
				.eq(step.displayAt - 1)
				.addClass(CLASSES.IS_COMPLETE);
		},

		/**
		 * Un-marks given step as complete by removing a class name
		 *
		 * @param {Object} step Step ro mark as incomplete
		 * @private
		 */
		_markInComplete: function(step) {
			this.$steps
				.eq(step.displayAt - 1)
				.removeClass(CLASSES.IS_COMPLETE);
		},

		/**
		 * Makes step clickable
		 *
		 * @param {Object} step
		 * @private
		 */
		_addLink: function(step) {
			var $a = $(document.createElement('a'));

			$a.attr('href', this.service.getURLPathFromStep(step.step))
				.on('click', function(evt) {
					this.settings.onStepClick(evt, step);
				}.bind(this));

			this.$steps
				.eq(step.displayAt - 1)
				.children()
				.wrapAll($a);
		},

		/**
		 * Removes clickable behaviour from step node
		 *
		 * @param {Object} step
		 * @private
		 */
		_removeLink: function(step) {
			this.$steps
				.eq(step.displayAt - 1)
				.find('> a')
				.children()
				.unwrap('a');
		}

	};

	NAMESPACE.forms.multistep.ProgressBar = ProgressBar;

}(DDIGITAL, jQuery));
