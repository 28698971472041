// ==========================================================================
// MULTI STEP FORM
// ==========================================================================

(function(NAMESPACE, $) {

	'use strict';

	/**
	 * Forms namespace
	 *
	 * @namespace DDIGITAL.forms
	 * @memberof DDIGITAL
	 */
	NAMESPACE.forms = NAMESPACE.forms || {};

	/**
	 * Multistep namespace
	 *
	 * @namespace DDIGITAL.forms.multistep
	 * @memberof DDIGITAL
	 */
	NAMESPACE.forms.multistep = NAMESPACE.forms.multistep || {};

	/**
	 * Multi step form service
	 *
	 * @namespace DDIGITAL.forms.multistep.router
	 * @memberOf DDIGITAL.forms.multistep
	 */
	NAMESPACE.forms.multistep.router = (function() {
		var init,
			OPTIONS,
			_listen,
			navigate,
			onStateChange,
			replaceState;

		OPTIONS = {
			onStateChange: function() {}
		};

		/**
		 * @private
		 * @memberOf DDIGITAL.forms.multistep.router
		 */
		_listen = function() {
			$(window).on('popstate.multistep', function(evt) {
				onStateChange(evt);
			});
		};

		/**
		 * @param {String} url URL to navigate to with using pushState
		 * @param {Object} step Step model object
		 * @memberOf DDIGITAL.forms.multistep.router
		 */
		navigate = function(url, step) {
			history.pushState(step, null, url);
		};

		/**
		 * @param {Object} evt State change event object
		 * @memberOf DDIGITAL.forms.multistep.router
		 */
		onStateChange = function(evt) {
			OPTIONS.onStateChange(evt);
		};

		/**
		 * Replace the current history entry with the same URL path but add a step object
		 * @param {Object} step
		 * @memberOf DDIGITAL.forms.multistep.router
		 */
		replaceState = function(step) {
			history.replaceState(step, null, window.location.pathname);
		};

		/**
		 * @param {Object} options Object containing options to override default OPTIONS
		 * @memberOf DDIGITAL.forms.multistep.router
		 */
		init = function(options) {
			OPTIONS = $.extend(true, OPTIONS, options);

			_listen();

			return this;
		};

		return {
			init: init,
			navigate: navigate,
			onStateChange: onStateChange,
			replaceState: replaceState
		};

	}());

}(DDIGITAL, jQuery));
