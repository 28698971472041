// ==========================================================================
// SCROLL TO
// ==========================================================================

/**
 * Deloitte Digital global namespace
 * @namespace DDIGITAL
 */
(function(NAMESPACE, $) {

	'use strict';

	/**
	 * Utility namespace
	 * @namespace DDIGITAL.util
	 * @memberof DDIGITAL
	 */
	NAMESPACE.util = NAMESPACE.util || {};

	/**
	 * Breakpoints for JavaScript. Works with the Deloitte Digital SCSS @bp mixin
	 *
	 * @namespace scroll
	 * @memberof DDIGITAL.util
	 * @version 1.0.0
	 * @author Deloitte Digital Australia deloittedigital@deloitte.com.au
	 */
	NAMESPACE.util.scroll = (function() {
		var SELECTORS,
			DATA,
			scrollPage,
			scrollPageOnClick,
			init;

		SELECTORS = {
			SCROLLTO: '.scrollto',
			TOP_HIDDEN_NAV_LINKS: '#top a'
		};

		DATA = {
			OFFSET: 'data-scroll-offset',
			TABTO: 'data-scroll-tabto',
			HASH: 'data-scroll-hash'
		};

		/**
		 * Scrolls the page
		 *
		 * @memberof DDIGITAL.util.scroll
		 * @param  {Number} top Top of the scren in pixels to scroll to.
		 * @param  {String} hash The hash to be updated in the URL upon completion of the scroll. Can be null.
		 * @param  {Number} _duration Time taken to animate to the new location. If null it will automatically determine the speed based on distance.
		 * @param  {Function} callback Callback function that is called on complete of the scroll.
		 */
		scrollPage = function(top, hash, _duration, callback) {
			var duration = _duration,
				isCallbackCalled = false;

			hash = (typeof (hash) === 'string') ? hash.substring(hash.indexOf('#') + 1) : null;

			if (duration === null) {
				var currentTop = $(document).scrollTop(),
					currentDistance = Math.abs(top - currentTop),
					maxDistance = 2000,
					maxDuration = 1000;

				if (currentDistance > maxDistance) {
					duration = maxDuration;
				} else {
					duration = (top > currentTop) ? (1 - currentTop / top) * maxDuration : (1 - top / currentTop) * maxDuration;
				}
			}

			$('html').velocity('stop').velocity('scroll', {
				offset: top,
				duration: duration,
				complete: function() {
					if (!isCallbackCalled) {
						isCallbackCalled = true;

						if (typeof (hash) === 'string') {
							if (window.history && window.history.pushState) {
								window.history.pushState(null, null, '#' + hash);
							} else {
								window.location.hash = hash;
							}
						}

						if (typeof (callback) === 'function') {
							callback();
						}
					}
				}
			});
		};

		/**
		 * Scrolls the page on click of a button
		 *
		 * @memberof DDIGITAL.util.scroll
		 * @private
		 * @param  {Event} top Top of the scren in pixels to scroll to.
		 */
		scrollPageOnClick = function(event) {
			var $btn = $(this),
				target = $btn.attr('href'),
				scrollOffset = parseInt($btn.attr(DATA.OFFSET), 10) || 0,
				tabTo = ($btn.attr(DATA.TABTO) === 'true'),
				updateHash = ($btn.attr(DATA.HASH) === 'false') ? false : true;

			// scroll to location
			target = target.substr(target.indexOf('#') + 1);

			var $target = $('#' + target),
				newHash = (updateHash) ? target : null;

			// we've assumed ID, if we can't find it, assume name attribute instead
			if ($target.length === 0) {
				$target = $('a[name="' + target + '"]');
			}

			// if can't be found, it's invalid - so treat it as a normal link
			if ($target.length === 0) {
				return;
			}

			// if the above checks have all passed, we can definitely scroll
			event.preventDefault();

			if (tabTo) {
				scrollPage($target.offset().top + scrollOffset, newHash, null, function() {
					$target.eq(0).noScrollFocus();
				});
			} else {
				scrollPage($target.offset().top + scrollOffset - $('.global-header').height() - 70, newHash);
			}
		};

		/**
		 * Binds the appropriate click events to the appropriate buttons on the page
		 *
		 * @memberof DDIGITAL.util.scroll
		 */
		init = function() {
			$('body').off('click.scrollto').on('click.scrollto', SELECTORS.SCROLLTO, scrollPageOnClick);

			$(SELECTORS.TOP_HIDDEN_NAV_LINKS).last().on('click', function() {
				scrollPage($('#skip-to-main').offset().top - $('.global-header').height() - 85, null, 250, null);
			});
		};

		return {
			page: scrollPage,
			init: init
		};
	}());

}(DDIGITAL, jQuery));
