(function(NAMESPACE, $) {

	'use strict';


	NAMESPACE.search = (function() {

		var init,
			_updateFacetFilters,
			CLASSES,
			SELECTORS;

		CLASSES = {
			IS_ACTIVE: 'is-active'
		};

		SELECTORS = {
			CONTENT_HEADER_SEARCH: '.content-header.is-search, .content-header.is-collection',
			SEARCH_FACETS: '.cm-search-facets',
			MODAL_SEARCH_FACETS: '.modal-search-facets'
		};

		/**
		 * Update facet filters based on modal filters
		 * @method _updateFacetFilters
		 * @param {object} $el Checked facet filter
		 * @private
		 */
		_updateFacetFilters = function($el) {
			var selectedFacetName = $el.attr('value');

			$(SELECTORS.SEARCH_FACETS).find('input:not(:submit)[value="' + selectedFacetName + '"], select[value="' + selectedFacetName + '"]').attr('checked', true);
		};

		init = function() {

			if ($(SELECTORS.CONTENT_HEADER_SEARCH.length)) {
				$(SELECTORS.CONTENT_HEADER_SEARCH).find('input')
					.on('focus mouseenter', function() {
						$(this).parent('.ctrl').addClass(CLASSES.IS_ACTIVE);
					})
					.on('blur mouseleave', function() {
						$(this).parent('.ctrl').removeClass(CLASSES.IS_ACTIVE);
					});
			}

			if (SELECTORS.SEARCH_FACETS.length) {
				$(SELECTORS.MODAL_SEARCH_FACETS).find('input:not(:submit), select').on('change', function() {
					_updateFacetFilters($(this));
				});
			}
		};

		return {
			init: init
		};

	}());


}(DDIGITAL, jQuery));
