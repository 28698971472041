// ==========================================================================
// FORM VALIDATION
// ==========================================================================

(function(NAMESPACE) {

	'use strict';

	/**
	 * Forms namespace
	 *
	 * @namespace DDIGITAL.forms
	 * @memberof DDIGITAL
	 */
	NAMESPACE.forms = NAMESPACE.forms || {};

	/**
	 * Form validation with jQuery validate
	 *
	 * @namespace validate
	 * @memberOf DDIGITAL
	 */
	NAMESPACE.forms.validate = (function() {

		var CONST,
			CLASSES,
			SELECTORS,
			getValidatorFromElement,
			_getGroupsInForm,
			isGroupValid,
			isInGroup,
			reset,
			resetScope,
			resetInput,
			isDirty,
			_onFocusOutHandler,
			setValidationDelay,
			init;

		CONST = {
			IS_DEBUG: false,
			SUMMARY: {
				TYPE: 'error',
				TITLE: 'There are some validation issues.'
			}
		};

		CLASSES = {
			ERROR: 'error'
		};

		SELECTORS = {
			FORM: '.js-validate',
			IGNORE: '.v-ignore, :hidden, [disabled]',
			RESET: 'input[type="reset"], button[type="reset"]',
			// Let 'SELECTOR' be a CSS selector that matches form controls.
			FORM_CONTROLS: 'input, select, textarea'
		};

		/**
		 * Gets the validator object from the current element
		 *
		 * @param {DOM Object} element DOM element of a form item
		 * @memberOf DDIGITAL.forms.validate
		 */
		getValidatorFromElement = function(element) {
			return NAMESPACE.forms.decorator.getFormFromElement(element).validate();
		};

		/**
		 * Parses the form object and groups form elements together if they
		 * are in the standard group format.
		 *
		 * @param {jQuery Object} $form jQuery DOM Object of the form
		 * @return {Object}
		 * @private
		 * @memberOf DDIGITAL.forms.validate
		 */
		_getGroupsInForm = function($form) {
			var groups = {};

			// if the form item is inside a .ctrls container, it's a grouped control.
			$form.find(NAMESPACE.forms.decorator.getSelectors().CTRLS).each(function(i, el) {
				var $group = $(el),
					$ctrls = $group.find(NAMESPACE.forms.decorator.getSelectors().ELEMENTS),
					key = $ctrls.first().attr('id'),
					ids = [];

				$ctrls.each(function(i, ctrl) {
					ids.push($(ctrl).attr('id'));
				});

				groups[key] = ids.join(' ');
			});

			return groups;
		};

		/**
		 * Given a single form element, returns true if the group of
		 * fields associated with it are all valid as well.
		 *
		 * @param {DOM Object} element DOM element of a form element
		 * @return {Boolean}
		 * @private
		 * @memberOf DDIGITAL.forms.validate
		 */
		isGroupValid = function(element) {
			var valid = true,
				$ctrls = NAMESPACE.forms.decorator.getCtrlsInGroup(element),
				validator = getValidatorFromElement(element);

			// iterate each control in the group
			$ctrls.each(function(i, ctrl) {
				var id = $(ctrl).attr('id');

				// check if the errorMap has a corresponding error
				if (validator.errorMap.hasOwnProperty(id)) {
					valid = false;
				}
			});

			return valid;
		};

		/**
		 * Detects if the given form element is inside a group or not.
		 *
		 * @param {DOM Object} element DOM element for a form item
		 * @return {Boolean}
		 * @memberOf DDIGITAL.forms.validate
		 */
		isInGroup = function(element) {
			var validator = getValidatorFromElement(element),
				groupsMap;

			// search for the element inside the list of validator groups
			groupsMap = $.map(validator.groups, function(groupName, controlName) {
				return (controlName.indexOf($(element).attr('id')) >= 0);
			});

			return (groupsMap.indexOf(true) !== -1);
		};

		/**
		 * Returns true if a control is dirty, meaning that it holds or has held a value
		 * @returns {boolean}
		 * @param {Element} element HTML input element
		 * @memberOf DDIGITAL.forms.validate
		 */
		isDirty = function(element) {
			var validator = getValidatorFromElement(element);
			return validator.submitted[element.name] !== undefined;
		};

		/**
		 * Resets the form back to it's original state and removes error
		 * messages and summary messages.
		 *
		 * @param {jQuery Object} $form jQuery DOM Object of the form
		 * @memberOf DDIGITAL.forms.validate
		 */
		reset = function($form, removeSummary) {
			removeSummary = removeSummary || false;
			$form.validate().resetForm();

			if (removeSummary) {
				NAMESPACE.forms.decorator.formSummary.remove($form);
			}
		};

		/**
		 * Resets the inputs within container
		 * @param {Object} $container jQuery container object
		 * @memberOf DDIGITAL.forms.validate
		 */
		resetScope = function($container) {
			$container.find('input, select, textarea').each(function(i, el) {
				resetInput(el);
			});
		};

		/**
		 * Resets the input or input group to be empty or unchecked
		 * @param {Element} input Element from within the doWhen scope
		 * @memberOf DDIGITAL.forms.validate
		 */
		resetInput = function(input) {
			var $input = $(input);

			if (NAMESPACE.forms.validate.isInGroup(input)) {
				var $ctrls = NAMESPACE.forms.decorator.getCtrlsInGroup(input);

				$ctrls.each(function(i, el) {
					var $ctrl = $(el);

					if ($ctrl.is(':checkbox') || $ctrl.is(':radio')) {
						$ctrl.prop('checked', false);
					} else {
						$ctrl.val('');
					}
				});
			} else if ($input.is(':radio') || $(input).is(':checkbox')) {
				$input.prop('checked', false);
			} else {
				$input.val('');
			}

			// remove inline validation
			NAMESPACE.forms.decorator.unhighlight(input);
		};

		/**
		 * Checks element type and triggers validate accordingly
		 * @param {Element} element Element to be validated
		 * @inner
		 * @memberOf DDIGITAL.forms.validate
		 * @private
		 */
		_onFocusOutHandler = function(element) {
			// Prevent error state from tabbing through controls in a
			// group when form submission has not been attempted yet.
			if (NAMESPACE.forms.validate.isInGroup(element)) {
				var $ctrls = NAMESPACE.forms.decorator.getCtrlsInGroup(element),
					hasValue = true;

				// Loop through all the controls to check if they have all been filled out
				$ctrls.each(function(i, el) {
					var $ctrl = $(el);

					if (($ctrl.is(':checkbox') || $ctrl.is(':radio')) && !$ctrl.is(':checked')) {
						// if the item is a radio or checkbox and isn't checked don't validate it
						hasValue = false;
					} else {
						// if the value of the non radio/checkbox item is empty/null or 0 don't validate it
						if ($ctrl.val() === '' || $.trim($ctrl.val()) === '0' || $ctrl.val() === null) {
							hasValue = false;
						}
					}
				});

				if (hasValue) {
					// this.element validates the form - not returning this will not validate the element
					this.element(element);
				}

				return;
			}

			if (!this.checkable(element) && (isDirty(element) || !this.optional(element))) {
				this.element(element);
			}
		};

		/**
		 * Set validation delay on element
		 * @param {Element} element HTML element
		 * @param {Number} delay Delay in milliseconds
		 * @memberOf DDIGITAL.forms.validate
		 */
		setValidationDelay = function(element, delay) {
			$(element).data('validation-delay', delay);
		};


		/**
		 * Disable or enable each form control in a particular container.
		 * @param {Boolean} boolean - A truthy value disables each form control
		 * whereas a falsy one enables each form control.
		 * @returns {Function} - A closure.
		 */
		function toggleDisability(boolean) {
			return function(event) {
				// Let 'target' be the target of the event.
				var target = event.target;
				// Let 'controls' be the form controls contained in 'target'.
				var controls = target.querySelectorAll(SELECTORS.FORM_CONTROLS);
				// For each form control in 'controls'.
				for (var i = 0; i < controls.length; i++) {
					// Disable or enable the form control accordingly.
					controls[i].disabled = boolean;
				}
			};
		}


		/**
		 * Searches for each form that requires validation and applies the
		 * jQuery Validate plugin to it.
		 *
		 * @memberOf DDIGITAL.forms.validate
		 */
		init = function($scope) {
			$scope = $scope || $('body');

			$scope.find(SELECTORS.FORM).each(function(i, el) {
				var $form = $(el);

				// initialise the form validation plugin
				$form.validate({
					debug: CONST.IS_DEBUG,
					errorClass: CLASSES.ERROR,
					ignore: SELECTORS.IGNORE,
					errorElement: 'span',
					ignoreTitle: true,
					groups: _getGroupsInForm($form),
					focusInvalid: false,
					errorPlacement: function($error, element) {
						NAMESPACE.forms.decorator.errorPlacement($error, element);
					},
					highlight: function(element) {
						NAMESPACE.forms.decorator.highlight(element);
					},
					unhighlight: function(element) {
						NAMESPACE.forms.decorator.unhighlight(element);
					},
					invalidHandler: function(event, validator) {
						NAMESPACE.forms.decorator.formSummary.add(CONST.SUMMARY.TYPE, $(this), validator.errorList, CONST.SUMMARY.TITLE);
					},
					success: function(error, element) {
						// if the element is in a group and the entire group isn't valid
						// don't validate the group at all
						if (isInGroup(element) && !isGroupValid(element)) {
							return;
						}

						NAMESPACE.forms.decorator.unhighlight(error, element);
					},
					onfocusout: function(element) {
						var validationDelay = $(element).data('validation-delay');

						if (validationDelay !== undefined && validationDelay !== false) {
							setTimeout(function() {
								_onFocusOutHandler.call(this, element);
							}.bind(this), validationDelay);
						} else {
							_onFocusOutHandler.call(this, element);
						}
					}
				});

				// reset the form on the reset button click
				$form.on('click.validate', SELECTORS.RESET, function() {
					reset($form, true);
				});

				// Validate control when it loses focus.
				(function() {
					// Let 'validator' be the JQueryValidation API.
					var validator = $form.validate();
					// Get the form to listen for 'focusout' events.
					$form.on('focusout', SELECTORS.FORM_CONTROLS, function(event) {
						// Let 'control' be the control that has just lost focus.
						var control = event.target;
						// Check the validity of 'control' and show a validation message if necessary.
						validator.element(control);
					});
				}());

				// Stop form controls that have just become invisible from submitting their values.
				$form.on('unmatched.show.doWhen', toggleDisability(true));

				// Allow form controls that have just become visible to submit their values.
				$form.on('matched.show.doWhen', toggleDisability(false));
			});
		};

		return {
			getValidatorFromElement: getValidatorFromElement,
			isInGroup: isInGroup,
			isGroupValid: isGroupValid,
			reset: reset,
			resetScope: resetScope,
			resetInput: resetInput,
			isDirty: isDirty,
			setValidationDelay: setValidationDelay,
			init: init
		};

	}());

}(DDIGITAL));
