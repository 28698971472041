// ==========================================================================
// CUSTOM REQUIRED VALIDATION RULE
// Required - validates grouped controls as one
// ==========================================================================
(function(NAMESPACE) {

	'use strict';

	$.validator.addMethod('required', function(value, ctrl, param) {
		var $ctrl = $(ctrl),
			isGroupValid;

		isGroupValid = function(ctrl) {
			var $ctrls = NAMESPACE.forms.decorator.getCtrlsInGroup(ctrl),
				requirementMet = false;

			// Iterate all controls in the group
			$ctrls.each(function() {
				var _$ctrl = $(this),
					isCheckboxOrRadio = _$ctrl.is(':checkbox') || _$ctrl.is(':radio');

				// Skip iteration if is control that this rule is executed for
				if (_$ctrl.attr('id') === $ctrl.attr('id')) {
					return true;
				}

				// Check if we're dealing with a checkbox/radio button and if so, is it checked?
				requirementMet = isCheckboxOrRadio ? _$ctrl.is(':checked') : _$ctrl.val() !== null && $.trim(_$ctrl.val()) !== '';
			});

			return requirementMet;
		};

		// check if dependency is met
		if (!this.depend(param, ctrl)) {
			return 'dependency-mismatch';
		}

		// Check if is in group, if any of the other elements already has a value, return true
		if (NAMESPACE.forms.validate.isInGroup(ctrl) && isGroupValid(ctrl)) {
			return true;
		}

		if (this.checkable(ctrl)) {
			return this.getLength(value, ctrl) > 0;
		}

		return value !== null &&  $.trim(value).length > 0;

	}, 'This field is required.');

}(DDIGITAL));
