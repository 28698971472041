// ==========================================================================
// HELPER UTILITIES
// ==========================================================================
/**
 * Deloitte Digital global namespace
 * @namespace DDIGITAL
 */
(function(NAMESPACE, $, win, doc) {

	'use strict';

	/**
	 * Utility namespace
	 * @namespace DDIGITAL.util
	 * @memberof DDIGITAL
	 */
	NAMESPACE.util = NAMESPACE.util || {};

	/**
	 * Bind print functionality to the page
	 *
	 * @namespace print
	 * @memberof DDIGITAL.util
	 * @version 1.0.0
	 * @author Deloitte Digital Australia deloittedigital@deloitte.com.au
	 */
	NAMESPACE.util.googleMapUtil = (function() {

		var extractComponentFromAddress = function(components, type) {
				for (var i = 0; i < components.length; i++) {
					for (var j = 0; j < components[i].types.length; j++) {
						if (components[i].types[j] === type) {
							return components[i].long_name;
						}
					}
				}
				return '';

			},
			getFormattedSuburb = function(suburb) {
				if (!suburb) {
					return '';
				}
				if (suburb.indexOf(', Australia') >= 0) {
					return suburb.substr(0, suburb.indexOf(', Australia'));
				}
				return suburb;
			},
			init = function() {
				if ($('.cm-provider-search-sgt-ctrl').length === 0) {
					return;
				}
				var inputField = doc.getElementById('enter-postcode');

				// stop form submitting when user selects drop down option of place
				// But still maintain submit on enter key for #enter-postcode
				$('#enter-postcode').keydown(function(e) {
					if (e.which === 13 && $('.pac-container:visible').length) {
						return false;
					}
				});

				google.maps.event.addDomListener(window, 'load', function() {
					var options = {
							types: ['(regions)'], // remove street from geolocated results
							componentRestrictions: {
								country: 'au'
							}

						},
						autocomplete = new google.maps.places.Autocomplete(inputField, options);

					google.maps.event.addListener(autocomplete, 'place_changed', function() {
						var selectedAddress = autocomplete.getPlace();

						// extract suburb from selection
						var suburbName = getFormattedSuburb(selectedAddress.formatted_address);

						// update #enter-postcode field with formatted suburb name
						$('#enter-postcode').val(suburbName);

						// extract postcode from selection
						var postcode = extractComponentFromAddress(selectedAddress.address_components, 'postal_code');

						// update postcode in hidden field for backend use
						$('#al').val(postcode);
						$('#alF').val(suburbName);
					});
				});
			};

		return {
			init: init
		};
	}());

}(DDIGITAL, jQuery, window, document));
