/* ==========================================================================
 * RESPONSIVE IFRAME
 * ========================================================================== */

(function(NAMESPACE, $) {

	'use strict';

	NAMESPACE.responsiveIframe = (function() {
		return {
			init: function init() {
				$('iframe').iFrameResize();
			}
		};
	}());

}(DDIGITAL, jQuery));
