// ==========================================================================
// HELPER UTILITIES
// ==========================================================================
/**
 * Deloitte Digital global namespace
 * @namespace DDIGITAL
 */
(function(NAMESPACE, $) {

	'use strict';

	/**
	 * Utility namespace
	 * @namespace DDIGITAL.util
	 * @memberof DDIGITAL
	 */
	NAMESPACE.util = NAMESPACE.util || {};

	/**
	 * Bind print functionality to the page
	 *
	 * @namespace print
	 * @memberof DDIGITAL.util
	 * @version 1.0.0
	 * @author Deloitte Digital Australia deloittedigital@deloitte.com.au
	 */
	NAMESPACE.util.print = (function() {
		var SELECTORS,
			init;

		SELECTORS = {
			PRINT: '.js-print'
		};

		/**
		 * On click, open the print dialog
		 *
		 * @memberof DDIGITAL.util.print
		 */
		init = function() {
			$(document).on('click.print', SELECTORS.PRINT, function(e) {
				e.preventDefault();
				window.print();
			});
		};

		return {
			init: init
		};
	}());

}(DDIGITAL, jQuery));
