// ==========================================================================
// DEVICE UTILITIES
// ==========================================================================

/**
 * Deloitte Digital global namespace
 * @namespace DDIGITAL
 */
(function(NAMESPACE, $) {

	'use strict';

	/**
	 * Utility namespace
	 * @namespace DDIGITAL.util
	 * @memberof DDIGITAL
	 */
	NAMESPACE.util = NAMESPACE.util || {};

	/**
	 * Breakpoints for JavaScript. Works with the Deloitte Digital SCSS @bp mixin
	 *
	 * @namespace device
	 * @memberof DDIGITAL.util
	 * @version 1.0.0
	 * @author Deloitte Digital Australia deloittedigital@deloitte.com.au
	 */

	NAMESPACE.util.device = (function() {
		var isDevice,
			init;

		/**
		 * Initialiser for the helpers
		 *
		 * @memberof DDIGITAL.util.device
		 */
		isDevice = {
			android: function() {
				return /Android/i.test(navigator.userAgent);
			},
			androidchrome: function() {
				return window.chrome && /Android/i.test(navigator.userAgent);
			},
			blackberry: function() {
				return /BlackBerry/i.test(navigator.userAgent);
			},
			ios: function() {
				return /iPhone|iPad|iPod/i.test(navigator.userAgent);
			},
			opera: function() {
				return /Opera Mini/i.test(navigator.userAgent);
			},
			windows: function() {
				return /IEMobile/i.test(navigator.userAgent);
			},
			any: function() {
				return (isDevice.android() || isDevice.blackberry() || isDevice.ios() || isDevice.opera() || isDevice.windows());
			},
			touch: function() {
				return 'ontouchstart' in window || 'onmsgesturechange' in window;
			}
		};

		/**
		 * Initialiser for the device utilities - auto adds classes to the page.
		 *
		 * @memberof DDIGITAL.util.device
		 * @private
		 */
		init = function() {
			// uses isDevice in local scope, to add classes to the HTML tag
			// this means that we can use CSS to identify (handle) different user agents
			// assumes: jQuery dependancy + only one user-agent is possible

			if (isDevice.android()) {
				$('html').addClass('d-android');
			} else if (isDevice.blackberry()) {
				$('html').addClass('d-blackberry');
			} else if (isDevice.ios()) {
				$('html').addClass('d-ios');
			} else if (isDevice.opera()) {
				$('html').addClass('d-opera');
			} else if (isDevice.windows()) {
				$('html').addClass('d-windows');
			} else {
				$('html').addClass('d-other'); //something we're not checking, maybe desktop?
			}

			if (isDevice.any()) {
				$('html').addClass('d-any');
			}

			if (isDevice.touch()) {
				$('html').addClass('d-touch');
			}
		};

		return {
			is: isDevice,
			init: init
		};
	}());

}(DDIGITAL, jQuery));
