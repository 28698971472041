// ==========================================================================
// TOOLTIPS
// ==========================================================================

(function(NAMESPACE, $) {

	'use strict';

	NAMESPACE.tooltip = (function() {
		var init;

		init = function() {
			$('.js-tooltip').ddTooltip();
		};

		return {
			init: init
		};
	}());

}(DDIGITAL, jQuery));
