// ==========================================================================
// M03 - HOMEPAGE USER AND TASK BASED NAV
// ==========================================================================

(function(NAMESPACE, $) {

	'use strict';
	/**
	 * Hero User and Task Based Nav
	 *
	 * @namespace homepageUserTaskNav
	 * @memberof DDIGITAL
	 * @version 1.0.0
	 * @author Deloitte Digital Australia
	 */

	NAMESPACE.homepageUserTaskNav = (function() {

		var ATTRS,
			CLASSES,
			SELECTORS,
			TEMPLATES,
			$scope,
			endpointData,
			selectedStep1,
			selectedStep2,
			prepopulate,
			init,
			_resetOptions,
			_createOptions,
			_populateOptions,
			_prepopulateOptions,
			_updatePlaceholder,
			_nextStep,
			_autoSelectOnlyOption,
			_getSavedOptions,
			_setSavedOptions;

		ATTRS = {
			ENDPOINT: 'data-task-endpoint'
		};

		CLASSES = {
			HERO_USER_TASK_NAV: 'homepage-user-task-nav',
			IS_SELECTED: 'is-selected',
			IS_FOCUSED: 'is-focused',
			IS_FILLED: 'is-filled',
			IS_ONLY_OPTION: 'is-only-option',
			IS_DISABLED: 'is-disabled'
		};

		SELECTORS = {
			TASK_FINDHELP: '#task-findhelp',
			TASK_MYTASK: '#task-mytask',
			TASK_ROW: '.task-row',
			SELECT: 'select',
			SELECT_PLACEHOLDER: '.select-placeholder',
			SUBMIT: '.user-task-nav-submit'
		};

		TEMPLATES = {
			DEFAULT_OPTION: $('<option />', {
				value: '',
				selected: true,
				disabled: true
			})
		};

		/**
		 * Reset `<option>` to default state
		 * @method _resetOptions
		 * @param {number} step Step number
		 * @private
		 */
		_resetOptions = function(step) {
			var $select,
				minStep,
				$taskRow = $(SELECTORS.TASK_ROW);

			prepopulate = false;

			if (step === 1) {
				$select = $(SELECTORS.TASK_FINDHELP);
				minStep = 0;
			} else if (step === 2) {
				$select = $(SELECTORS.TASK_MYTASK);
				minStep = 1;
			}

			for (var i = minStep; i <= 2; i += 1) {
				if ($($taskRow[i]).hasClass(CLASSES.IS_SELECTED)) {
					$($taskRow[i]).removeClass(CLASSES.IS_SELECTED);
				}

				if ($($taskRow[i + 1]) && $($taskRow[i + 1]).hasClass(CLASSES.IS_FOCUSED)) {
					$($taskRow[i + 1]).removeClass(CLASSES.IS_FOCUSED);
				}

				$($taskRow[i]).find('select')
					.empty()
					.prop('disabled', true);
			}

			var defaultValue = $select.next(SELECTORS.SELECT_PLACEHOLDER).attr('data-default-value');

			$select
				.append(TEMPLATES.DEFAULT_OPTION.clone())
				.next(SELECTORS.SELECT_PLACEHOLDER).text(defaultValue);

			$(SELECTORS.SUBMIT)
				.attr({
					disabled: true,
					href: '#'
				});
		};

		/**
		 * Populate `<option>` from JSON endpoint based on selection
		 * @method _populateOptions
		 * @param {number} step Step number
		 * @private
		 */
		_populateOptions = function(step) {
			var $select;

			if (step < 3) {
				_resetOptions(step);
			}

			if (step === 1) {
				$select = $scope.find(SELECTORS.TASK_FINDHELP);

				_createOptions($select, endpointData.Step1);
			} else if (step === 2) {
				$select = $scope.find(SELECTORS.TASK_MYTASK);

				_createOptions($select, endpointData.Step1[selectedStep1].Step2);
			} else {
				$scope.find(SELECTORS.SUBMIT)
					.prop('disabled', true)
					.attr({
						href: endpointData.Step1[selectedStep1].Step2[selectedStep2].Link,
						disabled: false
					});
				return;
			}

			_autoSelectOnlyOption($select, step);
		};

		/**
		 * Create and append options
		 * @method _createOptions
		 * @param {object} $select `<select>` element
		 * @param {object} options Available options
		 * @private
		 */
		_createOptions = function($select, options) {
			$select.prop('disabled', false);

			options.forEach(function(el, i) {
				$select .append($('<option />', {
					value: i,
					html: el.Title
				}));
			});
		};

		/**
		 * Pre-populate options
		 * @method _prepopulateOptions
		 * @param {object} formData Selected options
		 * @private
		 */
		_prepopulateOptions = function(formData) {
			prepopulate = true;

			formData.forEach(function(el, i) {
				_populateOptions(i + 1);
				$scope.find(SELECTORS.SELECT)[i].value = el.value;
				_nextStep($($scope.find(SELECTORS.SELECT)[i]), i);
			});
		};

		/**
		 * Update placeholder label
		 * @method _updatePlaceholder
		 * @param {object} $select `<select>` element
		 * @private
		 */
		_updatePlaceholder = function($select) {
			$select
				.next(SELECTORS.SELECT_PLACEHOLDER).text($select.find('option:selected')[0].textContent)
				.end()
				.delay(1000)
				.closest(SELECTORS.TASK_ROW).addClass(CLASSES.IS_SELECTED);

			$scope.delay(1000).addClass(CLASSES.IS_FILLED);
		};

		/**
		 * Go to next step
		 * @method _nextStep
		 * @param {object} $select Selected `<select>` element
		 * @param {number} selectIndex The index of selected `<select>`
		 * @private
		 */
		_nextStep = function($select, selectIndex) {
			var $currentStep,
				$nextStep;

			if (selectIndex === 0) {
				selectedStep1 = $select.val();
				$currentStep = $scope.find(SELECTORS.TASK_FINDHELP);
				$nextStep = $scope.find(SELECTORS.TASK_MYTASK);
			} else if (selectIndex === 1) {
				selectedStep2 = $select.val();
				$currentStep = $scope.find(SELECTORS.TASK_MYTASK);
				$nextStep = $scope.find(SELECTORS.SUBMIT);
			};

			$currentStep.closest(SELECTORS.TASK_ROW).removeClass(CLASSES.IS_FOCUSED);

			$nextStep.closest(SELECTORS.TASK_ROW).addClass(CLASSES.IS_FOCUSED);

			_updatePlaceholder($select);

			if (selectIndex < 2) {
				_populateOptions(selectIndex + 2);
			}
		};

		/**
		 * Check length of `<option>`
		 * @method _autoSelectOnlyOption
		 * @param {object} $select Selected `<select>` element
		 * @param {number} selectIndex The index of selected `<select>`
		 * @private
		 */
		_autoSelectOnlyOption = function($select, selectIndex) {
			if ($select.find('option:not([disabled])').length === 1) {
				$select.val('0');

				setTimeout(function() {
					_updatePlaceholder($select);
					$select
						.closest(SELECTORS.TASK_ROW).addClass(CLASSES.IS_ONLY_OPTION);
				}, 500);

				if (!prepopulate) {
					setTimeout(function() {
						_nextStep($select, selectIndex - 1);

					}, 750);
				}
			} else {
				$select
					.closest(SELECTORS.TASK_ROW).removeClass(CLASSES.IS_ONLY_OPTION);
			}
		};

		/**
		 * Get localStorage to pre-populate options
		 * @method _getSavedOptions
		 * @return {object} Form data in JSON
		 * @private
		 */
		_getSavedOptions = function() {
			return JSON.parse(localStorage.getItem('selectedOptions'));
		};

		/**
		 * Set localStorage to save selected options
		 * @method _setSavedOptions
		 * @param {object} formData Selected options
		 * @private
		 */
		_setSavedOptions = function(formData) {
			localStorage.setItem('selectedOptions', JSON.stringify(formData));
		};

		init = function(scope) {
			$scope = (scope) ? $('.homepage-user-task-nav', scope) : $('.homepage-user-task-nav');

			if ($($scope).length && $scope.attr(ATTRS.ENDPOINT).length) {
				$.ajax({
					method: 'GET',
					url: $scope.attr(ATTRS.ENDPOINT)
				})
				.done(function(resp) {
					if (resp.isValid === true) {
						endpointData = resp.data;

						// Check if localStorage has previous selections
						if (_getSavedOptions() && _getSavedOptions().length) {
							_prepopulateOptions(_getSavedOptions());
						} else {
							_populateOptions(1);
						}

						$scope.find(SELECTORS.SELECT).on('change', function() {
							var selectIndex = $scope.find(SELECTORS.SELECT).index($(this));

							_nextStep($(this), selectIndex);
						});

						$scope.find(SELECTORS.SUBMIT).on('click', function() {
							_setSavedOptions($scope.serializeArray());
						});
					} else {
						console.error('Request failed: ' + resp.message);
					}
				})
				.fail(function(jqXHR, textStatus) {
					console.error('Request failed: ' + textStatus);
				});
			}
		};

		return {
			init: init
		};
	}());

}(DDIGITAL, jQuery));
