/* =============================================================================
   HEADER NAV
   ========================================================================== */

   (function(NAMESPACE, $) {

	'use strict';

	NAMESPACE.primaryNavBrowserView = (function() {
		var SELECTORS,
			CLASSES,
			init,
			currentNavLevel = {},
			_toggleNav,
			closeNav,
			_scrollTopPos,
			_headerHeight,
			toggleStickyNav,
			_unstickyNav,
			_saveCurrentNavLevel,
			_restoreCurrentNavLevel,
			_resetNavLevel,
			_removeA11yListeners,
			_setNavOnscreen;

		SELECTORS = {
			ONSCREEN: '.primary-nav',
			ONSCREEN_CLOSE: '.js-onscreen-close',
			NAV_LVL1: '.nav-lvl1',
			NAV_CHILDREN: '.nav-children',
			IS_ACTIVE: '.is-active',
			IS_ACTIVE_PARENT: '.is-active-parent',
			IS_EXPANDED: '.is-expanded',
			IS_NAV_ACTIVE: '.is-nav-active',
			IS_DESELECTED: '.is-deselected',
			DROPDOWN: '.primary-dropdown',
			POPOVER_TOGGLE: '.js-popover-toggle',
			SHADE_BG: '.shade-bg',
			GLOBAL_HEADER: '.global-header',
			MAIN_CONTENT: '#skip-to-main',
			NAV_ITEM: '.nav-item',
			NAV_LVL2_CONTAINER: '.primary-nav-lvl2-container',
			NAV_LVL3_CONTAINER: '.primary-nav-lvl3-container',
			NAV_LVL4_CONTAINER: '.primary-nav-lvl4-container',
			NAV_LVL2_CONTAINER_ITEM: '.primary-nav-lvl2-container > li',
			NAV_LVL3_CONTAINER_ITEM: '.primary-nav-lvl3-container > li',
			NAV_LVL4_CONTAINER_ITEM: '.primary-nav-lvl4-container > li',
			PRIMARY_NAV : 'primary-nav'
		};

		CLASSES = {
			IS_ONSCREEN: 'is-nav-onscreen',
			IS_ACTIVE: 'is-active',
			IS_ACTIVE_PARENT: 'is-active-parent',
			IS_EXPANDED: 'is-expanded',
			IS_NAV_ACTIVE: 'is-nav-active',
			IS_DESELECTED: 'is-deselected',
			NAV_LVL1: 'nav-lvl1',
			NAV_NEXT: 'nav-next',
			IS_STICKY: 'is-sticky'
		};

		/**
		 * Toggle nav items
		 * @method _toggleNav
		 * @param {object} $el Link element
		 * @private
		 */
		_toggleNav = function($el) {
			var $parentNavItem = $el.parent('li'),
				parentNavItemClass,
				$nextElement;

			if ($el.hasClass(CLASSES.NAV_LVL1)) {
				parentNavItemClass = CLASSES.IS_NAV_ACTIVE;
				$nextElement = $el.next('.primary-dropdown');

				if ($parentNavItem.hasClass(CLASSES.IS_DESELECTED)) {
					$parentNavItem.removeClass(CLASSES.IS_DESELECTED);
				} else {
					$parentNavItem.siblings(SELECTORS.IS_ACTIVE_PARENT).addClass(CLASSES.IS_DESELECTED);
				}
			} else {
				if ($el.parent(SELECTORS.IS_ACTIVE_PARENT).length) {
					return;
				}

				parentNavItemClass = CLASSES.IS_ACTIVE_PARENT;
				$nextElement = $el.next(SELECTORS.NAV_CHILDREN);
			}

			// Set aria tag of active menu
			if ($el.attr('aria-expanded') === 'true') {
				$el.attr('aria-expanded', false);
			} else {
				$el.attr('aria-expanded', true);
			}

			$parentNavItem.siblings('li')
			.removeClass(parentNavItemClass)
			.find('a').attr('aria-expanded', false)
			.end()
			.find(SELECTORS.IS_EXPANDED).removeClass(CLASSES.IS_EXPANDED)
			.end()
			.find(SELECTORS.IS_NAV_ACTIVE).removeClass(CLASSES.IS_NAV_ACTIVE)
			.end()
			.find(SELECTORS.IS_ACTIVE_PARENT).removeClass(CLASSES.IS_ACTIVE_PARENT);

			// Close
			if ($parentNavItem.hasClass(parentNavItemClass)) {
				_resetNavLevel();

				$parentNavItem.removeClass(parentNavItemClass);

				_restoreCurrentNavLevel();

				if ($nextElement) {
					$nextElement.removeClass(CLASSES.IS_EXPANDED);
				}

				$(SELECTORS.SHADE_BG).removeClass(CLASSES.IS_ONSCREEN);
				$.ddShade.setActive(false);
				$.ddShade.opacity(0, 500);

				DD.noScroll.unset();

				// Open
			} else {
				$parentNavItem
					.addClass(parentNavItemClass);

				if ($nextElement) {
					$nextElement.addClass(CLASSES.IS_EXPANDED);
					setTimeout(function() {
						$nextElement[0].querySelector('a').focus();
					}, 200);
				}

				$(SELECTORS.SHADE_BG).addClass(CLASSES.IS_ONSCREEN);

				if (!($parentNavItem.hasClass(CLASSES.IS_ACTIVE_PARENT))) {
					$(SELECTORS.ONSCREEN).find('.is-nav-active')
						.find('.primary-dropdown').children().find('.has-children:first').addClass(CLASSES.IS_ACTIVE_PARENT);

					$(SELECTORS.ONSCREEN).find('.is-nav-active')
						.find('.primary-dropdown').children().find('.primary-nav-lvl3-container:first').addClass(CLASSES.IS_EXPANDED);
				}

				$.ddShade.setBehindHeader(true);
				$.ddShade.setActive(true);
				$.ddShade.opacity(0.95, 500);

				DD.noScroll.set();
			}

		};

		/**
		 * Close navigation primary-dropdown
		 * @method closeNav
		 */
		closeNav = function() {
			if ($(SELECTORS.IS_NAV_ACTIVE).length) {
				_toggleNav($(SELECTORS.IS_NAV_ACTIVE).find(SELECTORS.NAV_LVL1));
				_removeA11yListeners();
			}
		};

		/**
		 * Toggle sticky nav
		 * @method toggleStickyNav
		 */
		toggleStickyNav = function() {

			if (!NAMESPACE.IS_EDIT) {
				_scrollTopPos = $(window).scrollTop();
				_headerHeight = $(SELECTORS.GLOBAL_HEADER).height();

				if (!$('body').hasClass('has-no-scroll')) {
					$(SELECTORS.GLOBAL_HEADER).toggleClass(CLASSES.IS_STICKY, _scrollTopPos > 0);

					if (_scrollTopPos > 0) {
						$(SELECTORS.MAIN_CONTENT).css('margin-top', _headerHeight);
					} else {
						$(SELECTORS.MAIN_CONTENT).css('margin-top', 0);
					}
				}
			}
		};

		/**
		 * Turn off sticky nav
		 * @method _unstickyNav
		 * @private
		 */
		_unstickyNav = function() {
			$(SELECTORS.GLOBAL_HEADER).removeClass(CLASSES.IS_STICKY);
			$(SELECTORS.MAIN_CONTENT).css('margin-top', 0);
		};

		/**
		 * Save current nav level
		 * @method _saveCurrentNavLevel
		 * @private
		 */
		_saveCurrentNavLevel = function() {
			var $currentLvl1Nav = $(SELECTORS.ONSCREEN).find('.nav-item.is-active-parent');
			var $currentLvl2Nav = $(SELECTORS.ONSCREEN).find('.nav-lvl2-container > .is-active-parent');
			var $currentLvl3Nav = $(SELECTORS.ONSCREEN).find('.nav-lvl3-container > .is-active-parent');
			var $currentLvl4Nav = $(SELECTORS.ONSCREEN).find('.nav-lvl4-container > .is-active');

			currentNavLevel.lvl1 = $currentLvl1Nav.index();
			currentNavLevel.lvl2 = $currentLvl2Nav.index();
			currentNavLevel.lvl3 = $currentLvl3Nav.index();
			currentNavLevel.lvl4 = $currentLvl4Nav.index();

			localStorage.setItem('currentNavLevel', JSON.stringify(currentNavLevel));
		};

		/**
		 * Restore current nav level
		 * @method _restoreCurrentNavLevel
		 * @private
		 */
		_restoreCurrentNavLevel = function() {
			if (currentNavLevel) {

				if (currentNavLevel.lvl2 > -1) {
					$(SELECTORS.ONSCREEN)
						.find(SELECTORS.NAV_ITEM).eq(currentNavLevel.lvl1)
						.find(SELECTORS.NAV_LVL2_CONTAINER_ITEM).eq(currentNavLevel.lvl2).addClass(CLASSES.IS_ACTIVE_PARENT);
				}

				if (currentNavLevel.lvl3 > -1) {
					$(SELECTORS.ONSCREEN)
						.find(SELECTORS.NAV_ITEM).eq(currentNavLevel.lvl1)
						.find(SELECTORS.NAV_LVL2_CONTAINER_ITEM).eq(currentNavLevel.lvl2)
						.find(SELECTORS.NAV_LVL3_CONTAINER).addClass(CLASSES.IS_EXPANDED)
						.end()
						.find(SELECTORS.NAV_LVL3_CONTAINER_ITEM).eq(currentNavLevel.lvl3).addClass(CLASSES.IS_ACTIVE_PARENT);
				}

				if (currentNavLevel.lvl4 > -1) {
					$(SELECTORS.ONSCREEN)
						.find(SELECTORS.NAV_ITEM).eq(currentNavLevel.lvl1)
						.find(SELECTORS.NAV_LVL2_CONTAINER_ITEM).eq(currentNavLevel.lvl2)
						.find(SELECTORS.NAV_LVL3_CONTAINER)
						.end()
						.find(SELECTORS.NAV_LVL3_CONTAINER_ITEM).eq(currentNavLevel.lvl3)
						.find(SELECTORS.NAV_LVL4_CONTAINER).addClass(CLASSES.IS_EXPANDED)
						.end()
						.find(SELECTORS.NAV_LVL4_CONTAINER_ITEM).eq(currentNavLevel.lvl4).addClass(CLASSES.IS_ACTIVE_PARENT);
				}
			}
		};

		/**
		 * Reset nav level
		 * @method _resetNavLevel
		 * @private
		 */
		_resetNavLevel = function() {

			$(SELECTORS.ONSCREEN).find('.is-nav-active')
			.find('.primary-dropdown').children().find('.has-children:first').removeClass(CLASSES.IS_ACTIVE_PARENT);

			$(SELECTORS.ONSCREEN).find('.is-nav-active')
			.find('.primary-dropdown').children().find('.primary-nav-lvl3-container:first').removeClass(CLASSES.IS_EXPANDED);

			$(SELECTORS.ONSCREEN)
				.find(SELECTORS.IS_DESELECTED)
				.removeClass(CLASSES.IS_DESELECTED)
				.end()
				.find(SELECTORS.IS_NAV_ACTIVE)
				.find(SELECTORS.IS_ACTIVE_PARENT).removeClass(CLASSES.IS_ACTIVE_PARENT)
				.end()
				.find(SELECTORS.IS_EXPANDED).removeClass(CLASSES.IS_EXPANDED);
		};

		/**
		 * Remove all DD.a11y event listeners
		 * @method _removeA11yListeners
		 * @private
		 */
		_removeA11yListeners = function() {
			DD.a11y.onEscape.unset();
			DD.a11y.onClickOutside.unset();
		};

		_setNavOnscreen = function() {
			if (!(/(\/+$)|(\/index.html+$)/gi).test(window.location.pathname)) {
				_saveCurrentNavLevel();
			}

			// Let 'search' be the box that contains the search field that slides down from the top navigation.
			var search = document.querySelector('#global-search');
			// Let 'button' be the button that controls the visibility of 'search'.
			var button = document.querySelector('.search-toggle');

			$(SELECTORS.ONSCREEN).find('a')
				.off('click.nav-toggle')
				.on('click.nav-toggle', function(e) {

					if ($(this).hasClass(CLASSES.NAV_LVL1) || $(this).hasClass(CLASSES.NAV_NEXT)) {
						e.preventDefault();
						_toggleNav($(this));

						// Hide 'search'.
						search.classList.remove('is-ready');
						search.classList.remove('is-active');
						// Update 'button' visually.
						button.classList.remove('is-active');

						DD.a11y.onEscape.set(function() {
							closeNav();
						});

						DD.a11y.onClickOutside.set($(SELECTORS.ONSCREEN), function() {
							closeNav();
						});
					}
				});

			$(SELECTORS.ONSCREEN_CLOSE)
				.off('click.nav-close')
				.on('click.nav-close', function() {
					closeNav($(this));
				});

			$(window)
				.off('scroll.nav-global')
				.on('scroll.nav-global', function() {
					toggleStickyNav();
				});


			$(SELECTORS.ONSCREEN).find(SELECTORS.NAV_LVL1).last().on('blur', function() {
				if (!$(this).parents(SELECTORS.NAV_ITEM).hasClass(CLASSES.IS_NAV_ACTIVE)) {
					closeNav();
				}
			});

			$(SELECTORS.ONSCREEN).find(SELECTORS.ONSCREEN_CLOSE).last().on('blur', function() {
				if (!$(this).parentsUntil(SELECTORS.NAV_ITEM).hasClass(CLASSES.IS_NAV_ACTIVE)) {
					closeNav();
				}
			});
		};

		init = function() {
			if (DD.bp.is('l')) {
				_setNavOnscreen();
			}

			enquire.register(DD.bp.get('0, m'), {
				match: function() {
					var $activeCloseButton = $(SELECTORS.ONSCREEN)
						.find(SELECTORS.IS_NAV_ACTIVE)
						.find(SELECTORS.ONSCREEN_CLOSE);

					$activeCloseButton.trigger('click.nav-close');

					$(window).off('scroll.nav-global');

					_unstickyNav();
				},
				unmatch: _setNavOnscreen
			});
		};

		return {
			init: init,
			toggleStickyNav: toggleStickyNav,
			closeNav: closeNav
		};
	}());

}(DDIGITAL, jQuery));
